import { apiAntiga } from "../services/api/axios";
import { Pagination } from "../types/requestPagination";

interface IAdminUser {
	id: string;
	name: string;
	email: string;
	lastAccess: string;
	createdAt: string;
	status: boolean;
}

interface IGetAdimUsersResponse {
	items: IAdminUser[];
	meta: Pagination;
}

export async function getAllAdmins() {
	const response = await apiAntiga.get<IGetAdimUsersResponse>("/admins", {
		params: {
			page: 0,
			limit: 99999999,
		},
	});
	return response.data.items;
}
