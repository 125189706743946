import React, { useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";

import { DropZone, Text, TextInfo, Thumbnail } from "./file-uploader.styles";
import CloseIcon from "../../../assets/icons/close_big.svg";

const fileTypes = ["PNG"];

interface UploadFilesProps {
	setSelected: React.Dispatch<React.SetStateAction<string | File | null>>;
	value?: string | File | null;
	name?: string;
}

const DragDrop: React.FC<UploadFilesProps> = ({ setSelected, name, value }) => {
	const [url, setUrl] = React.useState("");
	const [error, setError] = React.useState(false);

	const thumbRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (value instanceof File) return;

		if (value) {
			thumbRef.current?.classList.add("active");
			setUrl(value);
			return;
		}

		setUrl("");
		thumbRef.current?.classList.remove("active");
	}, [value]);

	const dropZoneElement = document.querySelector(".dropZone");

	dropZoneElement?.addEventListener("dragover", (event) => {
		event?.preventDefault();
		dropZoneElement?.classList.add("active");
	});

	["dragleave", "dragend"].forEach((type) => {
		dropZoneElement?.addEventListener(type, (event) => {
			dropZoneElement?.classList.remove("active");
		});
	});

	function handleClearImage() {
		setSelected(null);
		setUrl("");
		thumbRef.current?.classList.remove("active");
	}

	const previewImage = (file: File): any => {
		const imgSrc = window.URL.createObjectURL(file);

		thumbRef.current?.classList.add("active");
		setUrl(imgSrc);
	};

	const handleChange = (file: File) => {
		previewImage(file);
		setSelected(file);
	};

	return (
		<FileUploader
			handleChange={handleChange}
			disable={!!value}
			name={name}
			hoverTitle=""
			types={fileTypes}
		>
			<DropZone className="dropZone">
				<Text>
					Imagem do Produto <br />
					(Arquivo .PNG)
				</Text>
				<TextInfo className="textInput">
					É Importante uma imagem com fundo transparente.
				</TextInfo>
				<Thumbnail
					ref={thumbRef}
					style={{
						backgroundImage: `url(${url})`,
						margin: "1px",
						zIndex: url ? 999 : 0,
					}}
					className={"thumbnail"}
				>
					<button
						onClick={handleClearImage}
						style={{
							width: "25px",
							height: "25px",
							backgroundImage: `url(${CloseIcon})`,
							zIndex: url ? 999 : 0,
						}}
					></button>
				</Thumbnail>
			</DropZone>
		</FileUploader>
	);
};

export default DragDrop;
