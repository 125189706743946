import { getAllAdmins } from "../../../hooks/usAdmins";
import { FormValues } from "../../../types/form";
import { onlySpaces } from "../../../utils/string";
import { validateEmail } from "../../input/validateInput";

export const validate = async (
	value: FormValues,
	currentName: string,
	currentEmail: string,
	setError: any,
) => {
	const { name, email, confirmEmail } = value;

	const passError = {
		name: false,
		email: false,
		confirmEmail: false,
	};

	const allAdmins = await getAllAdmins();

	const sameEmail = currentEmail === email;

	const emailAlreadyExists = allAdmins.some((admin) => admin.email === email!);

	if (emailAlreadyExists && !sameEmail) {
		passError.email = true;
		setError("email", {
			type: "required",
			message: "Email já existe!",
		});
	}

	if (typeof name === "string") {
		if (!name?.length || onlySpaces(name)) {
			passError.name = true;
			setError("name", {
				type: "required",
				message: "Informe um nome.",
			});
		}
	}

	if (typeof email === "string") {
		if (!email?.length) {
			passError.email = true;
			setError("email", {
				type: "required",
				message: "Informe um e-mail.",
			});
		} else if (!validateEmail(email)) {
			passError.email = true;
			setError("email", {
				type: "required",
				message: "Email inválido.",
			});
		}
	}

	if (typeof confirmEmail === "string") {
		if (email?.localeCompare(confirmEmail!) && !sameEmail) {
			passError.confirmEmail = true;
			setError("confirmEmail", {
				type: "required",
				message: "Valor diferente do campo email.",
			});
		}
	}

	return passError.name || passError.email || passError.confirmEmail;
};
