import React, { PureComponent } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { quantityStudents } from "../../mockup/dashboard";
import { ww } from "../../styles/responsive";

interface BarChartComponentProps {
	dataChart: {
		name: string;
		alunos: number;
	}[];
}

export default class BarChartComponent extends PureComponent<
	BarChartComponentProps,
	BarChartComponentProps
> {
	constructor(
		props: BarChartComponentProps | Readonly<BarChartComponentProps>,
	) {
		super(props);
		this.state = {
			dataChart: props.dataChart,
		};
	}

	render() {
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={ww(104)}
					height={ww(108)}
					data={this.state.dataChart}
					margin={{
						top: ww(11),
						right: ww(11),
						left: -ww(6),
						bottom: ww(6),
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="alunos" fill="#596CD4" />
				</BarChart>
			</ResponsiveContainer>
		);
	}
}
