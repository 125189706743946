import React from "react";
import BarChartComponent from "../../charts/bar-chart.components";

import {
	Container,
	Title,
	TitleContainer,
} from "../chart-bar/chart-bar.styles";

export type DataChartLastWeekStudents = {
	name: string;
	alunos: number;
};

interface BarChartDashboardProps {
	data: DataChartLastWeekStudents[];
}

const BarChartDashboard: React.FC<BarChartDashboardProps> = (props) => {
	return (
		<Container>
			<TitleContainer>
				<Title>Novos Alunos na Semana</Title>
			</TitleContainer>
			<BarChartComponent dataChart={props.data} />
		</Container>
	);
};

export default BarChartDashboard;
