import styled from "styled-components";
import { w, ww } from "../../../styles/responsive";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";

import { IconContainerProps } from "../../../types/category";

export const ContainerPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;
`;

export const TitlePage = styled.p`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 500;
	color: #4b4b4b;
	margin-right: auto;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	color: #000000;
	font-weight: 500;
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	text-align: left;
	color: #3b4ba2;
	margin-bottom: ${ww(13)}px;
`;

export const Box = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(600)}px;
	padding: ${w(7, 17, 14, 17)};
	position: relative;
	padding-top: ${ww(20)}px;
`;

export const CloseIconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const Icon = styled(Close).attrs({
	width: w(14),
	height: w(14),
})`
	display: flex;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: ${ww(131)}px;
	margin: ${w(0, 0, 6, 10)};
`;

export const InputContainer = styled.div`
	margin-bottom: ${ww(17)}px;
	max-width: 450px;
`;

export const Form = styled.div`
	display: flex;
	width: 100%;
	column-gap: ${w(40)};
`;

export const BoxColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	row-gap: 20px;
`;

export const SubContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const CancelContainer = styled.div`
	align-self: center;
	margin-top: ${ww(8)}px;

	p {
		font-size: ${ww(8)}px;
		text-align: center;
		font-weight: 400;
		color: #3b4ba2;
	}
	&:hover {
		cursor: pointer;
	}
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const IconsSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-height: ${ww(205)}px;
	margin-top: ${ww(10)}px;
	overflow-y: scroll;
`;

export const UploadSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: ${ww(5)}px;

	p {
		color: #7c7c7c;
	}
`;

export const VerticalDivisor = styled.div`
	height: ${ww(209)}px;
	border-right: 1px dashed #7c7c7c;
	margin: ${w(0, 15)};
`;

export const HorizontallDivisor = styled.div`
	width: 100%;
	border-bottom: 1px solid #7c7c7c;
	margin: ${w(15, 0)};
`;

export const IconContainer = styled.div<IconContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${ww(3)}px;
	width: ${ww(40.5)}px;
	height: ${ww(40.5)}px;
	margin: ${w(5, 2)};
	background: ${({ selected }) => (selected ? "#3d4da7" : "#fff")};

	&:hover {
		cursor: pointer;
	}

	& path {
		fill: ${({ selected }) => (selected ? "#fff" : "#7C7C7C")};
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	margin: ${w(7, 0, 2, 0)};
`;

export const Image = styled.img`
	width: 100%;
	height: ${ww(100)}px;
	object-fit: contain;
`;
