import { TableTitleModel } from "../models/table";

export const mockupTitle: TableTitleModel[] = [
	{ title: "" },
	{ title: "Países", icon: true },
	{ title: "Status", icon: true },
];

export const mockupData = [
	{
		id: "1",
		icon: require("../assets/images/brasil.png"),
		name: "Brasil",
		status: "Ativo",
	},
	{
		id: "2",
		icon: require("../assets/images/canada.png"),
		name: "Canadá",
		status: "Inativo",
	},
	{
		id: "3",
		icon: require("../assets/images/estados-unidos.png"),
		name: "Estados Unidos",
		status: "Ativo",
	},
];

/*eslint-disable*/
export const documentsData = [
	{
		id: "1",
		documents: [
			{
				id: "TERMS_OF_USE",
				title: "Termos de Uso",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 1 1</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFILE_PICTURE",
				title: "Foto de Perfil",
				status: false,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 1 2</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PERSONAL_DOCUMENT",
				title: "Documento Pessoal",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 1 3</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFESSIONAL_LICENSE",
				title: "Licença Profissional",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 1 4</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
		],
	},
	{
		id: "2",
		documents: [
			{
				id: "TERMS_OF_USE",
				title: "Termos de Uso",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 2 1</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFILE_PICTURE",
				title: "Foto de Perfil",
				status: false,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 2 2</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PERSONAL_DOCUMENT",
				title: "Documento Pessoal",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 2 3</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFESSIONAL_LICENSE",
				title: "Licença Profissional",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 2 4</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
		],
	},
	{
		id: "3",
		documents: [
			{
				id: "TERMS_OF_USE",
				title: "Termos de Uso",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 3 1</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFILE_PICTURE",
				title: "Foto de Perfil",
				status: false,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 3 2</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PERSONAL_DOCUMENT",
				title: "Documento Pessoal",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 3 3</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
			{
				id: "PROFESSIONAL_LICENSE",
				title: "Licença Profissional",
				status: true,
				description:
					'<p style="text-align:center;"><span style="font-size: 18px;"><strong>Title 3 4</strong></span></p> <ul> <li><span style="color: rgb(26,188,156);">Test 1</span></li> <li>Test2</li> </ul> ',
			},
		],
	},
];
/*eslint-enable*/

/*eslint-disable*/
export const emptyDocument = [
	{
		id: "TERMS_OF_USE",
		title: "Termos de Uso",
		status: true,
		description: " ",
	},
	{
		id: "PROFILE_PICTURE",
		title: "Foto de Perfil",
		status: true,
		description: " ",
	},
	{
		id: "PERSONAL_DOCUMENT",
		title: "Documento Pessoal",
		status: true,
		description: " ",
	},
	{
		id: "PROFESSIONAL_LICENSE",
		title: "Licença Profissional",
		status: true,
		description: " ",
	},
];
/*eslint-enable*/
