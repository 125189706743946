import styled, { css } from "styled-components";
import { w, ww } from "../../../styles/responsive";
import { AboutCardProps } from "../../../types/details";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: ${w(11, 11, 0, 11)};
	width: 100%;
`;

export const Box = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const Title = styled.p`
	font-size: ${ww(9)}px;
	font-weight: 700;
	color: #3b4ba2;
	margin-bottom: ${ww(7)}px;
`;

export const Text = styled.p<AboutCardProps>`
	font-size: ${ww(8)}px;
	font-weight: 400;
	color: #7c7c7c;
	line-height: ${ww(11)}px;
	margin-bottom: ${ww(19)}px;
`;

export const SubContainer = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${w(0, 14, 0, 3)};
`;

export const CardContainer = styled.div`
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
	margin-bottom: ${ww(11)}px;

	p {
		margin-bottom: 0px;
	}
`;

/* eslint-disable */
const cardStyle = css<AboutCardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${ww(17)}px;
	background-color: ${({ isCertification }) =>
		isCertification ? "#596CD4" : "#efefef"};
	padding: ${w(5, 9)};
	margin: ${w(0, 6, 6, 0)};
	border-radius: ${ww(3)}px;

	&:hover {
		cursor: ${({ isCertification }) =>
			isCertification ? "pointer" : "default"};
	}

	p {
		color: ${({ isCertification }) => (isCertification ? "#fff" : "#4b4b4b")};
	}
`;
/* eslint-enable */

export const Card = styled.div`
	${cardStyle}
`;

export const Link = styled.a`
	${cardStyle}
	padding: ${w(2, 9)};
`;

export const ContainerButton = styled.div`
	display: flex;
	width: ${ww(71)}px;
`;

export const Divisor = styled.div`
	height: 1px;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: ${ww(15)}px;
`;
