import React from "react";
import { useLocation } from "react-router-dom";

import { menuData } from "../../constants/menu";
import { AuthContext } from "../../contexts/authContext";
import {
	Container,
	Title,
	Text,
	ItemContainer,
	Divisor,
	TitleContainer,
} from "./menu-item.styles";

const MenuItem: React.FC = () => {
	const { setAdmin, setAuthenticated } = React.useContext(AuthContext);
	const [selected, setSelected] = React.useState(0);
	const location = useLocation();

	const currentRoute = location.pathname.split("/")[1];

	const logout = (id: number) => {
		if (id === 42) {
			setAuthenticated?.(false);
			setAdmin?.(null);
		}
	};

	React.useEffect(() => {
		if (!currentRoute) {
			setSelected(10);
		}

		menuData.map((item) => {
			item.data.map((subItem: any) => {
				if (subItem.link === currentRoute) {
					setSelected(subItem.id);
				}
			});
		});
	}, [currentRoute]);

	return (
		<Container className="scroll">
			{menuData.map((item) => (
				<React.Fragment key={item.id?.toString()}>
					<TitleContainer>
						<Title>{item.title}</Title>
					</TitleContainer>
					{item.data.map((subItem) => (
						<ItemContainer
							key={subItem.id?.toString()}
							to={subItem.link}
							selected={selected === subItem.id}
							onClick={() => {
								setSelected(subItem.id);
								logout(subItem.id);
							}}
						>
							<subItem.icon color={selected === subItem.id ? "#3D4DA7" : ""} />
							<Text selected={selected === subItem.id}>{subItem.title}</Text>
						</ItemContainer>
					))}
					<Divisor />
				</React.Fragment>
			))}
		</Container>
	);
};

export default MenuItem;
