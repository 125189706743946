import styled from "styled-components";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;
`;

export const Title = styled.p`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 500;
	color: #4b4b4b;
	margin-right: auto;
`;

export const Card = styled.form`
	display: flex;
	flex-direction: column;
	gap: 37px;

	background-color: white;
	border-radius: 10px;
	padding: 20px 25px;
	width: 100%;
`;

export const Label = styled.label`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #3b4ba2;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	button {
		max-width: 216px;
	}
`;
