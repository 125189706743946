import React from "react";
import { format, parse } from "date-fns";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import Table from "../../components/table";
import { GeneralContext } from "../../contexts/generalContext";

import { useTitle } from "../../hooks/useTitle";
import { TableTitleModel } from "../../models/table";
import { apiAntiga } from "../../services/api/axios";
import { getFinancialStatement } from "../../services/finance.service";
import { formatDateTo_dd_mm_yyyy_hh_mm_ss } from "../../utils/convertDate";
import { formatNumberForReal } from "../../utils/number";

import * as S from "./finance.styles";

const ColumnsFinanceTable: TableTitleModel[] = [
	{ title: "DATA", icon: true },
	{ title: "ALUNO", icon: true },
	{ title: "PROFESSOR", icon: true },
	{ title: "QTD AULAS", icon: true },
	{ title: "VALOR TOTAL", icon: true },
	{ title: "TAXA ALUNO %", icon: true },
	{ title: "TAXA PROFESSOR %", icon: true },
	{ title: "REPASSE PROFESSOR", icon: true },
	{ title: "REPASSE GATEWAY", icon: true },
	{ title: "REPASSE GREEKO", icon: true },
	{ title: "STATUS", icon: true },
];

const PAGE_TITLE = "Financeiro";
export function FinancePage() {
	useTitle(PAGE_TITLE);
	const [sortBy, setSortBy] = useState<null | string>(null);
	const [sortType, setSortType] = useState<"asc" | "desc">("asc");

	const { setTotalItems, itemsPage, currentPage, searchFilters } =
		useContext(GeneralContext);

	const { data } = useQuery(
		[
			"finance",
			currentPage,
			itemsPage,
			searchFilters.searchInput,
			searchFilters.dateFrom,
			searchFilters.dateTo,
			`Sort by: ${sortBy}`,
			sortType,
		],
		async () => {
			const finalDate = formatFinalDate(searchFilters.dateTo);

			const res = await getFinancialStatement({
				page: currentPage,
				limit: itemsPage,
				sortBy,
				sortType,
				search: searchFilters.searchInput,
				initialDate: searchFilters.dateFrom
					? parse(searchFilters.dateFrom, "dd/MM/yy", new Date()).toISOString()
					: undefined,
				finalDate,
			});

			setTotalItems?.(res?.meta.totalItems as number);

			const formattedData = res?.items.map((item) => ({
				id: item.id,
				date: format(new Date(item.date), "dd/MM/yyyy"),
				studentName: item.studentName,
				teacherName: item.teacherName,
				totalSchedule: item.totalSchedule,
				totalValue: formatNumberForReal(item.totalValue),
				studentFee: `${item.studentFee}%`,
				teacherFee: `${item.teacherFee}%`,
				teacherAmount: formatNumberForReal(item.teacherAmount),
				gatewayRepasse: formatNumberForReal(item.gatewayRepasse),
				greekoRepasse: formatNumberForReal(item.greekoRepasse),
				status: item.status,
			}));

			return formattedData;
		},
		{
			staleTime: 2 * 60 * 1000, // 2 Minutes
		},
	);

	// const setData = (newData: any) => {
	// 	queryClient.setQueryData("admin-supports", () => {
	// 		return {
	// 			data: {
	// 				...newData,
	// 			},
	// 		};
	// 	});
	// };

	const handleExportToExcel = () => {
		apiAntiga
			.get("admin/financial/export/report", {
				responseType: "arraybuffer",
			})
			.then((response) => {
				const { data, headers } = response;

				const a = document.createElement("a");
				const blob = new Blob([data], {
					type: headers["content-type"],
				});
				a.href = URL.createObjectURL(blob);
				a.download = `Greeko-Financeiro_exportado-em_${formatDateTo_dd_mm_yyyy_hh_mm_ss(
					new Date().toISOString(),
				)}.xlsx`;
				a.click();
				a.remove();
			});
	};

	return (
		<S.Container>
			<S.Title>{PAGE_TITLE}</S.Title>
			<Table
				titleData={ColumnsFinanceTable}
				data={data ?? []}
				// setData={setData}
				showDate
				hasActions
				onPressTopRightButton={handleExportToExcel}
				onPressColumnSortData={(keyColumn) => {
					setSortBy(keyColumn);
					setSortType((oldType) => (oldType === "asc" ? "desc" : "asc"));
				}}
			/>
		</S.Container>
	);
}

function formatFinalDate(date: string) {
	if (!date) return undefined;
	const parsedDate = parse(date, "dd/MM/yy", new Date());
	parsedDate.setHours(23, 59, 59);
	return parsedDate.toISOString();
}
