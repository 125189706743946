import styled, { css } from "styled-components";

import {
	SelectContainerProps,
	IconContainerProps,
	ShowOptionsProps,
} from "../../types/select";
import { ReactComponent as CheckBoxChecked } from "../../assets/icons/check_box_checked.svg";
import { ReactComponent as CheckBoxEmpty } from "../../assets/icons/check_box_empty.svg";
import { w, ww } from "../../styles/responsive";

//small: 81
//medium: 131
//large: 164

const selectContainerSize = css<SelectContainerProps & IconContainerProps>`
	display: flex;
	height: ${ww(23)}px;
	radius: ${ww(3)}px;
	width: ${({ width }) => width}px;
	font-size: ${ww(12)}px;
	color: #00000070;
	padding-left: ${ww(6)}px;
	background: #fff;
	position: relative;
	border-radius: ${ww(3)}px;
	margin-bottom: ${ww(3)}px;
	border: ${({ isFocus }) => (isFocus ? "1px solid #C6C2DE" : "none")};
	z-index: 9;

	&:hover {
		cursor: pointer;
	}
`;

const selectStyle = css<SelectContainerProps>`
	display: flex;
	align-items: center;
	min-height: ${ww(23)}px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: ${ww(7)}px;
	position: relative;
`;

export const ErrorMessage = styled.p`
	margin-top: 5px;

	color: #ff6868;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
`;

export const SelectContainer = styled.div<
	SelectContainerProps & IconContainerProps
>`
	${selectContainerSize}
	width: ${({ width }) => width}px;
	max-width: ${({ width }) => width}px;
	padding-left: ${ww(6)}px;
	border: ${({ isFocus }) => (isFocus ? "1px solid #C6C2DE" : "none")};
`;

export const Select = styled.div<SelectContainerProps>`
	${selectStyle}
	width: ${({ hasChildren, width }) =>
		hasChildren ? width! - 55 : width}px; //edit
`;

export const SelectTooSmall = styled.div<SelectContainerProps>`
	${selectStyle}
	height:${ww(17)}px;
	width: ${({ hasChildren }) => (hasChildren ? ww(34) : ww(51))}px; //edit
`;

export const IconContainer = styled.div<IconContainerProps>`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	right: ${ww(6)}px;
	left: none;
	transform: ${({ selected }) => (selected ? "rotate(-180deg)" : "none")};
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	color: #6e6893;
`;

//SHOW OPTIONS
export const ShowContainer = styled(SelectContainer)<ShowOptionsProps>`
	position: absolute;
	flex-direction: column;
	top: ${ww(24)}px;
	left: 0px;
	margin: 0px;
	padding: 0px;
	height: ${({ qtdeItems }) => ww(24) * qtdeItems}px;
	max-height: calc(100vh / 2);
	width: ${({ width }) => width}px;
	overflow-y: scroll;

	z-index: 91;
`;

export const ShowOptions = styled(Select)`
	width: 100%;
	align-items: center;
	justify-content: ${({ hasCheckBox }) =>
		hasCheckBox ? "flex-start" : "center"};

	&:hover {
		cursor: pointer;
	}
`;

//PAGE ITEM
export const Divisor = styled.div`
	width: 100%;
	border-bottom: 1px solid #c6c2de;
`;

export const SelectContainerPageItems = styled.div`
	${selectContainerSize}
	width: ${({ hasChildren }) => (hasChildren ? ww(34) : ww(51))}px;
	height: ${ww(17)}px;
	margin: ${w(0, 6, -8, 6)};
`;

export const ShowContainerPageItems = styled(ShowContainer)`
	top: -${({ qtdeItems }) => ww(24) * qtdeItems}px;
	left: ${ww(6)}px;
	width: ${ww(34)}px;
	max-height: calc(100vh / 2);
	overflow-y: scroll;

	z-index: 91;
`;

export const ShowOptionsPageItems = styled(ShowOptions)`
	min-height: ${ww(19)}px;
`;

export const IconCheckBoxChecked = styled(CheckBoxChecked)`
	margin: ${w(0, 6)};
`;

export const IconCheckBoxEmpty = styled(CheckBoxEmpty)`
	margin: ${w(0, 6)};
`;
