import { StylesConfig } from "react-select";

export const customStylesSelect: StylesConfig = {
	container: (provided) => {
		return {
			...provided,
			fontSize: "12px",
			height: "100%",
		};
	},
	menuList: (provided) => ({ ...provided, backgroundColor: "#FFF" }),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: "#596CD41A",
		color: "#3B4BA2",
	}),
	multiValueLabel: (provided) => ({
		...provided,
		fontSize: "12px",

		color: "#3B4BA2",
	}),
	multiValueRemove: (provided) => ({
		...provided,
		cursor: "pointer",
	}),
	control: (provided) => ({ ...provided, height: "100%" }),
};
