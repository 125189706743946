import React from "react";
import { ItemDetailsProps } from "../../../types/support";
import {
	Container,
	Image,
	ImageContainer,
	Date,
	Text,
	TextContainer,
	Title,
	SubContainer,
} from "./item.details.styles";
import avatar from "../../../assets/images/avatar.png";

const ItemDetails: React.FC<ItemDetailsProps> = ({
	image,
	name,
	date,
	text,
}) => {
	return (
		<Container>
			<SubContainer>
				<ImageContainer>
					<Image src={image || avatar} />
				</ImageContainer>
				<TextContainer>
					<Title>{name}</Title>
					<Date>{date}</Date>
				</TextContainer>
			</SubContainer>
			<Text>{text}</Text>
		</Container>
	);
};

export default ItemDetails;
