import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow_right.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down2.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	background-color: #f2f0f9;
	height: ${ww(15)}px;
	border-bottom-left-radius: ${ww(3)}px;
	border-bottom-right-radius: ${ww(3)}px;
`;
