import { apiAntiga } from "./api/axios";

type User = {
	id: string;
	name: string;
	citiesOfOperation: {
		name: string;
	}[];
	document: {
		file: string;
	}[];
};

type Approval = {
	id: string;
	type: string;
	status: string;
	user: User;
};

type HistoryClass = {
	classes_quantity: number;
	month_year: string;
};

export type HistoryStudent = {
	day_month_year: string;
	quantity: number;
};

export type DashboardData = {
	approvals: Approval[];
	classesHeld: number;
	historyClasses: HistoryClass[];
	historyStudents: HistoryStudent[];
	scheduledClasses: number;
	totalStudents: number;
	totalTeachers: number;
};

export async function getDashboardData(): Promise<DashboardData | undefined> {
	try {
		const response = await apiAntiga.get("/admin/dashboard");

		return response.data;
	} catch {
		console.error("Erro, não foi possível buscar os dados!");
	}
}
