import React from "react";
import { Routes, Route } from "react-router-dom";
import { AcceptancePage } from "./main/acceptance.component";
import { AcceptanceDetailsPage } from "./AcceptanceDetails/acceptanceDetails.component";

const AcceptanceIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<AcceptancePage />} />
			<Route path="details" element={<AcceptanceDetailsPage />} />
		</Routes>
	);
};

export default AcceptanceIndex;
