import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useTitle } from "../../../hooks/useTitle";

import Alert from "../../../components/alert/alert.component";
import Button from "../../../components/button/button.components";

import { CategoryInput } from "../../../types/input";
import UploadFile from "../../../components/category/uploadFile/upload-file.component";
import { useIcons } from "../../../hooks/useIcons";
import { useQueryClient } from "react-query";

import { adminApi, apiAntiga } from "../../../services/api/axios";

import {
	Box,
	Text,
	ButtonContainer,
	Title,
	Form,
	SubContainer,
	CancelContainer,
	ActionContainer,
	Section,
	IconsSection,
	SectionContainer,
	HorizontallDivisor,
	IconContainer,
	UploadSection,
	ImageContainer,
	Image,
} from "./category-details.style";

import * as S from "./category-details.style";
import { useLocation } from "react-router-dom";
import { CustomizedState } from "../../../types/details";
import { InputName } from "../../../components/InputName";
import { Language } from "../../../types/Language";
import { BackdropLoading } from "../../../components/BackdropLoading";
import useModal from "../../../utils/use-modal";

type Category = {
	icon: string;
	id: string;
	name: string;
	status: string;
	hidden: {
		category_i18n: Language[];
	};
};

const CategoryDetailsPage: React.FC = () => {
	const location = useLocation();
	const { data: icons } = useIcons();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const modalSuccess = useModal();

	const item = (location.state as CustomizedState<Category>)?.item;

	const PAGE_TITLE = `Categorias > ${
		item?.id ? "Editar Categoria" : "Adicionar Categoria"
	}`;
	useTitle(PAGE_TITLE);

	const { setValue, control, handleSubmit } = useForm<CategoryInput>();
	// const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [selected, setSelected] = React.useState<string | File>("");
	const [showCautionAlert, setShowCautionAlert] = React.useState(false);

	const categoryIsActive = item?.status === "Ativo";

	React.useEffect(() => {
		if (item?.id) {
			setSelected(item?.icon);

			setValue("pt-BR", item.name);

			// setValue(
			// 	"en-US",
			// 	item?.hidden?.category_i18n?.find(
			// 		(item) => item?.languageCode === "en-US",
			// 	)?.name ?? "",
			// );

			// setValue(
			// 	"es",
			// 	item?.hidden?.category_i18n?.find((item) => item?.languageCode === "es")
			// 		?.name ?? "",
			// );

			// setValue(
			// 	"fr-FR",
			// 	item?.hidden?.category_i18n?.find(
			// 		(item) => item?.languageCode === "fr-FR",
			// 	)?.name ?? "",
			// );
		}
	}, [item, setValue]);

	const onSubmit = async (data: CategoryInput) => {
		setIsLoading(true);

		// const categoryI18n = [];

		// categoryI18n.push({
		// 	name: data["pt-BR"],
		// 	languageCode: "pt-BR",
		// });

		// categoryI18n.push({
		// 	name: data["en-US"],
		// 	languageCode: "en-US",
		// });

		// categoryI18n.push({
		// 	name: data["fr-FR"],
		// 	languageCode: "fr-FR",
		// });

		// categoryI18n.push({
		// 	name: data["es"],
		// 	languageCode: "es",
		// });

		try {
			const formData = new FormData();

			if (selected instanceof File) {
				formData.append("image", selected);
			} else {
				formData.append("iconId", selected);
			}

			const name = data["pt-BR"];

			formData.append("name", name);

			if (item?.id) {
				await adminApi.put(`/categories/update/${item?.id}`, formData);
			} else {
				await adminApi.post("/categories/create", formData);
			}

			queryClient.invalidateQueries("categories");
			modalSuccess.open();
		} catch (err) {
			console.error("Error creating category");
		} finally {
			setIsLoading(false);
		}
	};

	async function handleUpdateStatusCategory() {
		setIsLoading(true);

		try {
			await apiAntiga.patch(`/category/${item?.id}`, {
				status: !categoryIsActive,
			});
			queryClient.invalidateQueries("categories"); // refetch categories list
			navigate(-1);
		} catch (err) {
			console.error("Error updating category, try again!");
		} finally {
			setIsLoading(false);
			setShowCautionAlert(false);
		}
	}

	const onClickSuccessModal = () => {
		modalSuccess.close();
		navigate(-1);
	};

	function getVisibilityConfirmText() {
		const actionText = categoryIsActive ? "desativar" : "ativar";
		return `Você está prestes a ${actionText} essa categoria`;
	}

	return (
		<S.ContainerPage>
			<S.TitlePage>{PAGE_TITLE}</S.TitlePage>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box>
					<Form>
						<S.BoxColumnContainer>
							<Title>Ícone da Categoria </Title>
							<SectionContainer>
								<Section>
									<Text>Escolha um ícone para a categoria:</Text>
									<IconsSection>
										{icons.map((item, index) => (
											<IconContainer
												selected={selected === item.imageUrl}
												key={item.id}
												onClick={() => setSelected(item.imageUrl)}
											>
												<item.Icon url={item.imageUrl} />
											</IconContainer>
										))}
									</IconsSection>
								</Section>
								{/* <VerticalDivisor /> */}
								<Section style={{ marginTop: 20 }}>
									<Text>Suba o seu ícone:</Text>
									<UploadSection>
										<Text>
											Arquivo .SVG quadrado (1x1) branco e fundo transparente.
										</Text>
										<ImageContainer>
											<Image
												src={require("../../../assets/images/upload_instruction.png")}
											/>
										</ImageContainer>
										<UploadFile setSelected={setSelected} />
									</UploadSection>
								</Section>
							</SectionContainer>
							<HorizontallDivisor />
						</S.BoxColumnContainer>
						<S.BoxColumnContainer>
							<Title>Nome da Categoria Por Idioma</Title>

							<InputName
								control={control}
								label="Português (Brasil)"
								name="pt-BR"
								placeholder="Nome da Categoria"
								isDefault
							/>

							{/* <InputName
								control={control}
								label="Inglês"
								name="en-US"
								placeholder="Nome da Categoria"
							/>

							<InputName
								control={control}
								label="Francês"
								name="fr-FR"
								placeholder="Nome da Categoria"
							/>

							<InputName
								control={control}
								label="Espanhol"
								name="es"
								placeholder="Nome da Categoria"
							/> */}
						</S.BoxColumnContainer>
					</Form>
				</Box>
				<SubContainer>
					<CancelContainer>
						<Text onClick={() => navigate(-1)}>Cancelar</Text>
					</CancelContainer>
					<ActionContainer>
						{!!item?.id && (
							<ButtonContainer onClick={() => setShowCautionAlert(true)}>
								<Button
									title={
										categoryIsActive
											? "Desativar Categoria"
											: "Ativar Categoria"
									}
									inverterColor
								/>
							</ButtonContainer>
						)}
						<ButtonContainer>
							<Button
								type="submit"
								title={item?.id ? "Salvar" : "Adicionar"}
								inverterColor={item?.id ? false : true}
								isLoading={isLoading}
							/>
						</ButtonContainer>
					</ActionContainer>
				</SubContainer>
			</form>

			{showCautionAlert && (
				<Alert
					text={getVisibilityConfirmText()}
					buttonText={categoryIsActive ? "Desativar" : "Ativar"}
					clickFunction={() => setShowCautionAlert(false)}
					cautionFunction={handleUpdateStatusCategory}
					isLoading={isLoading}
					type="danger"
				/>
			)}

			{modalSuccess.isShowing && (
				<Alert
					text={
						item?.id
							? "Categoria editada com sucesso"
							: "Categoria adicionada com sucesso."
					}
					buttonText="Ok"
					clickFunction={onClickSuccessModal}
				/>
			)}

			<BackdropLoading isVisible={isLoading} />
		</S.ContainerPage>
	);
};

export default CategoryDetailsPage;
