export const mockupTitle = [
	{ title: "" },
	{ title: "MODALIDADE" },
	{ title: "DATA" },
	{ title: "Ranking", icon: true },
];

export const mockupData = [
	{
		id: 1,
		name: "Luhio Geowu",
		modalities: "Cross Fit, Musculação, Corrida +3...",
		register: "13/11/2021",
		ranking: "3.9",
	},
	{
		id: 2,
		name: "Dianne Russell",
		modalities: "Cross Fit, Musculação, Corrida +1...",
		register: "13/11/2021",
		ranking: "1.3",
	},
	{
		id: 3,
		name: "Blofi Tuyur",
		modalities: "Musculação, Corrida",
		register: "13/11/2021",
		ranking: "4.2",
	},
	{
		id: 4,
		name: "Lewoa Xanauso",
		modalities: "Cross Fit",
		register: "13/11/2021",
		ranking: "3.5",
	},
	{
		id: 5,
		name: "Lewoa Abrao",
		modalities: "Corrida",
		register: "13/11/2021",
		ranking: "3.8",
	},
	{
		id: 6,
		name: "Doton Dyuhyen",
		modalities: "Cross Fit, Musculação, Corrida +2...",
		register: "13/11/2021",
		ranking: "1.9",
	},
	{
		id: 7,
		name: "Goubu Saypu",
		modalities: "Musculação, Corrida",
		register: "13/11/2021",
		ranking: "4.0",
	},
];
