import styled from "styled-components";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: ${ww(218)}px;
	background: #fff;
	margin-top: ${ww(13)}px;
	height: ${ww(139)}px;
	border: 1px solid #e5e5e5;
	border-radius: ${ww(2)}px;
`;

export const Title = styled.p`
	font-size: ${ww(9)}px;
	font-weight: 700;
	color: #4b4b4b;
`;

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	padding: ${w(8, 0, 3, 0)};
	justify-content: center;
`;
