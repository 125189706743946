import styled from "styled-components";
import { ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hideen;
	width: ${ww(218)}px;
	align-items: center;
	justify-content: flex-start;
	background: #fff;
	margin-left: ${ww(18)}px;
	border: 1px solid #e5e5e5;
	border-radius: ${ww(2)}px;
	padding: ${ww(16)}px;
`;

export const Title = styled.p`
	font-size: ${ww(11)}px;
	font-weight: 500;
	color: #000;
`;

export const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: ${ww(20)}px;
	margin-top: ${ww(14)}px;
	overflow: hidden;
`;
