import { documentType, userGender } from "../../../constants/acceptance";

export const formatGenderText = (gender: string) => {
	if (gender === userGender.male) return "Masculino";
	if (gender === userGender.female) return "Feminino";
	return "Outro";
};

export const formatDocumentTypeText = (type: string) => {
	if (type === documentType.personalDocument) return "Documento Pessoal";
	if (type === documentType.profilePicture) return "Foto de Perfil";
	if (type === documentType.professionalLicense) return "Licença Profissional";
};
