import { ICategory } from "../types/category";
import { Pagination } from "../types/requestPagination";
import { adminApi } from "./api/axios";

export interface GetCategoriesResponse {
	items: ICategory[];
	meta: Pagination;
}

type GetCategoriesParams = {
	search: string;
	page: number;
	limit: number;
};

export async function getCategories(params?: Partial<GetCategoriesParams>) {
	const response = await adminApi.get<GetCategoriesResponse>(
		params?.search ? "/categories/search" : "/categories",
		{
			params: {
				...params,
				page: 1,
			},
		},
	);

	return response.data;
}
