import styled, { css, keyframes } from "styled-components";
import { w } from "../../styles/responsive";

type ContainerProps = {
	hasError: boolean;
};

const shake = keyframes`
25% {
      transform: translateX(4px)
  }
  50% {
      transform: translateX(-4px)
  }
  75% {
      transform: translateX(4px)
  }
`;

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	max-width: 310px;

	padding: 9px 5px 6px 5px;

	&.error {
		animation: 0.3s ${shake} ease-in-out;
	}
`;

type LabelProps = {
	isActive: boolean;
};

export const Label = styled.label<LabelProps>`
	padding-left: 21px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	transition: 0.3s ease-in-out;

	color: #7c7c7c;
`;

export const Input = styled.input<ContainerProps>`
	padding-left: ${(props) => (props.placeholder ? "0" : "21px")};

	font: normal 400 14px/17px "Inter", sans-serif;
	margin-top: ${w(4)};
	margin-bottom: ${w(1.5)};
	padding-bottom: ${w(5)};
	border-radius: 0;

	border-bottom: 0.8px solid
		${({ hasError }) => (hasError ? "#FF6868" : "rgba(0, 0, 0, 0.4)")};

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&::placeholder {
		font-family: "Inter";
		font-style: italic;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;

		color: rgba(0, 0, 0, 0.4);
	}
`;

export const ErrorMessage = styled.p`
	margin-top: 5px;

	color: #ff6868;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
`;
