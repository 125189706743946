import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { GeneralContext } from "../../../contexts/generalContext";
import { apiAntiga } from "../../../services/api/axios";
import { FormValues } from "../../../types/form";
import { MessageProps } from "../../../types/support";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import TextAreaComponent from "../../text-area/text-area.component";

import {
	Container,
	Box,
	Text,
	ButtonContainer,
	Form,
	Title,
} from "./message.styles";

const MessageDetails: React.FC<MessageProps> = ({
	setShowMessage,
	setMessageSent,
}) => {
	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<FormValues>();

	const { pageDetails, setPageDetails } = React.useContext(GeneralContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [showErrorAlert, setShowErrorAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const queryClient = useQueryClient();

	const { id } = pageDetails;

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const message = getValues("message");

		if (!message?.length) {
			return setError("message", {
				type: "required",
				message: "Informe uma mensagem.",
			});
		}

		if (!message.includes(" ") && message.length > 30) {
			return setError("message", {
				type: "required",
				message: "Mensagem não contém espaçõs entre as palavras.",
			});
		}

		try {
			// TODO post message support call in the api
			await apiAntiga.post(`/admin/supports/${id}`, {
				message: message,
			});
			queryClient.invalidateQueries("support-details");
			queryClient.invalidateQueries("admin-supports");
		} catch (error) {
			setShowErrorAlert(true);
			return;
		}

		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);
			setMessageSent();
			setShowAlert(true);
		}, 3000);
	};

	function closeModal() {
		setShowErrorAlert(false);
		setShowAlert(false);
		setShowMessage(false);
	}

	return (
		<Container>
			<Box>
				<Title>Nova mensagem</Title>
				<Text>Escreva a mensagem:</Text>
				<Form onSubmit={onSubmit}>
					<TextAreaComponent
						register={register}
						name="message"
						type="text"
						placeholder="Escreva uma mensagem.."
						// maxLength={250}
						onFocus={() => setError("message", { type: "", message: "" })}
						error={errors.message?.message}
					/>
					<ButtonContainer>
						<Button
							title="Enviar Mensagem"
							type={"submit"}
							isLoading={isLoading}
						/>
					</ButtonContainer>
					<ButtonContainer onClick={closeModal}>
						<Button title="Cancelar" inverterColor />
					</ButtonContainer>
				</Form>
			</Box>
			{showAlert && (
				<Alert
					text="Mensagem enviada com sucesso."
					buttonText="Ok"
					clickFunction={closeModal}
				/>
			)}
			{showErrorAlert && (
				<Alert
					type="danger"
					text="Erro ao enviar mensagem, tente novamente mais tarde."
					buttonText="Ok"
					cautionFunction={closeModal}
					hideCancelButton
				/>
			)}
		</Container>
	);
};

export default MessageDetails;
