import {
	TableItemSupportModel,
	TableItemAcceptanceModel,
} from "../models/table";

export const sortData = (
	data: TableItemSupportModel[] | TableItemAcceptanceModel[],
	setData: (
		value:
			| React.SetStateAction<
					TableItemSupportModel[] | TableItemAcceptanceModel[] | undefined
			  >
			| undefined,
	) => void,
	dataSort?: string,
	invert?: boolean,
) => {
	const newArray = data ?? [...data!];
	type ObjectKey = keyof typeof data[0];
	const key = dataSort as ObjectKey;

	setData(
		newArray?.sort((a, b) => {
			const nameA = +a[key] ? +a[key] : a[key].toUpperCase();
			const nameB = +b[key] ? +b[key] : b[key].toUpperCase();

			if (invert ? nameA < nameB : nameA > nameB) {
				return -1;
			}
			if (invert ? nameA > nameB : nameA < nameB) {
				return 1;
			}

			return 0;
		}),
	);
};
