import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Container } from "./Editor.styles";
import { EditorProps } from "../../types/editor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorComponent: React.FC<EditorProps> = ({
	description,
	setDescription,
}) => {
	const [editorState, setEditorState] = React.useState<any>();

	const onEditorStateChange = (editorState: any) => {
		setEditorState(editorState);
		setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	React.useEffect(() => {
		const blocksFromHtml = htmlToDraft(description);

		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(
			contentBlocks,
			entityMap,
		);

		setEditorState(EditorState.createWithContent(contentState));
	}, [description]);

	return (
		<Container>
			<Editor
				editorState={editorState}
				wrapperClassName="demo-wrapper"
				editorClassName="demo-editor"
				onEditorStateChange={onEditorStateChange}
				locale="pt"
				editorStyle={{ direction: "inherit" }}
				wrapperStyle={{ direction: "ltr" }}
				toolbar={{
					options: [
						"inline",
						"blockType",
						"fontSize",
						"colorPicker",
						"textAlign",
						"list",
					],
					textAlign: { inDropdown: true },
					inline: {
						options: ["bold", "italic", "underline"],
					},
					fontSize: {
						options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
						className: undefined,
						component: undefined,
						dropdownClassName: undefined,
					},
					blockType: {
						options: [
							"Normal",
							"H1",
							"H2",
							"H3",
							"H4",
							"H5",
							"H6",
							"Blockqoute",
						],
					},
					colorPicker: {
						className: undefined,
						component: undefined,
						popupClassName: undefined,
					},
					list: {
						options: ["ordered", "unordered"],
						className: undefined,
						component: undefined,
						popupClassName: undefined,
					},
				}}
			/>
		</Container>
	);
};

export default EditorComponent;
