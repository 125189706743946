import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { GeneralContext } from "../../contexts/generalContext";
import { apiAntiga } from "../../services/api/axios";
import {
	convertDate_yyyy_mm_dd,
	formatDataToFinanceDetailsItems,
} from "../../utils/convertDate";
import { formatNumberForReal } from "../../utils/number";

import Button from "../button/button.components";

import CredidCardIcon from "../../assets/icons/credit-card-outlined.svg";
import * as S from "./finance-details.styles";

type FinanceDetailsResponse = {
	applicationFee: string;
	cart: {
		scheduleDate: string;
		modality: string;
		total: string;
	}[];
	datePayment: string;
	location: string;
	nameStudent: string;
	nameTeacher: string;
	payment: {
		brand: string;
		banck: string;
		type: string;
		lastFourDigits: string;
	};
	subTotal: string;
	total: string;
};

export function FinanceDetailsModal() {
	const { setPageDetails, setEditData, editData } =
		React.useContext(GeneralContext);

	const { data } = useQuery(
		["finance-details", editData?.id],
		async () => {
			const response = await apiAntiga.get<FinanceDetailsResponse>(
				`admin/financial/${editData?.id}`,
			);

			return response.data;
		},
		{
			staleTime: 5 * 60 * 1000, // 5 Minutes
		},
	);

	if (!data) {
		return null;
	}

	return (
		<S.Container>
			<S.Box>
				<S.BoxPadding>
					<S.IconContainer
						onClick={() => (setPageDetails?.(false), setEditData?.({}))}
					>
						<S.Icon />
					</S.IconContainer>
					<S.Title>{data.nameStudent}</S.Title>
					<S.SubDescription>Personal: {data.nameTeacher}</S.SubDescription>
					<S.SubDescription>
						Pgto realizado no dia {convertDate_yyyy_mm_dd(data.datePayment)}
					</S.SubDescription>
				</S.BoxPadding>

				<S.BoxPadding>
					<S.SubTitle style={{ marginTop: 20 }}>Local da aula</S.SubTitle>
					<S.Text style={{ color: "#4B4B4B" }}>{data.location}</S.Text>

					<S.SubTitle style={{ marginTop: 18 }}>Itens do Carrinho</S.SubTitle>

					<S.ContainerCart>
						{data.cart.map((item, indexItem) => (
							<S.ItemCart key={item.scheduleDate + indexItem}>
								<div>
									<S.Text style={{ color: "#4B4B4B" }}>
										{/* 05-Jul, Terça-Feira, 08:00 */}
										{formatDataToFinanceDetailsItems(item.scheduleDate)}
									</S.Text>
									<S.Text>{item.modality}</S.Text>
								</div>
								<S.Text style={{ color: "#4B4B4B" }}>
									{formatNumberForReal(Number(item.total))}
								</S.Text>
							</S.ItemCart>
						))}
					</S.ContainerCart>

					<S.SubTitle style={{ marginTop: 15 }}>Resumo</S.SubTitle>
					<S.SummaryBoxLine style={{ marginTop: 10 }}>
						<S.Text style={{ color: "#4B4B4B" }}>Subotal</S.Text>
						<S.Text style={{ color: "#4B4B4B" }}>
							{formatNumberForReal(Number(data.subTotal))}
						</S.Text>
					</S.SummaryBoxLine>
					<S.SummaryBoxLine>
						<S.Text style={{ color: "#4B4B4B" }}>Taxa de Serviço</S.Text>
						<S.Text style={{ color: "#4B4B4B" }}>
							{formatNumberForReal(Number(data.applicationFee))}
						</S.Text>
					</S.SummaryBoxLine>

					<S.DashedLine />

					<S.SummaryBoxLine>
						<S.SubTitle style={{ color: "#4B4B4B" }}>Total</S.SubTitle>
						<S.SubTitle style={{ color: "#4B4B4B" }}>
							{formatNumberForReal(Number(data.total))}
						</S.SubTitle>
					</S.SummaryBoxLine>
				</S.BoxPadding>

				<S.SolidLine />

				<S.BoxPadding>
					<S.SubTitle>Forma de Pagamento</S.SubTitle>
					<S.PaymentBox>
						<img src={CredidCardIcon} />

						<div>
							<S.SubTitle>Cartão de Crédito</S.SubTitle>
							<S.Text style={{ color: "#4B4B4B" }}>
								**** {data.payment.lastFourDigits}
							</S.Text>
						</div>
					</S.PaymentBox>

					<S.ButtonContainer>
						<Button
							title="Fechar"
							clickFunction={() => (setPageDetails?.(false), setEditData?.({}))}
							inverterColor
						/>
					</S.ButtonContainer>
				</S.BoxPadding>
			</S.Box>
		</S.Container>
	);
}
