import React from "react";
import { FieldError } from "react-hook-form";

import * as S from "./Input.styles";

interface FieldInputCatalogProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	error?: FieldError;
}

const FieldInputSystemComponent: React.ForwardRefRenderFunction<
	HTMLInputElement,
	FieldInputCatalogProps
> = (
	{ name, label, value, placeholder, onChange, error = null, ...rest },
	ref,
) => {
	const hasError = !!error;

	return (
		<S.Container className={hasError ? "error" : ""} hasError={hasError}>
			<S.Label
				isActive={!!placeholder || !!value}
				htmlFor={name}
				placeholder={placeholder}
			>
				{label}
			</S.Label>

			<S.Input
				id={name}
				name={name}
				placeholder={placeholder}
				ref={ref}
				onChange={(e) => onChange?.(e)}
				value={value}
				hasError={hasError}
				{...rest}
			/>

			<S.ErrorMessage>{error?.message}</S.ErrorMessage>
		</S.Container>
	);
};

export const FieldInputSystem = React.forwardRef(FieldInputSystemComponent);
