import React from "react";

import { ButtonProps } from "../../types/button";
import Loading from "../loading/loading.components";

import {
	PrimaryContainer,
	SecondaryContainer,
	CustomContainer,
	DisabledContainer,
	Text,
} from "./button.styles";

const Button: React.FC<ButtonProps> = ({
	type = "button",
	title,
	clickFunction,
	isLoading = false,
	children,
	inverterColor = false,
	customColor,
	isDisabled = false,
}) => {
	const renderContainer = () => {
		if (customColor) return CustomContainer;
		if (inverterColor) return SecondaryContainer;
		return PrimaryContainer;
	};

	const Container = renderContainer();

	return (
		<>
			{!isDisabled ? (
				<Container
					type={type}
					onClick={clickFunction}
					disabled={isLoading}
					isLoading={isLoading}
					customColor={customColor}
				>
					{children}
					{isLoading ? (
						<Loading color={customColor === "#EFD700" ? "#383838" : "#fff"} />
					) : (
						<Text inverterColor={inverterColor}>{title}</Text>
					)}
				</Container>
			) : (
				<DisabledContainer>
					<Text>{title}</Text>
				</DisabledContainer>
			)}
		</>
	);
};

export default Button;
