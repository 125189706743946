import styled from "styled-components";

import { w, ww } from "./../../styles/responsive";

import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(14),
	height: w(14),
})``;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;
`;

export const TitlePage = styled.p`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 500;
	color: #4b4b4b;
	margin-right: auto;
`;

export const CardContainer = styled.form`
	display: flex;
	flex-direction: column;
	gap: 37px;

	background-color: white;
	border-radius: 10px;
	padding: 20px 25px;
	width: 100%;
`;

export const SectionForm = styled.div`
	display: flex;
	column-gap: 20px;
`;

export const SectionColumnForm = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	flex: 1;
`;

export const TitleSection = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #3b4ba2;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;

	justify-content: space-between;
	div {
		flex: 1;
		display: flex;
		justify-content: space-between;

		button {
			max-width: 216px;
		}

		+ div {
			justify-content: flex-end;
		}
	}
`;

export const CancelContainer = styled.div`
	align-self: center;
	margin-top: ${ww(8)}px;

	p {
		font-size: ${ww(8)}px;
		text-align: center;
		font-weight: 400;
		color: #3b4ba2;
	}
	&:hover {
		cursor: pointer;
	}
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	margin-bottom: ${ww(3)}px;
	color: #7c7c7c;
	font-weight: 500;
`;
