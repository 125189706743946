export const mockupTitle = [
	{ title: "Autor" },
	{ title: "tipo do usuário" },
	{ title: "categoria" },
	{ title: "aberto em" },
	{ title: "ÚLT. RESPOSTA" },
	{ title: "status", icon: true },
];

export const mockupData = [
	{
		id: 1,
		name: "Luhio Geowu",
		type: "Personal",
		category: "Comportamento",
		openDate: "3 Dias",
		lastAnswer: "13/11/2021",
		status: "Respondido",
	},
	{
		id: 2,
		name: "Dianne Russell",
		type: "Personal",
		category: "Comportamento",
		openDate: "3 Dias",
		lastAnswer: "Não Houve",
		status: "Aberto",
	},
	{
		id: 3,
		name: "Blofi Tuyur",
		type: "Personal",
		category: "Comportamento",
		openDate: "10 Dias",
		lastAnswer: "10/12/2021",
		status: "Concluído",
	},
	{
		id: 4,
		name: "Lewoa Silva",
		type: "Personal",
		category: "Comportamento",
		openDate: "2 Dias",
		lastAnswer: "02/11/2021",
		status: "Aberto",
	},
	{
		id: 5,
		name: "Lewoa Abrao",
		type: "Personal",
		category: "Comportamento",
		openDate: "3 Dias",
		lastAnswer: "Não Houve",
		status: "Aberto",
	},
	{
		id: 6,
		name: "Doton Dyuhyen",
		type: "Personal",
		category: "Comportamento",
		openDate: "6 Dias",
		lastAnswer: "Não Houve",
		status: "Concluído",
	},
	{
		id: 7,
		name: "Goubu Saypu",
		type: "Personal",
		category: "Comportamento",
		openDate: "2 Dias",
		lastAnswer: "30/10/2021",
		status: "Aberto",
	},
];

export const mockupDataDetails = {
	id: 1,
	name: "Pedro Fonseca",
	memberSince: "10/12/2021",
	classAmount: "11",
	lastAnswer: "13/11/2021",
	status: "Respondido",
	question: {
		image:
			"https://cdn.pixabay.com/photo/2016/11/18/19/07/happy-1836445_1280.jpg",
		date: "11/07/2021",
		text: "Consectetur elit nulla commodo duis duis ut incididunt excepteur occaecat sint in quis nulla. Magna consequat sit aliqua veniam id deserunt ut elit id. Eu reprehenderit cillum duis culpa id minim cupidatat Lorem cillum anim. Aute pariatur aliqua anim anim velit nulla.",
	},
	answers: [
		{
			image: require("../assets/images/logo_small.png"),
			name: "Caio Cezar Greeko",
			date: "11/07/2021",
			text: "Consectetur elit nulla commodo duis duis ut incididunt excepteur occaecat sint in quis nulla. Magna consequat sit aliqua veniam id deserunt ut elit id. Eu reprehenderit cillum duis culpa id minim cupidatat Lorem cillum anim. Aute pariatur aliqua anim anim velit nulla.",
		},
	],
};
