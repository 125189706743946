import styled from "styled-components";
import { w, ww } from "../../../styles/responsive";
import { ReactComponent as Modality } from "../../../assets/icons/modality@2.svg";
import { ReactComponent as Group } from "../../../assets/icons/group.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar@2.svg";
import { ReactComponent as CalendarCheck } from "../../../assets/icons/calendar_check.svg";

export const GroupIcon = styled(Group).attrs({
	width: w(27),
	height: w(27),
})``;

export const ModalityIcon = styled(Modality).attrs({
	width: w(27),
	height: w(27),
})``;

export const CalendarIcon = styled(Calendar).attrs({
	width: w(27),
	height: w(27),
})``;

export const CalendarCheckIcon = styled(CalendarCheck).attrs({
	width: w(27),
	height: w(27),
})``;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: ${ww(380)}px;
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: ${ww(80)}px;
	width: ${ww(90)}px;
	background: #fff;
	border: 1px solid #e5e5e5;
	border-radius: ${ww(2)}px;
	cursor: pointer;
`;

export const Title = styled.p`
	font-size: ${ww(14)}px;
	font-weight: 400;
	color: #3b4ba2;
	margin: ${w(5, 0)};
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 400;
	color: #3b4ba2;
`;
