import React from "react";
import { FieldError } from "react-hook-form";

import * as S from "./fieldInputCatalog.styles";

type GridAreaFormProps =
	| "file"
	| "type"
	| "name"
	| "description"
	| "link"
	| "categories"
	| "highlighted";

interface FieldInputCatalogProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	error?: FieldError;
	multiline?: boolean;
	gridArea?: GridAreaFormProps;
}

const FieldInputCatalogComponent: React.ForwardRefRenderFunction<
	HTMLInputElement,
	FieldInputCatalogProps
> = (
	{
		name,
		label,
		value,
		placeholder,
		gridArea,
		onChange,
		error = null,
		...rest
	},
	ref,
) => {
	const hasError = !!error;

	return (
		<S.Container
			className={hasError ? "error" : ""}
			hasError={hasError}
			style={{ gridArea }}
		>
			<S.Label
				isActive={!!placeholder || !!value}
				htmlFor={name}
				placeholder={placeholder}
			>
				{label}
			</S.Label>

			<S.Input
				id={name}
				name={name}
				placeholder={placeholder}
				ref={ref}
				onChange={(e) => onChange?.(e)}
				value={value}
				{...rest}
			/>

			<S.Line />
		</S.Container>
	);
};

export const FieldInputCatalog = React.forwardRef(FieldInputCatalogComponent);

interface FieldTextareaCatalogProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label: string;
	error?: FieldError;
	gridArea?: GridAreaFormProps;
}

const FieldTextareaCatalogComponent: React.ForwardRefRenderFunction<
	HTMLTextAreaElement,
	FieldTextareaCatalogProps
> = (
	{ name, label, value, placeholder, gridArea, error = null, ...rest },
	ref,
) => {
	const hasError = !!error;

	return (
		<S.Container
			className={hasError ? "error" : ""}
			hasError={hasError}
			style={{ gridArea }}
		>
			<S.Label
				isActive={!!placeholder || !!value}
				htmlFor={name}
				placeholder={placeholder}
			>
				{label}
			</S.Label>

			<S.InputTextArea
				id={name}
				name={name}
				placeholder={placeholder}
				rows={undefined}
				ref={ref}
				value={value}
				{...rest}
			/>

			<S.Line />
		</S.Container>
	);
};
export const FieldTextareaCatalog = React.forwardRef(
	FieldTextareaCatalogComponent,
);
