import { FormValues } from "../../../types/form";

export const validate = (value: FormValues, setError: any) => {
	const { currentPassword, newPassword, confirmNewPassword } = value;

	const passError = {
		currentPassword: false,
		newPassword: false,
		confirmNewPassword: false,
	};

	if (typeof currentPassword === "string") {
		if (!value.currentPassword?.length) {
			passError.currentPassword = true;
			setError("currentPassword", {
				type: "required",
				message: "Informe a senha atual.",
			});
		}
	}

	if (typeof newPassword === "string") {
		if (!value.newPassword?.length) {
			passError.newPassword = true;
			setError("newPassword", {
				type: "required",
				message: "Informe uma nova senha.",
			});
		} else if (value.newPassword.length < 6) {
			passError.newPassword = true;
			setError("newPassword", {
				type: "required",
				message: "Mínimo 6 characteres.",
			});
		} else if (value.newPassword.length > 20) {
			passError.newPassword = true;
			setError("newPassword", {
				type: "required",
				message: "Máximo 20 characteres.",
			});
		}
	}
	if (typeof confirmNewPassword === "string") {
		if (
			value.newPassword?.localeCompare(value.confirmNewPassword!) ||
			!value.confirmNewPassword?.length
		) {
			passError.confirmNewPassword = true;
			setError("confirmNewPassword", {
				type: "required",
				message: "Valor diferente do campo nova senha.",
			});
		}
	}

	return (
		passError.currentPassword ||
		passError.newPassword ||
		passError.confirmNewPassword
	);
};
