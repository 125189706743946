import { compareAsc } from "date-fns";
import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { ww } from "../../styles/responsive";
import { DashboardChartProps } from "../../types/dashboard";

const LineChartComponent: React.FC<DashboardChartProps> = ({ dataChart }) => {
	const [isAboutMoney, setIsAboutMoney] = React.useState(true);

	React.useEffect(() => {
		if (!dataChart) {
			return;
		}

		// eslint-disable-next-line no-unsafe-optional-chaining
		if ("Alunos" in dataChart?.[0]) {
			setIsAboutMoney(false);
		} else {
			setIsAboutMoney(true);
		}
	}, [dataChart]);

	return (
		<ResponsiveContainer width="100%" height="90%">
			<LineChart
				width={ww(262)}
				height={ww(154)}
				data={dataChart}
				margin={{
					top: ww(11),
					right: ww(18),
					left: ww(2),
					bottom: ww(6),
				}}
			>
				<CartesianGrid strokeDasharray="2 2" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />
				{!isAboutMoney ? (
					<Line
						type="monotone"
						dataKey="Alunos"
						stroke="#30BA88"
						activeDot={{ r: 8 }}
						strokeWidth={2}
					/>
				) : (
					<>
						<Line
							type="monotone"
							dataKey="Valor Greeko"
							stroke="#30BA88"
							activeDot={{ r: 8 }}
							strokeWidth={2}
						/>
						<Line
							type="monotone"
							dataKey="Valor Personais"
							stroke="#596CD4"
							activeDot={{ r: 8 }}
							strokeWidth={2}
						/>
						<Line
							type="monotone"
							dataKey="Valor Total"
							stroke="#2A3467"
							activeDot={{ r: 8 }}
							strokeWidth={2}
						/>
					</>
				)}
			</LineChart>
		</ResponsiveContainer>
	);
};

export default LineChartComponent;
