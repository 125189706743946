import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";

//convert date to format: mm-dd-yy => dd-mm-yy
export const convertDate_mm_dd_yy = (date: string) => {
	const parts = date.split("/").map((p) => parseInt(p, 10));
	const newDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
	const currentYear = new Date().getFullYear().toString().slice(-2);

	return { newDate, currentYear };
};

//convert date to format: yy-mm-dd => dd-mm-yy
export const convertDate_yy_mm_dd = (date: string) => {
	const newString = date.substring(0, 10);
	const parts = newString.split("-").map((p) => parseInt(p, 10));
	const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
	const currentYear = new Date().getFullYear().toString().slice(-2);

	return { newDate, currentYear };
};

//convert date to format: 2022-07-12T12:48:30.000Z => dd/mm/yyyy
export const convertDate_yyyy_mm_dd = (date: string) => {
	const newString = date.substring(0, 10);
	const parts = newString.split("-").map((p) => parseInt(p, 10));
	const newDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
	return newDate;
};

export const formatDataToFinanceDetailsItems = (date: string) => {
	const newDate = format(new Date(date), "dd - MMM, EEEE, HH:mm", {
		locale: ptBR,
	});

	return newDate;
};

export const formatDateTo_dd_mm_yyyy_hh_mm_ss = (date: string) => {
	const newDate = format(new Date(date), "dd-LL-yyyy_HH-mm-ss", {
		locale: ptBR,
	});

	return newDate;
};
