import React from "react";
import StudentAnnotations from "../../components/student/anotations/anotations.component";
import StudentAnamnese from "../../components/student/anamnese/anamnese.component";
import SupportDetails from "../../components/support/details/details.component";
import { GeneralContext } from "../../contexts/generalContext";
import ModalityDetails from "../../components/modality/modality.component";
import AdminUserDetails from "../../components/admin-user/admin-user.component";
import MyAccountEditProfile from "../../components/my-account/edit-profile/edit-profile.component";
import MyAccountRedefinePassword from "../../components/my-account/redefine-password/redefine-password.component";
import { FinanceDetailsModal } from "../../components/finance-details";
import { useNavigate } from "react-router-dom";

const DetailsPageModal: React.FC = () => {
	const { pageDetails } = React.useContext(GeneralContext);

	const navigate = useNavigate();

	/* eslint-disable */
	switch (pageDetails?.route) {
		case "finance":
			return <FinanceDetailsModal />;
		case "support":
				return <SupportDetails />;
		case "student/annotations":
			return <StudentAnnotations />;
		case "student/anamnese":
			return <StudentAnamnese />;
		case "modality":
			return <ModalityDetails />;
		case "category":
			navigate('/details');
			return <></>
		case "admin-users":
			return <AdminUserDetails />;
		case "my-account/edit-profile":
			return <MyAccountEditProfile />;
		case "my-account/redefine-password":
			return <MyAccountRedefinePassword />;
		default:
			return <></>;
	}
	/* eslint-enable */
};

export default DetailsPageModal;
