import styled from "styled-components";
import { ww } from "../../styles/responsive";

export const Container = styled.div`
	height: ${ww(140)}px;
	max-height: ${ww(140)}px;
	overflow-y: scroll;
	border: 1px solid #c6c2de;
	border-radius: ${ww(3)}px;
	font-size: ${ww(9)}px;
	color: #4c4c4c;
	padding: ${ww(3)}px;
`;
