import React from "react";
import { GeneralContext } from "../../contexts/generalContext";
import { ww } from "../../styles/responsive";

import { SelectProps, SelectStyle } from "../../types/select";

import {
	Container,
	ErrorMessage,
	SelectContainer,
	IconContainer,
	Select,
	Text,
	ShowContainer,
	ShowOptions,
	SelectContainerPageItems,
	Divisor,
	SelectTooSmall,
	ShowContainerPageItems,
	IconCheckBoxChecked,
	IconCheckBoxEmpty,
} from "./select.styles";

export type SelectItem = {
	title: string;
	value: string;
};

const SelectComponent: React.FC<SelectProps & SelectStyle> = ({
	data,
	setData,
	children,
	isPageItems = false,
	setStatus,
	setModality,
	setCountryOfOperation,
	hasCheckBox,
	initialValue = 0,
	width,
	error,
}) => {
	const { setCurrentPage } = React.useContext(GeneralContext);
	const [show, setShow] = React.useState(false);
	const [checked, setChecked] = React.useState([""]);
	const [selected, setSelected] = React.useState({
		title: data?.[initialValue]?.title,
		value: data?.[initialValue]?.value,
	});

	React.useEffect(() => {
		setData?.(
			data?.[!!initialValue && initialValue >= 0 ? initialValue : 0]?.value,
		);
		if (initialValue >= 0) {
			setSelected({
				title: data?.[initialValue!]?.title,
				value: data?.[initialValue!]?.value,
			});
		}
	}, [initialValue]);

	const setCheckBox = (title: string) => {
		const elementIndex = checked.indexOf(title);

		if (elementIndex > 0) {
			const newChecked = [...checked];
			newChecked.splice(elementIndex, 1);

			setChecked(newChecked);
			setModality?.(newChecked);
			setCountryOfOperation?.(newChecked);
		} else {
			setChecked([...checked, title]);
			checked?.shift();

			setModality?.([...checked, title]);
			setCountryOfOperation?.([...checked, title]);
		}
	};

	/* eslint-disable */
	const InputContainerSize = isPageItems
		? SelectContainerPageItems
		: SelectContainer;
	const SelectSize = isPageItems ? SelectTooSmall : Select;

	const DropdownOptions = isPageItems ? ShowContainerPageItems : ShowContainer;
	/* eslint-enable */

	return (
		<Container className="ELIAN-É-AQUI">
			<InputContainerSize
				hasChildren={!!children}
				isFocus={true}
				onClick={() => setShow(!show)}
				width={width}
			>
				<SelectSize isFocus hasChildren={!!children} width={width}>
					{hasCheckBox ? (
						<>
							<IconCheckBoxChecked />
							<Text>{checked.length - 1} selecionadas</Text>
						</>
					) : (
						<Text>{selected.title ?? "Selecione"}</Text>
					)}
				</SelectSize>
				<IconContainer selected={show}>{children}</IconContainer>
			</InputContainerSize>
			{show && (
				<DropdownOptions
					className={`${data?.[0]?.title} scroll`}
					hasChildren={!!children}
					isFocus={true}
					qtdeItems={data.length!}
					width={width}
				>
					{data.map(({ title, value }, index) => {
						return (
							<React.Fragment key={value}>
								<ShowOptions
									key={index}
									isFocus
									hasChildren={!!children}
									onClick={() => (
										!hasCheckBox && setShow(!show),
										setSelected({ title, value }),
										setData?.(value),
										!hasCheckBox && setStatus?.(value),
										hasCheckBox && setCheckBox(title),
										isPageItems && setCurrentPage?.(1)
									)}
									hasCheckBox={!!hasCheckBox}
								>
									{hasCheckBox &&
										(checked.includes(title) ? (
											<IconCheckBoxChecked />
										) : (
											<IconCheckBoxEmpty />
										))}
									<Text>{title}</Text>
								</ShowOptions>
								{data.length !== 1 && index !== data.length - 1 && <Divisor />}
							</React.Fragment>
						);
					})}
				</DropdownOptions>
			)}
			{!!error && <ErrorMessage>{error}</ErrorMessage>}
		</Container>
	);
};

export default SelectComponent;
