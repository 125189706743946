import React from "react";
import { GeneralContext } from "../../../contexts/generalContext";
import SelectComponent from "../../table-footer-select/select.component";
import {
	Container,
	SubContainer,
	Text,
	IconContainer,
	ArrowLeftIcon,
	ArrowRightIcon,
	ArrowDownIcon,
	IconSubContainer,
} from "./table-footer.styles";

const TableFooter: React.FC = () => {
	const { currentPage, setCurrentPage, totalItems, itemsPage, setItemsPage } =
		React.useContext(GeneralContext);

	const maxItems = currentPage * itemsPage;

	const pageInterval = `${currentPage * itemsPage - itemsPage + 1}-${
		maxItems < totalItems ? maxItems : totalItems
	}`;

	const togglePage = (direction: string) => {
		if (direction === "left") {
			currentPage > 1 && setCurrentPage?.(currentPage - 1);
		} else {
			maxItems < totalItems && setCurrentPage?.(currentPage + 1);
		}
	};

	return (
		<Container>
			<SubContainer>
				<Text>Itens por página: </Text>
				<SelectComponent
					data={[
						{ title: "10", value: 10 },
						{ title: "25", value: 25 },
						{ title: "50", value: 50 },
					]}
					setData={setItemsPage}
					isPageItems={true}
				>
					<ArrowDownIcon />
				</SelectComponent>
			</SubContainer>

			<SubContainer>
				<Text>
					{pageInterval} de {totalItems}
				</Text>
			</SubContainer>

			<IconContainer>
				<IconSubContainer onClick={() => togglePage("left")}>
					<ArrowLeftIcon />
				</IconSubContainer>
				<IconSubContainer onClick={() => togglePage("right")}>
					<ArrowRightIcon />
				</IconSubContainer>
			</IconContainer>
		</Container>
	);
};

export default TableFooter;
