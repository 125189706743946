import React from "react";

import { Route, Routes } from "react-router-dom";
import DetailsPage from "../details/details.component";
import PersonalPage from "./main/student.component";

const StudentIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<PersonalPage />} />
			<Route path="details" element={<DetailsPage />} />
		</Routes>
	);
};

export default StudentIndex;
