import { compareAsc } from "date-fns";
import React from "react";
import { linearChartMoney, quantityClasses } from "../../../mockup/dashboard";
import {
	LinearChartClassProps,
	LinearChartMoneyProps,
} from "../../../models/dashboard";
import { DashboardData } from "../../../services/dashboard.service";
import LineChartComponent from "../../charts/line-chart.components";

import {
	Container,
	Title,
	Text,
	TitleContainer,
	ActionsContainer,
	ButtonsContainer,
	ButtonLeft,
	ButtonRight,
} from "./chart-line.styles";

interface ChartDashboardProps {
	data: {
		name: string;
		Alunos: number;
	}[];
}

const ChartDashboard: React.FC<ChartDashboardProps> = (props) => {
	// const [dataChart, setDataChart] = React.useState<
	// 	LinearChartMoneyProps[] | LinearChartClassProps[]
	// >(quantityClasses);

	if (!props.data) {
		return null;
	}

	return (
		<Container>
			<TitleContainer>
				<Title>Histórico de Aulas</Title>
				<ActionsContainer>
					<Text>Dados em:</Text>
					<ButtonsContainer>
						{/* <ButtonLeft
							selected={selected === 0}
							onClick={() => {
								setSelected(0);
								setDataChart(linearChartMoney);
							}}
						>
							<Text selected={selected === 0}>Dinheiro</Text>
						</ButtonLeft> */}
						<ButtonRight selected>
							<Text selected>Qtd. de aulas</Text>
						</ButtonRight>
					</ButtonsContainer>
				</ActionsContainer>
			</TitleContainer>
			<LineChartComponent dataChart={props.data} />
		</Container>
	);
};

export default ChartDashboard;
