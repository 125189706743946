import styled, { css } from "styled-components/macro";
import { w, ww } from "../../../styles/responsive";
import { ChartProps } from "../../../types/dashboard";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: #fff;
	margin-top: ${ww(13)}px;
	height: ${ww(273)}px;
	border: 1px solid #e5e5e5;
	border-radius: ${ww(2)}px;
`;

export const Title = styled.p`
	font-size: ${ww(9)}px;
	font-weight: 700;
	color: #4b4b4b;
`;

export const Text = styled.p<ChartProps>`
	font-size: ${ww(7)}px;
	font-weight: 500;
	color: ${({ selected }) => (selected ? "#fff" : "#4b4b4b")};
`;

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	padding: ${w(8, 11)};
	justify-content: space-between;
`;

export const ActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: ${ww(5)}px;
`;

const button = css<ChartProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${ww(49)}px;
	height: ${ww(16)}px;
	border-radius: 0px;
	background: ${({ selected }) => (selected ? "#30BA88" : "#F7F7F7")};
`;

export const ButtonLeft = styled.button<ChartProps>`
	${button}
	border-radius: 5px 0px 0px 5px;
`;

export const ButtonRight = styled.button<ChartProps>`
	${button}
	border-radius: 0px 5px 5px 0px;
`;
