import React from "react";

import { GeneralContext } from "../../contexts/generalContext";
import { ww } from "../../styles/responsive";
import Alert from "../alert/alert.component";
import Button from "../button/button.components";

import {
	Container,
	Box,
	Text,
	ButtonContainer,
	Title,
	Form,
	SelectContainer,
	SubContainer,
	ActionContainer,
	Section,
	SectionContainer,
	VerticalDivisor,
	HorizontallDivisor,
	ArrowDownIcon,
} from "./countries.styles";
import SelectComponent from "../select/select.component";
import DocumentList from "./documentList/documentList.component";
import DocumentSettings from "./documentSettings/documentSettings.component";
import { useNavigate } from "react-router-dom";
import { emptyDocument } from "../../mockup/countries";
import { CountryDetailProps } from "../../types/country";

import { CCA2Country, coins, countries } from "../../data/countries";
import { apiAntiga } from "../../services/api/axios";
import { onlySpaces } from "../../utils/string";
import { Pagination } from "../../types/requestPagination";

type DocumentType =
	| "TERMS_OF_USE"
	| "PROFILE_PICTURE"
	| "PERSONAL_DOCUMENT"
	| "PROFESSIONAL_LICENSE";

type GetCountryDetailsResponse = {
	cca2: CCA2Country;
	name: string;
	active: boolean;
	currencyType: null | string;
	document_to_country: {
		id: number | string;
		documentType: DocumentType;
		active: boolean;
		appHtml: string;
	}[];
};

type HasErrorFieds = {
	country: string;
	coin: string;
	TERMS_OF_USE: string;
	PROFILE_PICTURE: string;
	PERSONAL_DOCUMENT: string;
	PROFESSIONAL_LICENSE: string;
};

type DocumentCountry = {
	active: boolean;
	documentType: DocumentType;
	appHtml: string;
};

interface Country {
	cca2: CCA2Country;
	name: string;
	active: boolean;
}

interface GetCountriesRequest {
	items: Country[];
	meta: Pagination;
}

const CountriesDetails: React.FC<CountryDetailProps> = ({ id, status }) => {
	const { setPageDetails, setEditData } = React.useContext(GeneralContext);

	const [showAlert, setShowAlert] = React.useState(false);
	const [showEnableCountryAlert, setShowEnableCountryAlert] =
		React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [selected, setSelected] = React.useState<string>("TERMS_OF_USE");
	const [documents, setDocuments] = React.useState(emptyDocument);
	const [showCautionAlert, setShowCautionAlert] = React.useState(false);
	const [country, setCountry] = React.useState();
	const [showCancelAlert, setShowCancelAlert] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState<string>(
		emptyDocument[0].id,
	);
	const [selectedCoin, setSelectedCoin] = React.useState<string>();
	const [initialValueCoin, setInitialValueCoin] = React.useState<string>();

	const [isLoadedDetails, setIsLoadedDetails] = React.useState(false);

	const [hasChanged, setHasChanged] = React.useState(false);
	const [error, setError] = React.useState<HasErrorFieds>({
		country: "",
		coin: "",
		TERMS_OF_USE: "",
		PROFILE_PICTURE: "",
		PERSONAL_DOCUMENT: "",
		PROFESSIONAL_LICENSE: "",
	});

	const navigate = useNavigate();

	async function getCountryDetails(
		cca2: string,
	): Promise<GetCountryDetailsResponse | undefined> {
		try {
			const response = await apiAntiga.get<GetCountryDetailsResponse>(
				`/admin/countries/${cca2}`,
			);

			return response.data;
		} catch (err) {
			console.error(err);
		}
	}

	async function getCountriesSaved() {
		const response = await apiAntiga.get<GetCountriesRequest>(
			"/admin/countries",
			{
				params: {
					page: 1,
					limit: 9999,
				},
			},
		);

		const countriesCodes = response.data.items.map((item) => {
			return item.cca2;
		});

		return countriesCodes;
	}

	React.useEffect(() => {
		const countriesSelect: any = [];

		if (id) {
			getCountryDetails(id)
				.then((data) => {
					if (!data) {
						throw new Error("Houve um erro ao buscar detalhes do país!");
					}

					setInitialValueCoin(data.currencyType as string);
					setDocuments((oldDocs) => {
						const newDocs: any = oldDocs.map((item) => {
							const newItem = data.document_to_country.find(
								(doc) => doc.documentType === item.id,
							);
							return {
								...item,
								status: newItem?.active,
								description: newItem?.appHtml,
							};
						});

						return newDocs;
					});

					setIsLoadedDetails(true);
				})
				.catch((err) => {
					alert("Houve um erro, tente novamente!");
					navigate("/countries");
				});
		} else {
			getCountriesSaved().then((data) => {
				countries
					.filter((item) => !data.includes(item.code as any))
					.forEach((item) => {
						countriesSelect.push({ title: item.name.por, value: item.code });
					});

				setCountry(countriesSelect);
			});
		}
	}, [id]);

	function validateFieldsCountry(documentsCountries: DocumentCountry[]) {
		let hasError = false;
		const errors: HasErrorFieds = {
			country: "",
			coin: "",
			TERMS_OF_USE: "",
			PERSONAL_DOCUMENT: "",
			PROFESSIONAL_LICENSE: "",
			PROFILE_PICTURE: "",
		};

		if (!selectedData) {
			errors.country = "Selecione um país!";
			hasError = true;
		}

		if (!selectedCoin) {
			errors.coin = "Selecione uma moeda!";
			hasError = true;
		}

		documentsCountries.forEach((document) => {
			if (!document.active) {
				return;
			}

			const regex = /(<([^>]+)>)/gi;
			const appHtml = document.appHtml
				.replaceAll(regex, "")
				.replaceAll("\n", "");

			const isEmptyField = onlySpaces(appHtml);

			if (isEmptyField) {
				errors[document.documentType] = "Campo obrigatório!";
				hasError = true;
			}
		});

		setError(errors);

		return !hasError;
	}

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			setIsLoading(true);

			const cca2 = id ?? selectedData;

			const document_countries = documents.map((document) => {
				return {
					active: document.status,
					documentType: document.id,
					appHtml: document.description,
				};
			});

			if (!validateFieldsCountry(document_countries as DocumentCountry[])) {
				return;
			}

			const data = {
				cca2,
				currencyType: selectedCoin,
				name: countries?.find((item) => item.code === cca2)?.name.por,
				document_countries,
			};

			let response;

			if (id) {
				response = await apiAntiga.patch(`admin/countries/${id}`, data);
			} else {
				response = await apiAntiga.post("admin/countries", data);
			}

			if ([200, 201].includes(response.status)) {
				setShowAlert(true);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
			setEditData?.({});
		}
	};

	const handeDisableOrRemoveCountry = async () => {
		setIsLoading(true);

		try {
			if (id) {
				await apiAntiga.post(`admin/countries/${id}/disable`);
			}

			setIsLoading(false);
			navigate(-1);
		} catch (error) {
			console.error("Erro ao desabilitar ou remover país: ", error);
		}

		// setIsLoading(true);

		// if (!id) return navigate(-1);
		// setTimeout(() => {
		// 	setIsLoading(false);
		// 	setShowCautionAlert(false);
		// 	setPageDetails?.(false);
		// }, 2000);

		// setEditData?.({});
	};

	async function handleEnableCountry() {
		setIsLoading(true);
		try {
			await apiAntiga.post(`admin/countries/${id}/activate`);
			setIsLoading(false);
			navigate(-1);
		} catch (error) {
			//
		}
	}

	function closeModal() {
		setShowAlert(false);
		setShowCautionAlert(false);
		setEditData?.({});
	}

	if (!!id && !isLoadedDetails) {
		return null;
	}

	return (
		<Container>
			<Box>
				{!id && (
					<>
						<Title>Selecione o País Desejado</Title>

						<SelectContainer>
							<SelectComponent
								data={country!}
								width={ww(252)}
								setData={setSelectedData}
								initialValue={id as any}
								error={error.country}
							>
								<ArrowDownIcon />
							</SelectComponent>
						</SelectContainer>
					</>
				)}
				<Form onSubmit={onSubmit}>
					<SectionContainer>
						<Section>
							<Text>Selecione os Documentos que serão Solicitados</Text>

							<DocumentList
								data={documents}
								setData={setSelected}
								hasChanged={hasChanged}
								selected={selected}
							/>
						</Section>
						<VerticalDivisor />
						<Section>
							<SelectContainer style={{ marginTop: !id ? "-16.8%" : 0 }}>
								<SelectComponent
									data={coins.map((coin) => ({
										title: coin,
										value: coin,
									}))}
									width={ww(252)}
									setData={setSelectedCoin}
									initialValue={coins.findIndex(
										(item) => item === initialValueCoin,
									)}
									error={error.coin}
								>
									<ArrowDownIcon />
								</SelectComponent>
							</SelectContainer>

							<Text>Configure a Documentação Selecionada</Text>

							<DocumentSettings
								id={selected}
								data={documents}
								hasChanged={hasChanged}
								setHasChanged={setHasChanged}
								error={error[selected as DocumentType]}
							/>
						</Section>
					</SectionContainer>
					<HorizontallDivisor />
					<SubContainer>
						<ActionContainer>
							{!!id && (
								<ButtonContainer width={ww(72)}>
									{status === "Ativo" && (
										<Button
											title="Desativar País"
											clickFunction={() => setShowCautionAlert(true)}
											customColor={"#FF6868"}
										/>
									)}

									{status === "Inativo" && (
										<Button
											title="Habilitar País"
											clickFunction={handleEnableCountry}
											isLoading={isLoading}
										/>
									)}
								</ButtonContainer>
							)}
							<ButtonContainer width={ww(72)}>
								<Button
									title={id ? "Cancelar" : "Descartar"}
									inverterColor
									clickFunction={
										/*eslint-disable*/
										id
											? () => setShowCancelAlert(true)
											: () => setShowCautionAlert(true)
										/*eslint-enable*/
									}
								/>
							</ButtonContainer>
						</ActionContainer>

						<ButtonContainer width={ww(131)}>
							<Button
								type="submit"
								title={id ? "Salvar" : "Adicionar"}
								isLoading={!showCautionAlert && isLoading}
							/>
						</ButtonContainer>
					</SubContainer>
				</Form>
			</Box>
			{showCautionAlert && (
				<Alert
					text={
						id
							? "Tem certeza que deseja desativar esse país?"
							: "Ao descartar, todas as alterações serão perdidas."
					}
					buttonText={id ? "Sim, Desejo Desativar" : "Sim, Desejo Descartar"}
					description={
						id
							? "Os usuário cadastrados nesse país até o momento continuarão ativos, porém este país deixará de aparecer como opção para novos cadastros."
							: undefined
					}
					clickFunction={closeModal}
					cautionFunction={handeDisableOrRemoveCountry}
					isLoading={isLoading}
					type="danger"
				/>
			)}

			{showCancelAlert && (
				<Alert
					type="danger"
					text={"Ao cancelar, todas as alterações serão perdidas."}
					buttonText="Não Cancelar"
					clickFunction={() => {
						setPageDetails?.(false);
						setShowCancelAlert(false);
						navigate(-1);
					}}
					buttonSecondaryText="Sim, Cancelar"
					cautionFunction={() => setShowCancelAlert(false)}
				/>
			)}

			{showAlert && (
				<Alert
					text={
						id
							? "Documentação modificada com sucesso!"
							: "Documentação adicionada com sucesso!"
					}
					buttonText="Ok"
					clickFunction={() => (
						setShowAlert(false),
						setPageDetails?.(false),
						setEditData?.({}),
						navigate(-1)
					)}
				/>
			)}
			{showEnableCountryAlert && (
				<Alert
					text={
						id
							? "Documentação modificada com sucesso!"
							: "Documentação adicionada com sucesso!"
					}
					buttonText="Ok"
					clickFunction={() => (
						setShowAlert(false),
						setPageDetails?.(false),
						setEditData?.({}),
						navigate(-1)
					)}
				/>
			)}
		</Container>
	);
};

export default CountriesDetails;
