import styled from "styled-components";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Filter } from "../../assets/icons/filter_alt.svg";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: ${ww(23)}px;
	align-items: flex-start;
	justify-content: space-between;
	margin-right: auto;
	padding-left: ${ww(6)}px;
	margin-bottom: ${ww(6)}px;
`;

export const SubContainer = styled.div`
	display: flex;
`;

export const SearchIcon = styled(Search).attrs({
	width: w(12),
	height: w(12),
})``;
export const FilterIcon = styled(Filter).attrs({
	width: w(14),
	height: w(14),
})``;

export const ButtonContainer = styled.div`
	display: flex;
	width: ${ww(50)}px;
	margin-right: ${ww(6)}px;
`;
