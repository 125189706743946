import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { GeneralContext } from "../../../contexts/generalContext";
import { ww } from "../../../styles/responsive";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import InputComponent from "../../input/input.component";
import { validate } from "./validate-input";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	Title,
	Form,
	InputContainer,
	CancelContainer,
} from "../my-account.styles";

import { MyAccountInput } from "../../../types/input";
import { adminApi, apiAntiga } from "../../../services/api/axios";
import { PostAuthRequest } from "../../auth/signIn/signIn.component";

const MyAccountRedefinePassword: React.FC = () => {
	const { setPageDetails, setEditData, editData } =
		React.useContext(GeneralContext);

	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<MyAccountInput>();

	const [isLoading, setIsLoading] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);

	const { id, email } = editData;

	const handleOnFocusInput = (
		errorName: "currentPassword" | "newPassword" | "confirmNewPassword",
	) => {
		return () => setError(errorName, { type: "", message: "" });
	};

	async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (!id) {
			console.error(
				"Erro ao redefinir senha: id do usuário logado não é nulo ou indefinido",
			);
		}
		event.preventDefault();

		const currentPassword = getValues("currentPassword");
		const newPassword = getValues("newPassword");
		const confirmNewPassword = getValues("confirmNewPassword");

		// simulate a auth and return error message if the password is wrong
		try {
			await adminApi.post<PostAuthRequest>("/auth", {
				email: email,
				password: currentPassword,
			});
		} catch (error) {
			setError("currentPassword", {
				type: "required",
				message: "Atenção! A senha atual está incorreta.",
			});
			setIsLoading(false);
			return;
		}

		const hasError = validate(
			{ currentPassword, newPassword, confirmNewPassword },
			setError,
		);

		if (hasError) {
			return;
		}

		setIsLoading(true);

		const newData = {
			currentPassword: String(currentPassword),
			newPassword: String(newPassword),
		};

		const response = await apiAntiga.patch(`/admins/${id}/password`, newData);

		if (response.status !== 200) return;

		setTimeout(() => {
			setShowAlert(true);
			setIsLoading(false);
		}, 2000);
	}

	function closeModal() {
		setShowAlert(false);
		setPageDetails?.(false);
		setEditData?.({});
	}

	return (
		<Container>
			<Box>
				<IconContainer onClick={closeModal}>
					<Icon />
				</IconContainer>

				<Title>Redefinir Senha</Title>

				<Form onSubmit={onSubmit}>
					<InputContainer>
						<InputComponent
							register={register}
							required={true}
							value={getValues("currentPassword")}
							onFocus={handleOnFocusInput("currentPassword")}
							name="currentPassword"
							type="password"
							placeholder="Senha Atual"
							error={errors.currentPassword?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={getValues("newPassword")}
							onFocus={handleOnFocusInput("newPassword")}
							name="newPassword"
							type="password"
							placeholder="Nova Senha"
							error={errors.newPassword?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={getValues("confirmPassword")}
							onFocus={handleOnFocusInput("confirmNewPassword")}
							name="confirmNewPassword"
							type="password"
							placeholder="Confirmar Nova Senha"
							error={errors.confirmNewPassword?.message}
							width={ww(164)}
							isSecondary={true}
						/>
					</InputContainer>

					<ButtonContainer>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</ButtonContainer>
				</Form>
				<CancelContainer>
					<Text onClick={closeModal}>Cancelar</Text>
				</CancelContainer>
			</Box>
			{showAlert && (
				<Alert
					text="Nova senha redefinida com sucesso."
					buttonText="Ok"
					clickFunction={closeModal}
				/>
			)}
		</Container>
	);
};

export default MyAccountRedefinePassword;
