export const maskDate = (date: string) => {
	return date
		.replace(/\D/g, "") // remove any character without number
		.replace(/(\d{2})(\d)/, "$1/$2") // captures 2 groups of number the first of 2, after capturing the first group it adds a"/" before the second group of number
		.replace(/(\d{2})(\d)/, "$1/$2")
		.replace(/(\/\d{2})\d+?$/, "$1");
};

export const maskContabil = (amount: string) => {
	const newAmount = amount.replace(/\D/g, "").replace(/([0-9]{2})$/g, ",$1");

	if (newAmount.length > 6) {
		return newAmount.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
	}

	return newAmount;
};

export const phoneMask = (number: string) => {
	const r = number.replace(/\D/g, "");

	if (r.length > 10) {
		return r.replace(/^(\d\d)(\d{2})(\d{5})(\d{0,4}).*/, "+$1 ($2) $3-$4");
	} else if (r.length > 7) {
		return r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
	}
};

const mask = (data: string, type: string) => {
	/* eslint-disable */
	switch (type) {
		case "date":
			return maskDate(data);
		case "contabil":
			return maskContabil(data);
		case "phone":
			return phoneMask(data);
		default:
			return data;
	}
	/* eslint-enable */
};

export default mask;
