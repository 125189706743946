import styled from "styled-components";

export const InputNameContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	max-width: 450px;

	border: 1px solid #d5d5d5;
	border-radius: 5px;

	padding: 10px 16px;
`;

type InputName = {
	error?: string;
};

export const Input = styled.input<InputName>`
	font-family: "Inter";
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	margin-top: 24px;
	width: 80%;
	padding-left: 8px;

	border-radius: 0;
	border-bottom: 1px solid ${({ error }) => (error ? "#ff6868" : "#d5d5d5")};

	&::placeholder {
		color: #b2b2b2;

		font-style: italic;
	}
`;

export const Box = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Label = styled.label`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	color: #3d4da7;
`;

export const MinorText = styled.span`
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;

	color: #7c7c7c;
`;
