import React from "react";
import { Container } from "./table-footer.styles";

interface TableFooterProps {
	children?: React.ReactNode;
}

const TableFooter: React.FC = ({ children }: TableFooterProps) => {
	return <Container>{children}</Container>;
};

export default TableFooter;
