import React from "react";
import { useNavigate } from "react-router-dom";

import { AlertProps } from "../../types/alert";
import Button from "../button/button.components";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	CautionIcon,
	DangerIcon,
	TextContainer,
} from "./alert.styles";

const Alert: React.FC<AlertProps> = ({
	text,
	buttonText,
	buttonLink,
	buttonSecondaryText = "Cancelar",
	description = "",
	type = "alert",
	isLoading = false,
	hideCancelButton = false,
	clickFunction,
	cautionFunction,
}) => {
	const navigate = useNavigate();

	const renderButtonProps = () => {
		if (type === "caution") return { customColor: "#EFD700" };
		if (type === "danger") return { customColor: "#FF6868" };
		return {};
	};

	const goTo = () => {
		if (buttonLink) return navigate(buttonLink!);
		if (type === "caution" || type === "danger") return cautionFunction?.();
		return clickFunction?.();
	};

	const renderIcon = () => {
		if (type === "caution") return <CautionIcon />;
		if (type === "danger") return <DangerIcon color="#FF6868" />;
		return <Icon />;
	};

	const buttonProps = renderButtonProps();

	const texts = text.split(":");

	return (
		<Container>
			<Box type={buttonProps.customColor}>
				<IconContainer>{renderIcon()}</IconContainer>
				<Text>
					{texts[0]}
					{texts.length > 1 ? ":" : ""}
				</Text>
				{description && <Text>{description}</Text>}
				{type && texts[1] && (
					<Text style={{ fontWeight: 700 }}>{texts[1]}</Text>
				)}
				<Button
					title={buttonText}
					clickFunction={goTo}
					{...buttonProps}
					isLoading={isLoading}
				/>

				{((!isLoading && type === "caution") ||
					(type === "danger" && !hideCancelButton)) && (
					<TextContainer onClick={() => clickFunction?.()}>
						<Text>{buttonSecondaryText}</Text>
					</TextContainer>
				)}
			</Box>
		</Container>
	);
};

export default Alert;
