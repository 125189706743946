import styled from "styled-components";

import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	max-height: ${ww(205)}px;
	margin-top: ${ww(10)}px;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: ${ww(163)}px;
	height: ${ww(25)}px;
	border: 1px solid #c6c2de;
	border-radius: ${ww(3)}px;
	margin-bottom: ${ww(11)}px;
	padding: ${ww(9)}px;

	&:hover {
		background: #f6f6f6;
		cursor: pointer;

		& :nth-child(2) {
			box-shadow: ${w(0, 0, 3, 0)} #00000055;
			& :nth-child(1) {
				box-shadow: ${w(0, 0, 3, 0)} #00000055;
			}
		}
	}
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	color: #7c7c7c;
	font-weight: 500;
`;

export const Status = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 400;
	margin-left: auto;
`;

export interface IconStatusContainer {
	status: boolean;
}

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	width: ${ww(22)}px;
	height: ${ww(8)}px;
	background-color: #dadee3;
	border-radius: 50px;
	position: relative;
`;

export const Icon = styled.div<IconStatusContainer>`
	width: ${ww(11)}px;
	height: ${ww(11)}px;
	background: #596cd4;
	border-radius: 50px;
	position: absolute;
	left: ${({ status }) => (status ? ww(11) : 0)}px;
	transition: 0.2s ease-in-out;
`;
