import { createGlobalStyle } from "styled-components";
import { ww } from "./styles/responsive";

const GlobalStyles = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    &.scroll {
      ::-webkit-scrollbar {
        width: 6px;
      }
      
      ::-webkit-scrollbar-track {
        display: none;
      }
      
      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #7c7c7c44; 
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      }

      ::-webkit-scrollbar-thumb:window-inactive {
        background: transparent; 
      }

      scrollbar-width: thin;
      /* SCROLL */

      ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 15px;
        height: 15px;
        border:1px solid #7c7c7c44;
      }
    }
  }

  html, body {
    font-family: 'Inter', sans-serif;
    font-size: ${ww(9)}px;
    font-weight: 400;
    font-size: ${ww(4)}px;
    
    p, h1 {
      text-shadow: 0px 1px 3px rgba(94.1, 95.3, 98, 0.17);
    }

    & .category {
      &:nth-child(1) {
        max-width: 100px;
      }
      &:nth-child(2) {
        flex-grow: 10;
        justify-content: flex-start;
        min-width: 75%;
        padding-left: ${ww(2)}px;
      }
    }

    & .country {
      &:nth-child(1) {
        justify-content: flex-start;
        padding-left: ${ww(10)}px;
        max-width: ${ww(41)}px;
      }
      &:nth-child(2) {
        justify-content: flex-start;
      }
    }
  }

  button {
    all: unset;
    cursor: pointer;
    height: ${ww(23)}px;
    border-radius: ${ww(3)}px;
  }

  input, textarea {
    all: unset;
    border-radius: ${ww(3)}px;
  }

  a {
    all: unset;
  }
`;

export default GlobalStyles;
