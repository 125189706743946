export const mockupTitle = [
	{ title: "Nome" },
	{ title: "aulas", icon: true },
	{ title: "modalidades" },
	{ title: "cadastro" },
	{ title: "ranking", icon: true },
	{ title: "status" },
];

export const mockupData = [
	{
		id: 1,
		name: "Luhio Geowu",
		classes: "44",
		modalities: "Cross Fit, Musculação, Corrida +3...",
		register: "13/11/2021",
		ranking: "3.9",
		status: "Ativo",
	},
	{
		id: 2,
		name: "Dianne Russell",
		classes: "30",
		modalities: "Cross Fit, Musculação, Corrida +1...",
		register: "13/11/2021",
		ranking: "1.3",
		status: "Inativo",
	},
	{
		id: 3,
		name: "Blofi Tuyur",
		classes: "20",
		modalities: "Musculação, Corrida",
		register: "13/11/2021",
		ranking: "4.2",
		status: "Ativo",
	},
	{
		id: 4,
		name: "Lewoa Xanauso",
		classes: "50",
		modalities: "Cross Fit",
		register: "13/11/2021",
		ranking: "3.5",
		status: "Ativo",
	},
	{
		id: 5,
		name: "Lewoa Abrao",
		classes: "50",
		modalities: "Corrida",
		register: "13/11/2021",
		ranking: "3.8",
		status: "Ativo",
	},
	{
		id: 6,
		name: "Doton Dyuhyen",
		classes: "30",
		modalities: "Cross Fit, Musculação, Corrida +2...",
		register: "13/11/2021",
		ranking: "1.9",
		status: "Ativo",
	},
	{
		id: 7,
		name: "Goubu Saypu",
		classes: "20",
		modalities: "Musculação, Corrida",
		register: "13/11/2021",
		ranking: "4.0",
		status: "Ativo",
	},
];

export const mockupDataDetails = {
	id: 1,
	name: "Mariana Fugaz",
	raking: "4.2",
	status: "Ativo",

	phone: "10/12/2021",
	email: "11",
	document: {
		cref: "13/11/2021",
	},
	cpf: "",
	numberOfClasses: "11",
	memberSince: "",
	about:
		"Aliquip laborum et aute aliqua non duis eu aute duis laborum. Qui consectetur et veniam sunt magna. Duis dolore labore nisi est laborum ut aliqua id culpa ex laborum laborum. Ullamco consequat esse anim sit dolor do proident minim. Veniam magna proident elit velit in culpa. Consectetur adipisicing culpa labore duis.",
	cities: ["Sorocaba", "Votorantim", "Araçoiaba", "São Roque"],
	modalities: ["Body Building", "Corrida", "Natação"],
	courses: "Exercitation nisi ad esse occaecat commodo exercitation.",
	certifications: ["", ""],
};

export const citiesOfOperation = [
	{
		id: 1,
		name: "São Paulo",
		UF: "SP",
	},
	{
		id: 2,
		name: "Rio de Janeiro",
		UF: "RJ",
	},
	{
		id: 3,
		name: "Belo Horizonte",
		UF: "MG",
	},
];
