import React from "react";
import { GeneralContext } from "../../../contexts/generalContext";
import { apiAntiga } from "../../../services/api/axios";
import { convertDate_yy_mm_dd } from "../../../utils/convertDate";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import Loading from "../../loading/loading.components";
import ItemDetails from "../itemDetails/item-details.component";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	Title,
	Divisor,
	AnnotationContainer,
} from "./anotations.styles";

const StudentAnnotations: React.FC = () => {
	const { pageDetails, setPageDetails } = React.useContext(GeneralContext);
	const [annotations, setAnnotations] = React.useState<any>();
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const { id, student } = pageDetails;

	React.useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await apiAntiga.get(`adm-get-teacher-note/${id}`);

				data.items?.map((element: any, index: number) => {
					const { newDate } = convertDate_yy_mm_dd(element?.createdAt);
					element.createdAt = newDate;
				});

				setIsLoading(false);
				setAnnotations(data.items);
			} catch (err) {
				setShowAlert(true);
				setIsLoading(false);
			}
		};

		getData();
	}, []);

	if (isLoading) {
		return (
			<Container>
				<Box>
					<Loading color={"#383838"} text="Buscando dados..." />
				</Box>
			</Container>
		);
	}

	if (!isLoading && showAlert) {
		return (
			<Alert
				text={"Esse usuário não tem nenhuma anotação."}
				buttonText="Ok"
				clickFunction={() => setPageDetails?.({})}
			/>
		);
	} else {
		return (
			<Container>
				<Box>
					<IconContainer onClick={() => setPageDetails?.(false)}>
						<Icon />
					</IconContainer>

					<Title>{student}</Title>
					<Text>Anotações feitas pelos Personais</Text>
					<Divisor />
					<AnnotationContainer className="scroll">
						{annotations?.map((answer: any, index: number) => (
							<>
								<ItemDetails
									key={index}
									image={answer.teacher.photo}
									name={answer.teacher.name}
									date={answer.createdAt}
									text={answer.note}
								/>
								<Divisor />
							</>
						))}
					</AnnotationContainer>
					<ButtonContainer>
						<Button
							title="Fechar"
							clickFunction={() => setPageDetails?.(false)}
						/>
					</ButtonContainer>
				</Box>
			</Container>
		);
	}
};

export default StudentAnnotations;
