import React from "react";
import { useLocation } from "react-router-dom";
import CountriesDetails from "../../../components/countries/countries.component";
import { CustomizedState } from "../../../types/details";

import { Container, Title } from "./countryDetails.styles";

export const CountryDetailsPage: React.FC = () => {
	const location = useLocation();

	const state = location?.state as CustomizedState<any>;

	return (
		<Container>
			<Title>{`Países Habilitados ${
				state?.id ? "- " + state.name : "Habilitar Novo País"
			}`}</Title>
			<CountriesDetails id={state?.id} status={state?.item?.status} />
		</Container>
	);
};

export default CountryDetailsPage;
