import React from "react";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { adminApi } from "../../services/api/axios";

import FileUploader from "./FileUploader";
import Button from "../button/button.components";
import { FieldInputCatalog, FieldTextareaCatalog } from "./FieldInputCatalog";
import {
	Container,
	ButtonContainer,
	FormContainer,
	ToggleContainer,
	DropZoneContainer,
} from "./formCategory.styles";
import { useNavigate } from "react-router-dom";
import { onlySpaces } from "../../utils/string";
import { customStylesSelect } from "./customStylesSelect";
import { Pagination } from "../../types/requestPagination";

interface GetCategoriesResponse {
	items: Category[];
	meta: Pagination;
}

export interface Category {
	iconId: string;
	id: string;
	name: string;
	status: boolean;
}
export interface CategoryOption {
	readonly value: string;
	readonly label: string;
	readonly color: string;
	readonly isFixed?: boolean;
	readonly isDisabled?: boolean;
}

export type FieldsCatalog = {
	type: string;
	name: string;
	description: string;
	url: string;
	categories: { value: string; label: string }[];
	featured: boolean;
	image: File | string | null;
};

export type DataCatalog = FieldsCatalog;

interface FormCatalogComponentProps {
	onSubmit: (data: DataCatalog) => Promise<void>;
	onPressDelete?: () => void;
	onPressCancel?: () => void;
	isLoading?: boolean;
	initialValues?: DataCatalog | null;
	type?: "UPDATE" | "CREATE";
}

export default function FormCatalogComponent({
	onSubmit,
	onPressDelete,
	onPressCancel,
	isLoading = false,
	initialValues = null,
	type = "CREATE",
}: FormCatalogComponentProps) {
	const navigate = useNavigate();
	const { handleSubmit, formState, control } = useForm<FieldsCatalog>({
		defaultValues: initialValues ?? undefined,
	});

	function loadCategories(
		inputValue: string,
		callback: (options: CategoryOption[]) => void,
	) {
		if (inputValue === "" || !!onlySpaces(inputValue)) {
			adminApi
				.get<GetCategoriesResponse>("/categories?page=1&limit=9999")
				.then(({ data }) => {
					const formattedOptions = data.items.map((option) => ({
						value: option.id,
						label: option.name,
						color: "#596CD41A",
					}));

					callback(formattedOptions);
				});
		} else {
			adminApi
				.get<Category[]>(`/categories/search/${inputValue}?page=1&limit=9999`)
				.then(({ data }) => {
					const formattedOptions = data.map((option) => ({
						value: option.id,
						label: option.name,
						color: "#596CD41A",
					}));

					callback(formattedOptions);
				});
		}
	}

	return (
		<Container onSubmit={handleSubmit(onSubmit)}>
			<FormContainer>
				<DropZoneContainer>
					<Controller
						control={control}
						name="image"
						render={({ field }) => (
							<FileUploader
								setSelected={field.onChange}
								value={field.value}
								name={field.name}
							/>
						)}
						rules={{ required: true }}
					/>
				</DropZoneContainer>

				<Controller
					name="type"
					control={control}
					defaultValue=""
					rules={{
						required: true,
						validate: (value) => !onlySpaces(value),
					}}
					render={({ field }) => (
						<FieldInputCatalog
							name={field.name}
							label="Tipo do Produto"
							error={formState.errors.type}
							gridArea="type"
							value={field.value}
							onChange={field.onChange}
							ref={field.ref}
						/>
					)}
				/>

				<Controller
					name="name"
					control={control}
					defaultValue=""
					rules={{
						required: true,
						validate: (value) => !onlySpaces(value),
					}}
					render={({ field }) => (
						<FieldInputCatalog
							label="Nome do Produto"
							multiline
							error={formState.errors.name}
							gridArea="name"
							{...field}
						/>
					)}
				/>

				<Controller
					name="description"
					control={control}
					defaultValue=""
					rules={{
						required: true,
						validate: (value) => !onlySpaces(value),
					}}
					render={({ field }) => (
						<FieldTextareaCatalog
							label="Descrição do Produto"
							placeholder="Digite as informações sobre o produto"
							gridArea="description"
							error={formState.errors.description}
							{...field}
						/>
					)}
				/>

				<Controller
					name="url"
					control={control}
					defaultValue=""
					rules={{
						required: true,
					}}
					render={({ field }) => (
						<FieldInputCatalog
							type="url"
							label="Link do Afiliado"
							gridArea="link"
							error={formState.errors.url}
							{...field}
						/>
					)}
				/>

				<ToggleContainer>
					<span>Em Destaque</span>

					<Controller
						control={control}
						name="featured"
						defaultValue={false}
						rules={{ required: false }}
						render={({ field }) => (
							<label htmlFor={field.name}>
								<span>{field.value ? "Ligado" : "Desligado"}</span>
								<Toggle
									id={field.name}
									defaultChecked={field.value}
									icons={false}
									onChange={field.onChange}
								/>
							</label>
						)}
					/>
				</ToggleContainer>

				<div style={{ gridArea: "categories" }}>
					<Controller
						control={control}
						name="categories"
						rules={{ required: true }}
						render={({ field }) => (
							<AsyncSelect
								isMulti
								cacheOptions
								defaultOptions
								placeholder="Categorias"
								styles={customStylesSelect}
								loadOptions={loadCategories}
								name={field.name}
								value={field.value}
								onChange={(value: unknown) => field.onChange(value)}
								ref={field.ref}
							/>
						)}
					/>
				</div>
			</FormContainer>

			<ButtonContainer>
				<div>
					{type === "UPDATE" && (
						<Button
							clickFunction={() => onPressDelete?.()}
							title="Excluir Produto"
							customColor="#FF6868"
						/>
					)}
				</div>
				<div>
					<Button
						clickFunction={() => {
							if (!onPressCancel) {
								navigate("/catalog");
								return;
							}

							onPressCancel?.();
						}}
						title="Cancelar"
						inverterColor
					/>
					<Button
						title={type === "UPDATE" ? "Salvar" : "Adicionar Produto"}
						type="submit"
						isLoading={isLoading}
					/>
				</div>
			</ButtonContainer>
		</Container>
	);
}
