import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../../button/button.components";
import Input from "../../input/input.component";
import { validate } from "../../input/validateInput";
import { FormValues } from "../../../types/form";
import Alert from "../../alert/alert.component";

import LogoImage from "../../../assets/images/logo.png";

import {
	AuthBox,
	AuthContainer,
	InputLink,
	LockIcon,
	Logo,
	LogoContainer,
	MainLabel,
	SubLabel,
} from "../auth.styles";

import { Container, Form, InputContainer } from "./forgotPassword.styles";
import { adminApi } from "../../../services/api/axios";
import { AuthContext } from "../../../contexts/authContext";
import { ww } from "../../../styles/responsive";

const ForgotPassword: React.FC = () => {
	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<FormValues>();
	const { setPasswordToken } = React.useContext(AuthContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = React.useState(false);
	const navigate = useNavigate();

	React.useEffect(() => {
		const token = searchParams.get("token");
		token?.length && setPasswordToken?.(token);
	}, []);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const email = getValues("email");

		const hasError = validate({ email }, setError, errors);

		if (!hasError) {
			setShowAlert(!showAlert);
			setIsLoading(true);
			try {
				await adminApi.post("/forget-password", {
					email,
				});
				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
			}
		}
	};

	return (
		<AuthContainer>
			<AuthBox>
				<LogoContainer>
					<Logo src={LogoImage} />
				</LogoContainer>
				<MainLabel>Recuperar Senha</MainLabel>
				<SubLabel>Digite seu email:</SubLabel>
				<Container style={{ display: "flex" }}>
					<Form onSubmit={onSubmit}>
						<InputContainer>
							<Input
								register={register}
								required={true}
								onFocus={() => setError("email", { type: "", message: "" })}
								name="email"
								type="text"
								placeholder="Email"
								error={errors.email?.message}
								width={ww(165)}
							>
								<LockIcon />
							</Input>
						</InputContainer>
						<Button
							type="submit"
							title="Enviar link para meu email"
							isLoading={isLoading}
						/>
					</Form>
					<InputLink to="/authentication" replace={true}>
						Cancelar
					</InputLink>
				</Container>
			</AuthBox>
			{!isLoading && showAlert && (
				<Alert
					text="Se esse email estiver cadastrado no sistema, enviaremos um link para a redefinição de senha."
					buttonText="Entendi"
					clickFunction={() => navigate("/authentication")}
				/>
			)}
		</AuthContainer>
	);
};

export default ForgotPassword;
