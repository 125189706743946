import React from "react";
import { useNavigate } from "react-router-dom";
import { formatTypeText } from "../../../utils/formatText";
import Button from "../../button/button.components";
import ProfessorItem from "./professor-acceptance-item.component";
import { Container, SubContainer, Title } from "./styles";

type Teacher = {
	id: string;
	approvalId: string;
	type: string;
	name: string;
	location: string;
	image: string;
};
interface ProfessorAceptanceProps {
	teachers: Teacher[] | undefined;
}

const ProfessorAceptance: React.FC<ProfessorAceptanceProps> = (props) => {
	const navigate = useNavigate();

	function handleNavigateToAcceptance() {
		navigate("/acceptance");
	}

	function handleNavigateToAcceptanceDetails(
		approvalId: string,
		approvalType: string,
	) {
		return () => {
			navigate("/acceptance/details", {
				state: {
					id: approvalId,
					item: {
						status: "Pendente",
						type: formatTypeText(approvalType),
					},
				},
			});
		};
	}

	return (
		<Container>
			<Title>Personais em Aprovação</Title>
			<SubContainer>
				{props.teachers?.map((professor) => (
					<ProfessorItem
						key={professor.id}
						onPress={handleNavigateToAcceptanceDetails(
							professor.approvalId,
							professor.type,
						)}
						{...professor}
					/>
				))}
			</SubContainer>
			<Button title="Ver todos" clickFunction={handleNavigateToAcceptance} />
		</Container>
	);
};

export default ProfessorAceptance;
