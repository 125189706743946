/* eslint-disable */
import styled, { css } from "styled-components";
import { ww } from "../../styles/responsive";
import { ContainerProps } from "../../types/button";

const buttonStyle = css`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: ${ww(23)}px;
`;

export const PrimaryContainer = styled.button<ContainerProps>`
	${buttonStyle}
	background-color: #3b4ba2;

	&:hover {
		cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
		border: ${({ isLoading }) => (isLoading ? "none" : "1px solid #3b4ba2")};
		background-color: ${({ isLoading }) => (isLoading ? "#3b4ba2" : "#fff")};
		color: ${({ isLoading }) => (isLoading ? "#fff" : "#3b4ba2")};
		box-shadow: ${({ isLoading }) =>
			isLoading ? "none" : "0 0.2rem 0.3rem #3b4ba244"};

		p {
			color: ${({ isLoading }) => (isLoading ? "#fff" : "#3b4ba2")};
		}

		path {
			fill: #fff;
		}
	}
`;

export const SecondaryContainer = styled.button<ContainerProps>`
	${buttonStyle}
	background-color: #fff;
	border: 1px solid #3b4ba2;
	color: #3b4ba2;

	&:hover {
		cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
		border: ${({ isLoading }) => (!isLoading ? "none" : "1px solid #3b4ba2")};
		background-color: ${({ isLoading }) => (!isLoading ? "#3b4ba2" : "#fff")};
		color: ${({ isLoading }) => (!isLoading ? "#fff" : "#3b4ba2")};
		box-shadow: ${({ isLoading }) =>
			!isLoading ? "none" : "0 0.2rem 0.3rem #3b4ba244"};

		p {
			color: #fff;
		}

		path {
			fill: #fff;
		}
	}
`;

export const CustomContainer = styled.button<ContainerProps>`
	${buttonStyle}
	background-color: ${({ customColor }) => customColor};
	border: ${({ customColor }) => `1px solid ${customColor}`};

	p {
		color: ${({ customColor }) =>
			customColor === "#EFD700" ? "#383838" : "#fff"} !important;
	}

	&:hover {
		cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
		border: ${({ isLoading, customColor }) =>
			isLoading ? "none" : `1px solid ${customColor}`};
		background-color: ${({ isLoading, customColor }) =>
			!isLoading
				? customColor === "#EFD700"
					? customColor
					: "#fff"
				: customColor === "#EFD700"
				? customColor
				: "#FF6868"};

		box-shadow: ${({ isLoading, customColor }) =>
			isLoading ? "none" : `0 0.2rem 0.3rem ${customColor}44`};

		p {
			color: ${({ isLoading, customColor }) =>
				isLoading
					? customColor === "#EFD700"
						? "#383838"
						: "#fff"
					: customColor === "#EFD700"
					? "#383838"
					: customColor} !important;
		}

		path {
			fill: #fff;
		}
	}
`;

export const DisabledContainer = styled.button<ContainerProps>`
	${buttonStyle}
	background-color: #fff;
	border: 1px solid #000;
	color: #000;
	cursor: default;
	filter: opacity(0.5);
	p {
		color: #000;
	}
`;

export const Text = styled.p<ContainerProps>`
	text-transform: capitalize;
	color: ${({ inverterColor }) => (inverterColor ? "#3b4ba2" : "#fff")};
	font-size: ${ww(7)}px;
`;
