import format from "date-fns/format";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import Table from "../../components/table";
import { GeneralContext } from "../../contexts/generalContext";
import { useTitle } from "../../hooks/useTitle";
import { mockupTitle } from "../../mockup/support";
import { TableActionsStatusProps } from "../../models/table";
import { apiAntiga } from "../../services/api/axios";
import { Pagination } from "../../types/requestPagination";
import { formatSupportStatusText } from "../../utils/formatText";
import { Container, Title } from "./support.styles";

const PAGE_TITLE = "Suporte";

interface AdminSupport {
	id: string;
	author: string;
	userType: string;
	problemTypeName: string;
	replyDate: string;
	closingDate: string;
	status: string;
	createdAt: string;
}

interface GetAdminSupportsRequest {
	items: AdminSupport[];
	meta: Pagination;
}

interface CategoryType {
	id: string;
	name: string;
	order: number;
}

const SupportPage: React.FC = () => {
	useTitle(PAGE_TITLE);

	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const fetchList = async () => {
		const response = await apiAntiga.get("/admin/supports/type/list");
		return response.data.map((categoryType: CategoryType) => {
			return {
				title: categoryType.name,
				value: categoryType.name,
			};
		});
	};

	const { data: categoryTypes } = useQuery("support/list", fetchList, {
		refetchOnWindowFocus: false,
	});

	const { searchInput = "" } = searchFilters;
	//

	const queryClient = useQueryClient();

	const renderReplyDate = (item: AdminSupport) => {
		if (item.status === "CLOSED") {
			return format(new Date(item.closingDate), "dd/MM/yyyy HH:mm");
		}
		if (!item.replyDate && item.status !== "CLOSED") {
			return "-";
		}
		return format(new Date(item.replyDate), "dd/MM/yyyy HH:mm");
	};

	const { data } = useQuery(
		["admin-supports", searchFilters, currentPage, itemsPage],
		async () => {
			const response = await apiAntiga.get<GetAdminSupportsRequest>(
				"/admin/supports",
				{
					params: {
						page: currentPage,
						limit: itemsPage,
						search: searchInput || undefined,
						// sortBy: "status",
						typeUser: searchFilters.typeUser || undefined,
						status: searchFilters.status || undefined,
						category: searchFilters.supportCategory || undefined,

						// sortBy: status
						// typeUser=TEACHER
						// &status=OPENED
						// category=Problema 1
					},

					// limit=2&page=3&search=Alexandre&sortBy=status&typeUser=TEACHER&status=OPENED&category=Problema 1
				},
			);

			const formattedData = response.data.items.map((item: AdminSupport) => {
				return {
					id: item.id,
					author: item.author,
					userType: item.userType === "STUDENT" ? "Estudante" : "Personal",
					problemTypeName: item.problemTypeName,
					createdAt: format(new Date(item.createdAt), "dd/MM/yyyy"),
					replyDate: renderReplyDate(item),
					status: formatSupportStatusText(item.status),
				};
			});

			setTotalItems?.(response.data.meta.totalItems);
			return formattedData;
		},
	);

	const setData = (newData: any) => {
		queryClient.setQueryData("admin-supports", () => {
			return {
				data: {
					...newData,
				},
			};
		});
	};

	const getCategories = () => categoryTypes ?? [];

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={data!}
				setData={setData}
				showDate={false}
				statusData={[
					{ title: "Selecionar", value: "" },
					{ title: "Aberto", value: "OPENED" },
					{ title: "Respondido", value: "ANSWERED" },
					{ title: "Concluido", value: "CLOSED" },
				]}
				hasSupportCategories={true}
				supportCategoriesData={[
					{ title: "Selecionar", value: "" },
					...getCategories(),
				]}
				hasUserType={true}
			/>
		</Container>
	);
};

export default SupportPage;
