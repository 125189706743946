import React from "react";
import { Container, SubContainer, Title } from "./details.styles";
import { useLocation } from "react-router-dom";
import DetailsComponent from "../../components/details/profile/profile.component";
import AboutComponent from "../../components/details/about/about.component";
import { TableTitleModel } from "../../models/table";
import { GeneralContext } from "../../contexts/generalContext";
import { mockupTitle } from "../../mockup/history";
import Table from "../../components/table";
import { CustomizedState } from "../../types/details";
import { menuData } from "../../constants/menu";
import { apiAntiga } from "../../services/api/axios";
import { convertDate_yy_mm_dd } from "../../utils/convertDate";

const DetailsPage: React.FC = () => {
	const location = useLocation();
	const { routeFrom, id, name } = location.state as CustomizedState;
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		totalItems,
		setTotalItems,
	} = React.useContext(GeneralContext);
	const [paternRoute, setPaternRoute] = React.useState("");
	const [titleData, setTitleData] = React.useState<TableTitleModel[]>();
	const [data, setData] = React.useState<any>();

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});

		menuData.map((item) => {
			item.data.map((subItem) => {
				if (subItem.link === routeFrom) {
					setPaternRoute(subItem.title);
				}
			});
		});
	}, []);

	React.useEffect(() => {
		const { searchInput = "" } = searchFilters;

		const getData = async () => {
			const newData = [{}];

			try {
				const route =
					routeFrom.search("personal") >= 0
						? `/adm-teacher-schedules/${id}?page=${currentPage}&limit=${itemsPage}&search=${searchInput}`
						: `/adm-student-schedules/${id}?page=${currentPage}&limit=${itemsPage}&search=${searchInput}`;

				const { data } = await apiAntiga.get(route);
				setTotalItems?.(data.meta.totalItems);

				newData.shift();
				data?.items.map((item: any) => {
					const { newDate } = convertDate_yy_mm_dd(item.startDate);
					item.startDate = newDate;

					newData.push({
						id: item?.student ? item.student.id : item.teacher.id,
						name: item?.student ? item.student.name : item.teacher.name,
						modality: item?.modality.name,
						startDate: item?.startDate,
						rating: item.rating === null ? "-" : item.rating,
					});
				});

				setData(newData);
			} catch (error) {
				//
			}
		};
		getData();

		mockupTitle[0].title =
			routeFrom.search("personal") >= 0 ? "ALUNO" : "PERSONAL";

		setTitleData(mockupTitle);
	}, [searchFilters, currentPage, itemsPage, totalItems]);

	return (
		<Container>
			<Title>{`${paternRoute} - ${name}`}</Title>
			<SubContainer>
				<DetailsComponent id={id} routeFrom={routeFrom} />
				{routeFrom.search("personal") >= 0 && (
					<AboutComponent id={id} routeFrom={routeFrom} />
				)}
			</SubContainer>
			<Title className="subTitle">Histórico</Title>
			<Table
				titleData={titleData!}
				data={data!}
				setData={setData}
				showDate={false}
				hasActions={false}
			/>
		</Container>
	);
};

export default DetailsPage;
