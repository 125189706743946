import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { GeneralContext } from "../../../contexts/generalContext";
import { apiAntiga } from "../../../services/api/axios";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import ItemDetails from "../itemDetails/item-details.component";
import MessageDetails from "../message/message.component";
import format from "date-fns/format";

import {
	Container,
	SectionMessages,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	StatusContainer,
	Title,
	Divisor,
	UserNameButton,
} from "./details.styles";
import Loading from "../../loading/loading.components";

interface GetSupportDetailsRequest {
	scheduleCount: number;
	support: {
		id: number;
		replyDate: string;
		problemType: {
			id: string;
			name: string;
			order: number;
		};
		accountActivationAt: null;
		teacher: {
			id: string;
			name: string;
			accountActivationAt: string;
			document: [
				{
					file: string;
				},
			];
		};
		student: {
			id: string;
			name: string;
			photo: string;
		};
		comment: string;
		responses: [
			{
				message: string;
				createdAt: string;
				admin: {
					name: string;
				};
			},
		];
	};
}

const renderUserImage = ({ teacher, student }: any) => {
	if (!student) {
		if (teacher?.document.length === 0)
			return require("../../../assets/images/avatar.png");
		return teacher.document[0].file;
	}

	if (!teacher) {
		const studentImage = student.photo;
		if (!studentImage) return require("../../../assets/images/avatar.png");
		return studentImage;
	}

	return require("../../../assets/images/avatar.png");
};

export const renderLastAnswerDate = (replyDate: string, createdAt: string) => {
	if (!replyDate) return createdAt;

	return format(new Date(replyDate), "dd/MM/yyyy");
};

const SupportDetails: React.FC = () => {
	const navigate = useNavigate();
	const { pageDetails, setPageDetails, editData, setEditData } =
		React.useContext(GeneralContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [showMessage, setShowMessage] = React.useState(false);
	const [messageSent, setMessageSent] = React.useState(false);
	const [data, setData] = React.useState<any>();
	const [isFetching, setIsFetching] = React.useState(true);

	const queryClient = useQueryClient();

	const isFinished = editData.status === "Concluído";

	React.useEffect(() => {
		const getData = async () => {
			const response = await apiAntiga.get<GetSupportDetailsRequest>(
				`/admin/supports/${pageDetails.id}`,
			);

			const formattedData = {
				name: editData.author,
				teacherId: response.data.support.teacher?.id || null,
				studentId: response.data.support.student?.id || null,
				memberSince: editData.createdAt,
				classAmount: response.data.scheduleCount,
				lastAnswer: renderLastAnswerDate(
					response.data.support.replyDate,
					editData.createdAt,
				),
				status: editData.status,
				question: {
					image: renderUserImage(response.data.support),
					date: editData.createdAt,
					text: response.data.support.comment,
				},
				answers: response.data.support.responses.map((response) => {
					return {
						image: require("../../../assets/images/logo_small.png"),
						name: response.admin.name,
						date: format(new Date(response.createdAt), "dd/MM/yyyy"),
						text: response.message,
					};
				}),
			};

			setData(formattedData);
			setIsFetching(false);
		};

		getData();
	}, [messageSent, editData]);

	// const { data, isFetching, isError } = useQuery(
	// 	["support-details"],
	// 	async () => {
	// 		const response = await apiAntiga.get<GetSupportDetailsRequest>(
	// 			`/admin/supports/${pageDetails.id}`,
	// 		);

	// 		const formattedData = {
	// 			name: editData.author,
	// 			teacherId: response.data.support.teacher.id,
	// 			studentId: response.data.support.student.id,
	// 			memberSince: editData.createdAt,
	// 			classAmount: response.data.scheduleCount,
	// 			lastAnswer: renderReplyDate(response.data.support.replyDate),
	// 			status: editData.status,
	// 			question: {
	// 				image: renderUserImage(response.data.support),
	// 				date: editData.createdAt,
	// 				text: response.data.support.comment,
	// 			},
	// 			answers: response.data.support.responses.map((response) => {
	// 				return {
	// 					image: require("../../../assets/images/logo_small.png"),
	// 					name: response.admin.name,
	// 					date: format(new Date(response.createdAt), "dd/MM/yyyy"),
	// 					text: response.message,
	// 				};
	// 			}),
	// 		};

	// 		return formattedData;
	// 	},
	// 	{
	// 		onError: (error) => console.error("Something went wrong"),
	// 	},
	// );

	async function handleFinishServiceCall() {
		try {
			await apiAntiga.post(`admin/supports/${editData.id}/concluded`);
			setShowAlert(true);
			queryClient.invalidateQueries("support-details");
		} catch (error) {
			//
		}
	}

	function closeModal() {
		setEditData?.({});
		setShowAlert(!showAlert);
		setPageDetails?.(false);
	}

	function goToStudentOrPersonalScreen() {
		if (editData.userType === "Personal") {
			navigate("/personal/details", {
				state: {
					routeFrom: "personal",
					id: data?.teacherId,
					name: editData.author,
				},
			});
		} else {
			navigate("/student/details", {
				state: {
					routeFrom: "student",
					id: data?.studentId,
					name: editData.author,
				},
			});
		}

		closeModal();
	}

	return (
		<Container>
			<Box>
				{isFetching ? (
					<Loading color="#3b4ba2" text="Buscando Dados..." />
				) : (
					<>
						<IconContainer onClick={closeModal}>
							<Icon />
						</IconContainer>
						<StatusContainer>
							<Text>
								{data?.status} em {data?.lastAnswer}
							</Text>
						</StatusContainer>

						<UserNameButton onClick={goToStudentOrPersonalScreen}>
							<Title>{data?.name}</Title>
						</UserNameButton>

						<Text>{data?.classAmount} aulas pela Greeko</Text>
						<Text>Membro desde: {data?.memberSince}</Text>

						<Divisor />

						<SectionMessages>
							<ItemDetails
								image={data?.question?.image}
								name={data?.name}
								date={data?.question?.date}
								text={data?.question?.text}
							/>

							<Divisor />

							{data?.answers.map((answer: any, index: number) => (
								<>
									<ItemDetails
										key={index}
										image={answer.image}
										name={answer.name}
										date={answer.date}
										text={answer.text}
									/>
									<Divisor />
								</>
							))}
						</SectionMessages>

						<ButtonContainer>
							<Button
								isDisabled={isFinished ? true : false}
								title="Nova Mensagem"
								clickFunction={() => setShowMessage(!showMessage)}
							/>
						</ButtonContainer>
						<ButtonContainer>
							<Button
								isDisabled={isFinished ? true : false}
								title="Concluir atendimento"
								customColor="#30BA88"
								clickFunction={handleFinishServiceCall}
							/>
						</ButtonContainer>
					</>
				)}
			</Box>
			{showAlert && (
				<Alert
					text="Suporte concluído com sucesso."
					buttonText="Ok"
					clickFunction={closeModal}
				/>
			)}
			{showMessage && (
				<MessageDetails
					setShowMessage={() => setShowMessage(false)}
					setMessageSent={() => setMessageSent(!messageSent)}
				/>
			)}
		</Container>
	);
};

export default SupportDetails;
