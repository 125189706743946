import React from "react";
import { useNavigate } from "react-router-dom";
import {
	CalendarCheckIcon,
	Container,
	Card,
	Title,
	Text,
	GroupIcon,
	CalendarIcon,
	ModalityIcon,
} from "./top-cards.styles";

interface TopCardsProps {
	classesHeld: number;
	scheduledClasses: number;
	totalStudents: number;
	totalTeachers: number;
}

const TopCards: React.FC<TopCardsProps> = (props) => {
	const navigate = useNavigate();

	return (
		<Container>
			<Card onClick={() => navigate("/student")}>
				<GroupIcon />
				<Title>{props.totalStudents}</Title>
				<Text>Alunos</Text>
			</Card>

			<Card onClick={() => navigate("/personal")}>
				<ModalityIcon />
				<Title>{props.totalTeachers}</Title>
				<Text>Personais</Text>
			</Card>

			<Card onClick={() => navigate("/finance")}>
				<CalendarIcon />
				<Title>{props.scheduledClasses}</Title>
				<Text>Aulas Agendadas</Text>
			</Card>

			<Card onClick={() => navigate("/finance")}>
				<CalendarCheckIcon />
				<Title>{props.classesHeld}</Title>
				<Text>Aulas Realizadas</Text>
			</Card>
		</Container>
	);
};

export default TopCards;
