import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/alert/alert.component";
import Button from "../../components/button/button.components";
import { InputName } from "../../components/InputName";
import SelectComponent, {
	SelectItem,
} from "../../components/select/select.component";
import { Input } from "../../components/system/Input.styles";
import { useTitle } from "../../hooks/useTitle";
import { getCategories } from "../../services/categories.service";
import {
	createModality,
	getModalityById,
	ICreateModality,
	IUpdateModality,
	updateModality,
} from "../../services/modalities.service";
import { ww } from "../../styles/responsive";
import { ICategory } from "../../types/category";
import { CustomizedState } from "../../types/details";
import { ModalityInput } from "../../types/input";
import { Language } from "../../types/Language";
import {
	extractNumber,
	MAX_MONEY,
	numberToMoneyFormat,
} from "../../utils/money";

import * as S from "./modality-details.style";
import { BackdropLoading } from "../../components/BackdropLoading";
import toast, { Toaster } from "react-hot-toast";
import useModal from "../../utils/use-modal";

type ModalityByLocation = {
	id: string;
	category: string;
	name: string;
	minimalPrice: string;
	status: string;
	hidden: {
		category_id: string;
	};
};

export default function ModalityDetailsPage() {
	const location = useLocation();
	const modalConfirmVisibility = useModal();

	const item = (location.state as CustomizedState<ModalityByLocation>)?.item;

	const PAGE_TITLE = getPageTitle();

	function getPageTitle() {
		const currentPage = item?.id ? "Editar Modalidade" : "Adicionar Modalidade";
		return `Modalidades > ${currentPage}`;
	}

	useTitle(PAGE_TITLE);

	const { handleSubmit, control, setValue, register } =
		useForm<ModalityInput>();
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const [selectedData, setSelectedData] = React.useState<string>("");
	const [selectInitialValue, setSelectInitialvalue] = React.useState(0); // It's a index position array

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const { data } = useQuery("categories/modalities", () =>
		getCategories({
			limit: 20,
		}),
	);

	const {
		data: modality,
		refetch,
		isLoading: isLoadingModality,
	} = useQuery(
		["modalities", item?.id],
		() => getModalityById(item?.id ?? ""),
		{
			enabled: Boolean(item?.id),
		},
	);

	useEffect(() => {
		if (item && data) {
			const indexCategory = data?.items.findIndex(
				(category) => category.id === item.hidden.category_id,
			);

			if (indexCategory >= 0) setSelectInitialvalue(indexCategory);

			setValue("pt-BR", item.name);
			setValue("minPrice", numberToMoneyFormat(Number(item.minimalPrice)));
		}
	}, [item, setValue, selectedData, data]);

	const onSubmitModality = async (formData: ModalityInput) => {
		if (!selectedData) return;
		setIsLoading(true);

		const modalityI18n: Omit<Language, "id">[] = [];

		modalityI18n.push({
			name: formData["pt-BR"],
			languageCode: "pt-BR",
		});

		// modalityI18n.push({
		// 	name: formData["en-US"],
		// 	languageCode: "en-US",
		// });

		// modalityI18n.push({
		// 	name: formData["fr-FR"],
		// 	languageCode: "fr-FR",
		// });

		// modalityI18n.push({
		// 	name: formData["es"],
		// 	languageCode: "es",
		// });

		const category = data?.items.find((item) => item.id === selectedData);

		if (!category) return;

		if (item?.id) {
			const request: Partial<IUpdateModality> = {
				name: formData["pt-BR"],
				minimalPrice: extractNumber(formData.minPrice ?? "") / 100,
				category,
			};
			await updateModality(item.id, request);
			queryClient.invalidateQueries("modalities"); // refetch modilities list
		} else {
			const request: ICreateModality = {
				name: formData["pt-BR"],
				minimalPrice: extractNumber(formData.minPrice ?? "") / 100,
				category,
				modalityI18n,
			};
			await createModality(request);
			queryClient.invalidateQueries("modalities"); // refetch modilities list
		}

		setTimeout(() => {
			setShowAlert(true);
			setIsLoading(false);
			navigate(-1);
		}, 2000);
	};

	function formatCategoriesToSelect(values: ICategory[]): SelectItem[] {
		return values.map((item) => ({
			title: item.name,
			value: item.id,
		}));
	}

	const handleDeactivateModality = async () => {
		modalConfirmVisibility.close();

		setIsLoading(true);
		try {
			await updateModality(item?.id ?? "", {
				active: !modality?.active,
			});

			toast.success("Status da modalidade alterado com sucesso");

			refetch();
			queryClient.invalidateQueries("modalities");
		} catch (error) {
			toast.error("Erro inesperado ao mudar o status dessa modalidade");
		} finally {
			setIsLoading(false);
		}
	};

	const handleModalityPrice = (text: string) => {
		const number = extractNumber(text);
		let money = number;

		if (number > MAX_MONEY) money = MAX_MONEY;

		if (number < 0) money = 0;

		setValue("minPrice", numberToMoneyFormat(money / 100));
	};

	function getVisibilityConfirmText() {
		const actionText = modality?.active ? "desativar" : "ativar";
		return `Você está prestes a ${actionText} essa modalidade`;
	}

	function getActionStatus(active?: boolean) {
		return active ? "Desativar" : "Ativar";
	}

	return (
		<S.Container>
			<div>
				<Toaster
					position="top-right"
					reverseOrder={false}
					toastOptions={{
						duration: 3000,
						style: {
							fontSize: 16,
						},
					}}
				/>
			</div>

			<S.TitlePage>{PAGE_TITLE}</S.TitlePage>

			<S.CardContainer onSubmit={handleSubmit(onSubmitModality)}>
				<S.SectionForm>
					<S.SectionColumnForm>
						<S.TitleSection>Nova Modalidade</S.TitleSection>

						<SelectComponent
							data={formatCategoriesToSelect(data?.items ?? [])}
							width={ww(165)}
							setData={setSelectedData}
							initialValue={selectInitialValue}
						>
							<S.ArrowDownIcon />
						</SelectComponent>

						<S.TitleSection>Valor mínimo da modalidade</S.TitleSection>

						<Input
							{...register("minPrice")}
							placeholder="Valor mínimo"
							onChange={(event) => handleModalityPrice(event.target.value)}
							hasError={false}
							style={{ width: ww(165) }}
						/>
					</S.SectionColumnForm>
					<S.SectionColumnForm>
						<S.TitleSection>Nome da Modalidade Por Idioma</S.TitleSection>

						<InputName
							control={control}
							label="Português (Brasil)"
							name="pt-BR"
							placeholder="Nome da Modalidade"
							isDefault
						/>

						{/* <InputName
							control={control}
							label="Inglês"
							name="en-US"
							placeholder="Nome da Modalidade"
						/>

						<InputName
							control={control}
							label="Francês"
							name="fr-FR"
							placeholder="Nome da Modalidade"
						/>

						<InputName
							control={control}
							label="Espanhol"
							name="es"
							placeholder="Nome da Modalidade"
						/> */}
					</S.SectionColumnForm>
				</S.SectionForm>
				<S.ButtonContainer>
					<div>
						{item?.id ? (
							<S.ButtonContainer onClick={() => modalConfirmVisibility.open()}>
								<Button
									title={
										modality?.active
											? "Desativar Modalidade"
											: "Ativar Modalidade"
									}
									inverterColor
								/>
							</S.ButtonContainer>
						) : (
							<S.CancelContainer>
								<S.Text onClick={() => navigate(-1)}>Cancelar</S.Text>
							</S.CancelContainer>
						)}
					</div>
					<div>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</div>
				</S.ButtonContainer>
			</S.CardContainer>

			{modalConfirmVisibility.isShowing && (
				<Alert
					text={getVisibilityConfirmText()}
					buttonText={getActionStatus(modality?.active)}
					clickFunction={modalConfirmVisibility.close}
					cautionFunction={handleDeactivateModality}
					isLoading={isLoading}
					type="danger"
				/>
			)}

			{showAlert && (
				<Alert
					text="Modalidade adicionada com sucesso."
					buttonText="Ok"
					clickFunction={() => navigate(-1)}
				/>
			)}

			<BackdropLoading isVisible={isLoadingModality || isLoading} />
		</S.Container>
	);
}
