import React from "react";
import { useQuery, useQueryClient } from "react-query";
import Table from "../../../components/table";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { mockupTitle } from "../../../mockup/catalog";
import { apiAntiga } from "../../../services/api/axios";
import { Pagination } from "../../../types/requestPagination";

import { Container, Title } from "./catalog.styles";

type CatalogPropsResponse = {
	image: string;
	id: string;
	name: string;
	typeProduct: string;
	isFeatured: boolean;
	url: string;
};

type GetCatalogResponse = {
	value: {
		items: CatalogPropsResponse[];
		meta: Pagination;
	};
};

const PAGE_TITLE = "Catálogo";

export const CatalogPage: React.FC = () => {
	useTitle(PAGE_TITLE);

	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		totalItems,
		setTotalItems,
	} = React.useContext(GeneralContext);

	const queryClient = useQueryClient();

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const { searchInput = "" } = searchFilters;

	const { data } = useQuery(
		["products", searchFilters, currentPage, itemsPage],
		async () => {
			const response = await apiAntiga.get<GetCatalogResponse>(
				"/admin/products",
				{
					params: {
						page: currentPage,
						limit: itemsPage,
						search: searchInput,
					},
				},
			);
			const formattedData = response.data.value.items.map((item) => {
				return {
					id: item.id,
					icon: item.image,
					name: item.name,
					typeProduct: item?.typeProduct,
					isFeatured: item.isFeatured ? "Sim" : "Não",
					externalLink: item.url,
				};
			});
			setTotalItems?.(response.data.value.meta.totalItems);
			return formattedData;
		},
		{ staleTime: 60 * 5 * 1000 }, // 5 minutes
	);

	const setData = (newData: any) => {
		queryClient.setQueryData("categories", () => {
			return {
				data: {
					...newData,
				},
			};
		});
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle}
				data={data!}
				setData={setData}
				hasActions={false}
				hasPageDetails
				statusData={[
					{ title: "Ativo", value: "Ativo" },
					{ title: "Inativo", value: "Inativo" },
				]}
			/>
		</Container>
	);
};
