import format from "date-fns/format";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/button/button.components";
import { AuthContext } from "../../contexts/authContext";
import { GeneralContext } from "../../contexts/generalContext";
import { useTitle } from "../../hooks/useTitle";
import { SectionTitle, SubContainer } from "../../styles/components";
import { Container, Title, Text } from "./my-account.styles";

const PAGE_TITLE = "Minha Conta";

const MyAccountPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const location = useLocation();

	const { admin } = useContext(AuthContext);

	const { setPageDetails, setEditData } = React.useContext(GeneralContext);

	const currentRoute = location.pathname.split("/")[1];

	function handleEditClick() {
		setPageDetails?.({
			route: currentRoute + "/edit-profile",
			id: admin?.id,
		});

		setEditData?.(admin);
	}
	function handleRedefinePasswordClick() {
		setPageDetails?.({
			route: currentRoute + "/redefine-password",
			id: admin?.id,
		});

		setEditData?.(admin);
	}

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<SubContainer direction="row" justify="flex-start" padding={11}>
				<SubContainer direction="column">
					<SectionTitle color="primary">{admin.name}</SectionTitle>
					<Text>{admin.email}</Text>
					<Text>
						Último Acesso:{" "}
						{admin.lastAccess
							? format(new Date(admin.lastAccess), "dd/MM/yyyy HH:mm")
							: "-"}
					</Text>
				</SubContainer>
				<SubContainer direction="column" justify="flex-end" widthPerCent={50}>
					<SubContainer direction="row" gap={10}>
						<Button
							title="Redefinir Senha"
							inverterColor={true}
							clickFunction={handleRedefinePasswordClick}
						/>
						<Button
							title="Editar"
							inverterColor={true}
							clickFunction={handleEditClick}
						/>
					</SubContainer>
				</SubContainer>
			</SubContainer>
		</Container>
	);
};

export default MyAccountPage;
