import React from "react";
import { CountryModel } from "../../../models/country";
import { DocumentListProps } from "../../../types/country";
import {
	Container,
	DocumentContainer,
	DocumentIcon,
	ArrowRightIcon,
	Text,
	Status,
} from "./documentList.styles";

const DocumentList: React.FC<DocumentListProps> = ({
	selected,
	data,
	setData,
}) => {
	return (
		<Container>
			{data?.map((item: CountryModel, index: number) => (
				<DocumentContainer
					key={index}
					isSelected={item.id === selected}
					onClick={() => setData(item.id)}
				>
					<DocumentIcon />
					<Text>{item.title}</Text>
					<Status status={item.status}>
						{item.status ? "Ativo" : "Inativo"}
					</Status>
					<ArrowRightIcon />
				</DocumentContainer>
			))}
		</Container>
	);
};

export default DocumentList;
