import React from "react";
import {
	InputTextContainer,
	InputText,
	Label,
	Text,
} from "./input_text.styles";
import { FlexBox, Image } from "../../styles/components";

interface InputTextProps {
	children?: React.ReactNode;
	label?: string;
	value: string;
}

export const InputTextComponent = ({
	children,
	label,
	value,
}: InputTextProps) => {
	return (
		<InputTextContainer>
			<InputText>
				{label && <Label>{label}</Label>}
				<FlexBox direction="row" align="center">
					{children}
					<Text>{value}</Text>
				</FlexBox>
			</InputText>
		</InputTextContainer>
	);
};
