import { Category } from "../components/formCatalog/formCatalog.component";
import { Language } from "../types/Language";
import { adminApi } from "./api/axios";

export interface ICreateModality {
	name: string;
	minimalPrice: number;
	category: Category;
	modalityI18n: Omit<Language, "id">[];
}

export interface Modality {
	active: boolean;
	iconId: string;
	id: string;
	minimalPrice: string;
	name: string;
}

export interface IUpdateModality {
	name: string;
	minimalPrice: number;
	category: Category;
	active: boolean;
}

export interface ModalityListItem {
	modality_id: string;
	name: string;
	minimalPrice: string;
	iconId: string;
	active: number;
	category_id: string;
	category_name: string;
	category_iconId: string;
	category_active: number;
	category_status: number;
}

export async function createModality(params: ICreateModality) {
	try {
		await adminApi.post("/modalities/create", params);
	} catch (error) {
		throw new Error("Problemas ao criar modalidade");
	}
}

export async function updateModality(
	modalityId: string,
	params: Partial<IUpdateModality>,
) {
	try {
		await adminApi.put(`/modalities/update/${modalityId}`, params);
	} catch (error) {
		throw new Error("Problemas ao atualizar modalidade");
	}
}

export async function getModalityById(modalityId: string): Promise<Modality> {
	const response = await adminApi.get(`/modalities/${modalityId}`);
	return response.data;
}
