import React from "react";
import { FlexBox } from "../../styles/components";

import { AlertConfirmProps } from "../../types/alertConfirm";
import Button from "../button/button.components";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	CautionIcon,
	DangerIcon,
	InfoIcon,
	TextContainer,
} from "./alertConfirm.styles";

const AlertConfirm: React.FC<AlertConfirmProps> = ({
	text,
	buttonText1,
	buttonText2,
	buttonLink,
	type = "alert",
	isLoading = false,
	confirmFunction,
	cancelFunction,
}) => {
	const renderButtonProps = () => {
		if (type === "caution") return { customColor: "#EFD700" };
		if (type === "danger") return { customColor: "#FF6868" };
		return {};
	};

	const renderIcon = () => {
		if (type === "caution") return <CautionIcon />;
		if (type === "danger") return <DangerIcon />;
		if (type === "info") return <InfoIcon />;
		return <Icon />;
	};

	const buttonProps = renderButtonProps();

	const texts = text.split(":");

	return (
		<Container>
			<Box type={buttonProps.customColor}>
				<IconContainer>{renderIcon()}</IconContainer>
				<Text>{texts[0]}</Text>
				{type && texts[1] && (
					<Text style={{ fontWeight: 700 }}>{texts[1]}</Text>
				)}
				<FlexBox direction="row" gap={11}>
					<Button
						title={buttonText1}
						clickFunction={confirmFunction}
						{...buttonProps}
						isLoading={isLoading}
						inverterColor
					/>
					<Button
						title={buttonText2}
						clickFunction={cancelFunction}
						{...buttonProps}
						isLoading={isLoading}
						customColor="#3b4ba2"
						inverterColor
					/>
				</FlexBox>
				{((!isLoading && type === "caution") || type === "danger") && (
					<TextContainer onClick={() => confirmFunction?.()}>
						<Text>Cancelar</Text>
					</TextContainer>
				)}
			</Box>
		</Container>
	);
};

export default AlertConfirm;
