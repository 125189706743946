import styled from "styled-components";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";

import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	margin-bottom: ${ww(3)}px;
	color: #7c7c7c;
	font-weight: 500;
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	text-align: left;
	color: #3b4ba2;
	line-height: ${w(25)};
`;

export const SubTitle = styled.p`
	font-weight: 700;
	font-size: ${ww(8)}px;
	color: #3b4ba2;
	line-height: ${w(10)};
`;

export const SubDescription = styled.span`
	font-weight: 400;
	font-size: ${ww(8)}px;
	color: #3b4ba2;
	line-height: ${w(10)};
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	padding: ${w(7, 0, 14, 0)};
	border-radius: ${ww(3)}px;
	width: ${ww(200)}px;
	position: relative;
	padding-top: ${ww(20)}px;
`;

export const BoxPadding = styled.div`
	padding: ${w(0, 17, 0, 17)};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	width: 100%;
`;

export const IconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const Icon = styled(Close).attrs({
	width: w(14),
	height: w(14),
})`
	display: flex;
`;

export const ContainerCart = styled.div`
	height: 18vh;
	width: 100%;
	overflow-y: auto;
`;

export const ItemCart = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	margin-top: 10px;

	border-bottom: 1px solid #f6f6f6;
	padding-bottom: 6px;

	> div p {
		margin-bottom: 0;
	}
`;

export const SummaryBoxLine = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 2px;
`;

export const DashedLine = styled.div`
	width: 100%;
	border-bottom: 1px dashed #7c7c7c;

	margin: 8px 0;
`;

export const SolidLine = styled.div`
	width: 96%;
	border-bottom: 1px solid #4b4b4b;

	margin: 16px auto;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;

	margin-top: ${ww(26)}px;
`;

export const InputContainer = styled.div`
	margin-bottom: ${ww(17)}px;
	width: 100%;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(14),
	height: w(14),
})``;

export const CancelContainer = styled.div`
	align-self: center;
	margin-top: ${ww(8)}px;

	p {
		font-size: ${ww(8)}px;
		text-align: center;
		font-weight: 400;
		color: #3b4ba2;
	}
	&:hover {
		cursor: pointer;
	}
`;

export const PaymentBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 16px;

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 14px;
	}
`;
