import React from "react";
import { TableItemProps } from "../../types/table";
import Loading from "../loading/loading.components";
import SearchFilter from "../search-filter/search-filter.component";
import { Container } from "./styles";
import TableAction from "./table-action/table-action.component";
import TableFooter from "./table-footer/table-footer.component";
import TableItem from "./table-item/table-item.component";

const Table: React.FC<TableItemProps> = ({
	titleData,
	data,
	setData,
	showDate,
	hasPageDetails,
	hasModalities,
	hasActions = true,
	hasCheckBox,
	statusData,
	hasCategories,
	hasSupportCategories,
	supportCategoriesData,
	hasUserType,
	hasCountryOfOperation,
	countryOfOperationData,
	hasAprovationType,
	aprovationTypeData,
	onPressTopRightButton,
	onPressColumnSortData,
}) => {
	return (
		<Container>
			<SearchFilter
				hasActions={hasActions}
				onPressRightButton={onPressTopRightButton}
			/>
			{hasActions && (
				<TableAction
					showDate={showDate}
					hasCheckBox={hasCheckBox}
					hasModalities={hasModalities}
					statusData={statusData}
					hasCategories={hasCategories}
					hasSupportCategories={hasSupportCategories}
					supportCategoriesData={supportCategoriesData}
					hasUserType={hasUserType}
					hasCountryOfOperation={hasCountryOfOperation}
					countryOfOperationData={countryOfOperationData}
					hasAprovationType={hasAprovationType}
					aprovationTypeData={aprovationTypeData}
				/>
			)}
			{data ? (
				<TableItem
					titleData={titleData!}
					data={data!}
					setData={setData}
					hasPageDetails={hasPageDetails}
					hasActions={hasActions}
					onPressColumnSortData={onPressColumnSortData}
				/>
			) : (
				<Loading color="#3b4ba2" text="Buscando Dados..." />
			)}
			<TableFooter />
		</Container>
	);
};

export default Table;
