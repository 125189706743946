import React from "react";
import { IApprovalDocumentation } from "../../types/acceptanceDetails";
import { TableItemProps } from "../../types/table";
import Loading from "../loading/loading.components";
import { Container } from "./styles";
import TableFooter from "./table-footer/table-footer.component";
import TableItem from "./table-item/table-item.component";

const TableDocuments: React.FC<TableItemProps<IApprovalDocumentation>> = ({
	titleData,
	data,
	setData,
	onClickApproveButton,
	onClickDisapproveButton,
	onClickDetailsButton,
}) => {
	return (
		<Container>
			{data ? (
				<TableItem
					titleData={titleData!}
					data={data!}
					setData={setData}
					hasPageDetails={false}
					hasActions={false}
					onClickApproveButton={onClickApproveButton}
					onClickDisapproveButton={onClickDisapproveButton}
					onClickDetailsButton={onClickDetailsButton}
				/>
			) : (
				<Loading color="#3b4ba2" text="Buscando Dados..." />
			)}
			<TableFooter />
		</Container>
	);
};

export default TableDocuments;
