import React from "react";
import { useQuery } from "react-query";
import Table from "../../../components/table";
import { GeneralContext } from "../../../contexts/generalContext";
import { CCA2Country, getFlagCountry } from "../../../data/countries";
import { useTitle } from "../../../hooks/useTitle";
import { mockupTitle } from "../../../mockup/countries";
import { apiAntiga } from "../../../services/api/axios";
import { Pagination } from "../../../types/requestPagination";
import { renderCountryFlags } from "../../../utils/countryFlags";

import { Container, Title } from "./countries.styles";

const PAGE_TITLE = "Países Habilitados";

interface Country {
	cca2: CCA2Country;
	name: string;
	active: boolean;
}

interface GetCountriesRequest {
	items: Country[];
	meta: Pagination;
}

const CountriesPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const { searchInput = "" } = searchFilters;

	const params = {
		page: currentPage,
		limit: itemsPage,
		search: searchInput,
	};

	const { data } = useQuery(
		["countries", searchFilters, currentPage, itemsPage],
		async () => {
			const response = await apiAntiga.get<GetCountriesRequest>(
				"/admin/countries",
				{
					params: {
						page: currentPage,
						limit: itemsPage,
						search: searchInput,
					},
				},
			);

			const formattedData = response.data.items.map((item) => {
				return {
					id: item.cca2,
					icon: getFlagCountry(item.cca2),
					name: item.name,
					status: item.active ? "Ativo" : "Inativo",
				};
			});

			setTotalItems?.(response.data.meta.totalItems);

			return formattedData;
		},
	);

	const setData = (newData: any) => {
		return {
			data: {
				...newData,
			},
		};
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={data!}
				setData={setData}
				hasActions={false}
				hasPageDetails={true}
				statusData={[
					{ title: "Ativo", value: "Ativo" },
					{ title: "Inativo", value: "Inativo" },
				]}
			/>
		</Container>
	);
};

export default CountriesPage;
