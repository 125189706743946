import axios from "axios";

import { baseUrl } from "../../constants/api";

const api = axios.create({
	baseURL: baseUrl.api,
	timeout: 15000,
	headers: { "Content-Type": "application/json" },
});

const adminApi = axios.create({
	baseURL: baseUrl.adminApi,
	timeout: 15000,
	headers: { "Content-Type": "application/json" },
});

export const setAuthorization = (token: string | null): void => {
	api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	adminApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export { api as apiAntiga, adminApi };

export function insertTokenHeadersRequest(token: string) {
	const Authorization = `Bearer ${token}`;

	api.interceptors.request.use((config) => {
		config.headers = {
			...config.headers,
			Authorization,
		};

		return config;
	});

	adminApi.interceptors.request.use((config) => {
		config.headers = {
			...config.headers,
			Authorization,
		};

		return config;
	});
}
