import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import Table from "../../components/table";
import { GeneralContext } from "../../contexts/generalContext";
import { useTitle } from "../../hooks/useTitle";
import { mockupTitle } from "../../mockup/category";
import { adminApi } from "../../services/api/axios";
import { Pagination } from "../../types/requestPagination";

import { Container, Title } from "./category.styles";
import { useDebounce } from "usehooks-ts";

type CategoryI18n = {
	id: string;
	name: string;
	languageCode: string;
};
interface Category {
	iconId: string;
	id: string;
	name: string;
	status: boolean;
	category_i18n: CategoryI18n[];
}
interface GetCategoriesResponse {
	items: Category[];
	meta: Pagination;
}

const PAGE_TITLE = "Categorias";

const CategoryPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const queryClient = useQueryClient();

	const { searchInput = "" } = searchFilters;

	const debouncedSearch = useDebounce(searchInput as string, 400);

	const { data, refetch } = useQuery(
		["categories", currentPage, itemsPage],
		async () => {
			const response = await adminApi.get<GetCategoriesResponse>(
				"/categories",
				{
					params: {
						search: debouncedSearch?.trim(),
						page: currentPage,
						limit: itemsPage,
					},
				},
			);

			const formattedData = response.data.items.map((item) => {
				return {
					id: item.id,
					icon: item.iconId,
					name: item.name,
					status: item.status ? "Ativo" : "Inativo",
					hidden: {
						category_i18n: item.category_i18n,
					},
				};
			});

			setTotalItems?.(response.data.meta.totalItems);
			return formattedData;
		},
	);

	useEffect(() => {
		refetch();
	}, [debouncedSearch, refetch]);

	const setData = (newData: any) => {
		queryClient.setQueryData("categories", () => {
			return {
				data: {
					...newData,
				},
			};
		});
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={data!}
				setData={setData}
				hasActions={false}
				hasPageDetails
				statusData={[
					{ title: "Ativo", value: "Ativo" },
					{ title: "Inativo", value: "Inativo" },
				]}
			/>
		</Container>
	);
};

export default CategoryPage;
