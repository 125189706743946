export const mockupTitle = [
	{ title: "Categoria" },
	{ title: "modalidade", icon: true },
	{ title: "Preço Mínimo" },
	{ title: "status", icon: true },
];

export const mockupData = [
	{
		id: 4,
		category: "Academia",
		modalities: "Cross Fit",
		price: "50",
		status: "Ativo",
	},
	{
		id: 5,
		category: "Academia 2",
		modalities: "Corrida",
		price: "50",
		status: "Inativo",
	},
	{
		id: 7,
		category: "Academia",
		modalities: "Musculação",
		price: "20",
		status: "Ativo",
	},
];
