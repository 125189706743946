import styled from "styled-components";
import { ww, w } from "../../styles/responsive";

// create a text input with div element with some styles applied to it
export const InputTextContainer = styled.div`
	width: 100%;
	margin: ${w(7, 10)};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const InputText = styled.div`
	padding: ${w(1, 5)};
	font-size: 16px;
	color: #ffffff;
	border: 2px solid #d5d5d5;
	border-radius: ${ww(5)}px;
`;

export const Label = styled.label`
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	color: #7c7c7c;
	height: 13.31px;
`;

export const Text = styled.span`
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	height: 16.94px;
	color: #4b4b4b;
`;
