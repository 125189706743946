import styled from "styled-components";

import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Caution } from "../../assets/icons/caution.svg";
import { ReactComponent as Danger } from "../../assets/icons/info.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";

import { AlertStyleProps } from "../../types/alert";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999;
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	color: #4b4b4b;
	margin-bottom: ${ww(18)}px;
	text-align: center;
`;

export const Box = styled.div<AlertStyleProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ type }) => type ?? "#64bc47"};
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(181)}px;
	padding: ${w(7, 20, 14, 20)};
`;

export const IconContainer = styled.div`
	margin-bottom: ${ww(10)}px;
`;

export const Icon = styled(Check).attrs(({ width, height }) => ({
	width: width ?? ww(16),
	height: height ?? ww(26),
}))``;

export const CautionIcon = styled(Caution).attrs({
	width: w(18),
	height: w(18),
})``;

export const DangerIcon = styled(Danger).attrs({
	width: w(18),
	height: w(18),
})`
	color: #ff6868; ;
`;
export const InfoIcon = styled(Info).attrs({
	width: w(18),
	height: w(18),
})`
	color: #3b4ba2;
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${ww(23)}px;
	width: 100%;
	margin-top: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}

	p {
		font-size: ${ww(7)}px;
		color: #3b4ba2;
		margin-bottom: 0px;
	}
`;
