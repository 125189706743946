import React from "react";
import { Control, useController } from "react-hook-form";
import { CategoryInput } from "../../types/input";
import { onlySpaces } from "../../utils/string";

import * as S from "./InputName.style";

interface InputNameProps {
	label: string;
	name: "pt-BR" | "en-US" | "fr-FR" | "es";
	isDefault?: boolean;
	control: Control<CategoryInput>;
	error?: string;
	placeholder?: string;
}

export function InputName({
	label,
	name,
	control,
	isDefault = false,
	error,
	placeholder,
}: InputNameProps) {
	const { field } = useController({
		name,
		control,
		rules: { required: true, validate: (value) => !onlySpaces(value) },
		defaultValue: "",
	});

	return (
		<S.InputNameContainer>
			<S.Box>
				<S.Label htmlFor={field.name}>{label}</S.Label>

				{isDefault && <S.MinorText>Padrão</S.MinorText>}
			</S.Box>

			<S.Input
				error={error}
				id={field.name}
				name={field.name}
				onChange={field.onChange}
				onBlur={field.onBlur}
				value={field.value}
				ref={field.ref}
				placeholder={placeholder}
			/>
			{!!error && <h1>{error}</h1>}
		</S.InputNameContainer>
	);
}
