import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down.svg";
import { ReactComponent as Document } from "../../../assets/icons/document.svg";

import { w, ww } from "../../../styles/responsive";
import { DocumentContainerStyle, StatusProps } from "../../../types/country";

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-height: ${ww(205)}px;
	margin-top: ${ww(10)}px;
	overflow-y: scroll;
`;

export const DocumentContainer = styled.div<DocumentContainerStyle>`
	display: flex;
	align-items: center;
	width: 100%;
	height: ${ww(37)}px;
	border: 1px solid #c6c2de;
	border-radius: ${ww(3)}px;
	margin-bottom: ${ww(11)}px;
	padding: ${ww(9)}px;
	background: ${({ isSelected }) => (isSelected ? "#f6f6f6" : "#fff")};

	&:hover {
		background: #f6f6f6;
		cursor: pointer;
	}
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	color: #4b4b4b;
	font-weight: 700;
`;

export const Status = styled.p<StatusProps>`
	color: ${({ status }) => (status ? "#30BA88" : "#FF6868")};
	font-size: ${ww(8)}px;
	font-weight: 400;
	margin-left: auto;
`;

export const DocumentIcon = styled(Document).attrs({
	width: w(15),
	height: w(18),
})`
	margin-right: ${ww(8)}px;
`;

export const ArrowRightIcon = styled(ArrowDown).attrs({
	width: w(11),
	height: w(11),
})`
	transform: rotate(-90deg);
	margin-left: ${ww(8)}px;
`;
