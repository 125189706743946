import React from "react";
import { useForm } from "react-hook-form";

import { FormValues } from "../../types/form";
import Button from "../button/button.components";
import InputComponent from "../input/input.component";
import { GeneralContext } from "../../contexts/generalContext";
import {
	Container,
	SubContainer,
	SearchIcon,
	ButtonContainer,
	FilterIcon,
} from "./search-filter.styles";
import { SearchFilterProps } from "../../types/searchFilter";
import { ww } from "../../styles/responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { listOfTablesWithButton } from "../../constants/table";

const SearchFilter: React.FC<SearchFilterProps> = ({
	hasActions,
	onPressRightButton,
}) => {
	const { showFilter, setShowFilter, setSearchFilters, setPageDetails } =
		React.useContext(GeneralContext);
	const {
		register,
		formState: { errors },
	} = useForm<FormValues>();
	const location = useLocation();
	const navigate = useNavigate();
	const [showButton, setShowButton] = React.useState(false);
	const currentRoute = location.pathname.split("/")[1];
	const [buttonTitle, setButtonTitle] = React.useState("");

	React.useEffect(() => {
		setShowFilter?.(true);
	}, []);

	const showData = () => {
		/* eslint-disable */
		switch (currentRoute) {
			case "acceptance":
				// setButtonTitle("Adicionar Produto");
				return setPageDetails?.({
					route: currentRoute,
					id: 0,
				});
			case "catalog":
				setButtonTitle("Adicionar Produto");
				return setPageDetails?.({
					route: currentRoute,
					id: 1,
				});
			case "admin-users":
				setButtonTitle("Adicionar Admin");
				return setPageDetails?.({
					route: currentRoute,
					id: 4,
				});
			default:
				return;
		}
		/* eslint-enable */
	};

	const navigateTo = () => {
		/* eslint-disable */
		switch (currentRoute) {
			case "catalog":
				return navigate("new");
			case "countries":
				return navigate("details");
			case "category":
				return navigate("details");
			case "modality":
				return navigate("details");
			default:
				return;
		}
		/* eslint-enable */
	};

	React.useEffect(() => {
		if (listOfTablesWithButton.indexOf(currentRoute) > -1) {
			setShowButton(true);
		}

		/* eslint-disable */
		switch (currentRoute) {
			case "catalog":
				return setButtonTitle("Adicionar Produto");
			case "modality":
				return setButtonTitle("Adicionar Modalidade");
			case "category":
				return setButtonTitle("Adicionar Categoria");
			case "countries":
				return setButtonTitle("Habilitar Novo País");
			case "admin-users":
				return setButtonTitle("Adicionar Admin");
				case "finance":
				return setButtonTitle("Exportar XLSX");
			default:
				return;
		}
		/* eslint-enable */
	}, []);

	return (
		<Container>
			<SubContainer>
				{hasActions && (
					<ButtonContainer>
						<Button
							title="Filtros"
							clickFunction={() => setShowFilter?.(!showFilter)}
						>
							<FilterIcon />
						</Button>
					</ButtonContainer>
				)}
				<InputComponent
					register={register}
					name="search"
					placeholder="Pesquise"
					type="text"
					iconStart={true}
					inputStyle={{ background: "#F4F2FF" }}
					searchFilter={setSearchFilters}
					width={ww(223)}
				>
					<SearchIcon />
				</InputComponent>
			</SubContainer>
			{showButton && (
				<ButtonContainer style={{ width: ww(95) }} onClick={showData}>
					<Button
						title={buttonTitle}
						inverterColor={currentRoute === "finance"}
						clickFunction={onPressRightButton ? onPressRightButton : navigateTo}
					/>
				</ButtonContainer>
			)}
		</Container>
	);
};

export default SearchFilter;
