import React, { useEffect } from "react";
import Table from "../../../components/table";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { mockupTitle } from "../../../mockup/personal";
import { TableTitleModel } from "../../../models/table";
import { apiAntiga } from "../../../services/api/axios";
import { convertDate_yy_mm_dd } from "../../../utils/convertDate";

import { Container, Title } from "./personal.styles";
import { useDebounce } from "usehooks-ts";
import { useQuery, useQueryClient } from "react-query";

const PAGE_TITLE = "Professores";

const PersonalPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		totalItems,
		setTotalItems,
	} = React.useContext(GeneralContext);
	const queryClient = useQueryClient();

	const { searchInput = "" } = searchFilters;
	const debouncedValue = useDebounce<string>(searchInput ?? "", 400);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const fetchPersonals = async () => {
		const { data } = await apiAntiga.get("/adm-list-teachers", {
			params: {
				page: currentPage,
				limit: itemsPage,
				search: debouncedValue.trim(),
			},
		});

		setTotalItems?.(data.meta.totalItems);

		data.items?.map((item: any) => {
			const modalities = item.modalities.split(",");
			const { newDate } = convertDate_yy_mm_dd(item.createdAt);

			item.createdAt = newDate;
			item.status = item.status ? "Ativo" : "Inativo";
			item.rating = item.rating.toFixed(2);
			item.schedules = item.schedules.toString();

			/* eslint-disable */
			item.modalities = !!modalities[0]
				? `${modalities[0]} ${!!modalities[1] ? ", " + modalities[1] : ""}${
						modalities.length > 2
							? ", +" + (modalities.length - 2) + "..."
							: ""
					}`
				: "";
			/* eslint-enable */
		});

		return data.items;
	};

	const { data: personals, refetch } = useQuery(
		["personals", currentPage, itemsPage],
		fetchPersonals,
		{
			refetchOnWindowFocus: false,
		},
	);

	const updateData = (data: any) => {
		queryClient.setQueryData("personals", () => data);
	};

	useEffect(() => {
		refetch();
	}, [debouncedValue, refetch]);

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={personals ?? []}
				setData={updateData}
				showDate={false}
				hasPageDetails={true}
				hasCheckBox={true}
				hasModalities={true}
				hasActions={false}
				statusData={[
					{ title: "Ativo", value: true },
					{ title: "Inativo", value: false },
				]}
			/>
		</Container>
	);
};

export default PersonalPage;
