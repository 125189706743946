import styled from "styled-components";
import Button from "../../../components/button/button.components";
import { ww, w } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;
`;

export const Section = styled.div<{ backgroundColor?: string }>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: ${w(25)};
`;

export const ButtonTableItem = styled(Button).attrs({})`
	height: 12px;
`;

export const SubContainer = styled.div<{
	direction: "row" | "column";
	justify?:
		| "flex-start"
		| "flex-end"
		| "center"
		| "space-between"
		| "space-around";
	wrap?: "wrap" | "nowrap";
	widthPerCent?: number;
	width?: number;
	margin?: number;
	padding?: number;
	gap?: number;
}>`
	width: ${({ widthPerCent }) => widthPerCent || 100}%;
	min-width: ${({ width }) => width || 0}px;
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify || "none"};
	flex-wrap: ${({ wrap }) => wrap || "nowrap"};
	background-color: #fff;
	border-radius: 10px;
	margin: ${({ margin }) => ww(margin || 0)}px;
	padding: ${({ padding }) => ww(padding || 0)}px;
	gap: ${({ gap }) => ww(gap || 0)}px;
`;
