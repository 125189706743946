import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FormValues } from "../../../types/form";
import Button from "../../button/button.components";
import Input from "../../input/input.component";
import { validate } from "../../input/validateInput";
import Alert from "../../alert/alert.component";

import LogoImage from "../../../assets/images/logo.png";

import {
	AuthBox,
	AuthContainer,
	EmailIcon,
	Logo,
	LogoContainer,
	MainLabel,
	SubLabel,
} from "../auth.styles";

import { Container, Form, InputContainer } from "./defineNewPassword.styles";
import { ww } from "../../../styles/responsive";
import { adminApi } from "../../../services/api/axios";

const DefineNewPassword: React.FC = () => {
	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<FormValues>();
	const [showAlert, setShowAlert] = React.useState(false);
	const [searchParams] = useSearchParams();
	const [defineNewPasswordToken, setDefinedNewPasswordToken] =
		React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const navigate = useNavigate();

	React.useEffect(() => {
		const token = searchParams.get("token");
		token?.length && setDefinedNewPasswordToken?.(token);
	}, []);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const password = getValues("password");
		const confirmPassword = getValues("confirmPassword");

		const hasError = validate(
			{
				password,
				confirmPassword,
			},
			setError,
			errors,
		);

		if (!hasError && confirmPassword === password) {
			setShowAlert(!showAlert);
			setIsLoading(true);
			try {
				await adminApi.post("/forget-password/reset", {
					token: defineNewPasswordToken,
					password,
				});
				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
			}
		}
	};

	return (
		<AuthContainer>
			<AuthBox>
				<LogoContainer>
					<Logo src={LogoImage} />
				</LogoContainer>
				<MainLabel>Definir Nova Senha</MainLabel>
				<SubLabel>Informe sua nova senha:</SubLabel>
				<Container style={{ display: "flex" }}>
					<Form onSubmit={onSubmit}>
						<InputContainer>
							<Input
								register={register}
								required={true}
								onFocus={() => setError("password", { type: "", message: "" })}
								name="password"
								type="password"
								placeholder="Senha"
								error={errors.password?.message}
								width={ww(165)}
							>
								<EmailIcon />
							</Input>
							<Input
								register={register}
								required={true}
								onFocus={() =>
									setError("confirmPassword", { type: "", message: "" })
								}
								name="confirmPassword"
								type="password"
								placeholder="Confirmar Senha"
								error={errors.confirmPassword?.message}
								width={ww(165)}
							>
								<EmailIcon />
							</Input>
						</InputContainer>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</Form>
				</Container>
			</AuthBox>
			{!isLoading && showAlert && (
				<Alert
					text="Sua nova senha foi criada, agora você pode fazer o login"
					buttonText="Entendi"
					clickFunction={() => navigate("/authentication")}
				/>
			)}
		</AuthContainer>
	);
};

export default DefineNewPassword;
