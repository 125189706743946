import { FormValues } from "../../../types/form";
import { validateEmail } from "../../input/validateInput";
import { onlySpaces } from "../../../utils/string";
import { getAllAdmins } from "../../../hooks/usAdmins";

export const validate = async (
	value: FormValues,
	currentEmail: string,
	setError: any,
) => {
	const { name, email, password, confirmPassword } = value;

	const passError = {
		name: false,
		email: false,
		password: false,
		confirmPassword: false,
	};

	const sameEmail = currentEmail === email;

	const allAdmins = await getAllAdmins();

	const emailAlreadyExists = allAdmins.some((admin) => admin.email === email!);

	if (emailAlreadyExists && !sameEmail) {
		passError.email = true;
		setError("email", {
			type: "required",
			message: "Email já existe!",
		});
	}

	if (typeof name === "string") {
		if (!value.name?.length || onlySpaces(value.name)) {
			passError.name = true;

			setError("name", {
				type: "required",
				message: "Informe um nome.",
			});
		}
	}

	if (typeof email === "string") {
		if (!value.email?.length) {
			passError.email = true;
			setError("email", {
				type: "required",
				message: "Informe um e-mail.",
			});
		} else if (!validateEmail(value.email)) {
			passError.email = true;
			setError("email", {
				type: "required",
				message: "Email inválido.",
			});
		}
	}

	if (typeof password === "string") {
		if (!value.password?.length) {
			passError.password = true;
			setError("password", {
				type: "required",
				message: "Informe uma senha.",
			});
		} else if (value.password.length < 6) {
			passError.password = true;
			setError("password", {
				type: "required",
				message: "Mínimo 6 characteres.",
			});
		} else if (value.password.length > 20) {
			passError.password = true;
			setError("password", {
				type: "required",
				message: "Máximo 20 characteres.",
			});
		}
	}

	if (typeof confirmPassword === "string") {
		if (!value.confirmPassword?.length) {
			passError.confirmPassword = true;
			setError("confirmPassword", {
				type: "required",
				message: "Informe uma senha.",
			});
		}
		if (value.password?.localeCompare(value.confirmPassword!)) {
			passError.confirmPassword = true;
			setError("confirmPassword", {
				type: "required",
				message: "Valor diferente do campo senha.",
			});
		}
	}

	return (
		passError.name ||
		passError.email ||
		passError.password ||
		passError.confirmPassword
	);
};
