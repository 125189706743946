import styled from "styled-components";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { w, ww } from "../../../styles/responsive";
import { TextContainerProps } from "../../../types/details";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	padding-top: ${ww(8)}px;
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 500;
	color: #3b4ba2;
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	color: #3b4ba2;
	margin-bottom: ${ww(6)}px;
`;

export const TextContainer = styled.div<TextContainerProps>`
	display: flex;
	flex-direction: ${({ flexColumn }) => (flexColumn ? "column" : "row")};
	margin-bottom: ${ww(8)}px;
	align-items: ${({ flexColumn }) => (flexColumn ? "flex-start" : "center")};

	& :first-child {
		color: #7c7c7c;
		font-size: ${ww(8)}px;
		margin-bottom: 2px;
	}

	& :last-child {
		color: #7c7c7c;
		font-size: ${ww(7)}px;
		margin-left: ${({ flexColumn }) => (flexColumn ? "0" : ww(3))}px;
	}
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(210)}px;
	padding: ${w(7, 17, 14, 17)};
	position: relative;
`;

export const IconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const Icon = styled(Close).attrs({
	width: w(14),
	height: w(14),
})`
	display: flex;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: ${ww(6)}px;
`;

export const Divisor = styled.div`
	height: 1px;
	width: calc(100% + ${ww(34)}px);
	transform: translateX(-${ww(17)}px);
	border-bottom: 1px solid #e5e5e5;
	margin: ${w(11, 0)};
`;

export const Ul = styled.ul`
	marginleft: ${ww(14)}px;
`;

export const Li = styled.li``;
