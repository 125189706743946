import styled from "styled-components";
import { ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 100vh;
	width: ${ww(143)}px;
	min-width: 255px;
	background: #fff;
	overflow: hidden;
`;
