import { acceptanceStatus, acceptanceType } from "../constants/acceptance";
import { supportStatus } from "../constants/support";

export const formatCitiesOfOperation = (cities: any) => {
	const list: string[] = [];
	cities?.split(",").forEach((item: string, index: number) => {
		// get the item that is above the third comma
		if (index % 3 === 0) {
			list.push(item);
		}
	});
	return list;
};

// format cities of operation: ex: "Brasília, df, Brasíl" => Brasília - DF
export function formatCity(
	cityStateAndCountry: string,
	showCountry = false,
	showState = false,
) {
	const cityStateAndCountryArray = cityStateAndCountry.split(", ");
	const city = cityStateAndCountryArray[0];
	const state = cityStateAndCountryArray[1];
	const country = cityStateAndCountryArray[2];
	if (showCountry) return `${city} - ${state} - ${country}`;
	if (showState) return `${city} - ${state}`;
	return `${city}`;
}

export const formatTypeText = (type: string) => {
	if (type === acceptanceType.newRegister) return "Novo Cadastro";
	if (type === acceptanceType.newCategory) return "Nova Categoria";
	if (type === acceptanceType.countryChange) return "Mudança de País";
};

export const formatStatusText = (status: string) => {
	if (status === acceptanceStatus.approved) return "Aprovado";
	if (status === acceptanceStatus.rejected) return "Reprovado";
	if (status === acceptanceStatus.pending) return "Pendente";
};

export const formatSupportStatusText = (status: string) => {
	if (status === supportStatus.opened) return "Aberto";
	if (status === supportStatus.answered) return "Respondido";
	if (status === supportStatus.closed) return "Concluído";
};
