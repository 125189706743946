import React from "react";
import MenuItem from "../menu-item.tsx/menu-item.component";
import MenuTop from "../menu-top/menu-top.components";
import { Container } from "./menu.styles";

const Menu: React.FC = () => {
	return (
		<Container>
			<MenuTop />
			<MenuItem />
		</Container>
	);
};

export default Menu;
