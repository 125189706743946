import React from "react";
import styled from "styled-components";

type BackdropLoadingProps = {
	isVisible: boolean;
};

export function BackdropLoading({ isVisible }: BackdropLoadingProps) {
	if (!isVisible) return null;

	return (
		<StyledBackdrop>
			<StyledLoading>
				<div className="loading-spinner" />
			</StyledLoading>
		</StyledBackdrop>
	);
}

const StyledBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
`;

const StyledLoading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	.loading-spinner {
		border: 5px solid #f3f3f3;
		border-radius: 50%;
		border-top: 5px solid #3b4ba2;
		width: 50px;
		height: 50px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
