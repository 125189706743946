import React from "react";

import { Container, Page } from "../styles";
import Menu from "../../components/menu/menu.components";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "../dashboard/dashboard.page";
import SupportPage from "../support/support.component";
import AcceptancePage from "../acceptance";
import DetailsPage from "../details-modal/details-modal.page";
import PersonalIndex from "../personal";
import StudentIndex from "../student";
import CountryIndex from "../countries";
import CatalogIndex from "../catalog";
import AdminUsersPage from "../admin-users/admin-users.component";
import { SystemPage } from "../system";
import MyAccountPage from "../my-account/my-account.component";
import { FinancePage } from "../finance";
import CategoryIndex from "../category";
import ModalityIndex from "../modality";

const AnotherPage = () => {
	return (
		<div>
			<h1>Another Page</h1>
		</div>
	);
};

const HomePage: React.FC = () => {
	return (
		<Container>
			<Menu />
			<DetailsPage />
			<Page>
				<Routes>
					<Route path="/" element={<Navigate to="/dashboard" replace />} />
					<Route path="dashboard" element={<DashboardPage />} />
					<Route path="support" element={<SupportPage />} />
					<Route path="finance" element={<FinancePage />} />
					<Route path="another-page" element={<AnotherPage />} />
					<Route path="catalog/*" element={<CatalogIndex />} />
					<Route path="acceptance/*" element={<AcceptancePage />} />
					<Route path="personal/*" element={<PersonalIndex />} />
					<Route path="student/*" element={<StudentIndex />} />
					<Route path="admin-users" element={<AdminUsersPage />} />
					<Route path="modality/*" element={<ModalityIndex />} />
					<Route path="category/*" element={<CategoryIndex />} />
					<Route path="countries/*" element={<CountryIndex />} />
					<Route path="system" element={<SystemPage />} />
					<Route path="my-account" element={<MyAccountPage />} />
				</Routes>
			</Page>
		</Container>
	);
};

export default HomePage;
