import styled from "styled-components";
import { ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InputContainer = styled.div`
	margin-bottom: ${ww(17)}px;
`;

export const Form = styled.form``;
