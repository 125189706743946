import styled, { css, keyframes } from "styled-components";
import { w } from "../../../styles/responsive";

type ContainerProps = {
	hasError: boolean;
};

const shake = keyframes`
25% {
      transform: translateX(4px)
  }
  50% {
      transform: translateX(-4px)
  }
  75% {
      transform: translateX(4px)
  }
`;

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;

	border: 0.8px solid
		${({ hasError }) => (hasError ? "#FF6868" : "rgba(0, 0, 0, 0.4)")};
	border-radius: 5px;
	padding: 9px 5px 6px 5px;

	&.error {
		animation: 0.3s ${shake} ease-in-out;
	}
`;

type LabelProps = {
	isActive: boolean;
};

export const Label = styled.label<LabelProps>`
	padding-left: ${(props) => (props.placeholder ? "0" : "21px")};
	font-family: "Inter";
	font-style: ${(props) => (props.isActive ? "normal" : "italic")};
	font-weight: ${(props) => (props.isActive ? 600 : 400)};
	font-size: ${(props) => (props.isActive ? "12px" : "14px")};
	line-height: ${(props) => (props.isActive ? "16px" : "17px")};

	margin-top: ${(props) => (props.isActive ? "-3px" : 0)};

	transition: 0.3s ease-in-out;

	color: #7c7c7c;
`;

export const Input = styled.input`
	padding-left: ${(props) => (props.placeholder ? "0" : "21px")};

	font: normal 400 14px/17px "Inter", sans-serif;
	margin-top: ${w(4)};
	margin-bottom: ${w(1.5)};

	&::placeholder {
		font-family: "Inter";
		font-style: italic;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;

		color: rgba(0, 0, 0, 0.4);
	}
`;

export const InputTextArea = styled.textarea`
	padding-left: ${(props) => (props.placeholder ? "0" : "21px")};

	font: normal 400 14px/17px "Inter", sans-serif;
	margin: ${w(4)} 0px;

	&::placeholder {
		font-family: "Inter";
		font-style: italic;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;

		color: rgba(0, 0, 0, 0.4);
	}
`;

export const Line = styled.div`
	width: 100%;
	border-top: 0.5px solid rgba(0, 0, 0, 0.4);
`;
