import styled from "styled-components";
import { ww } from "../styles/responsive";

export const Container = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
	align-items: center;
	justify-content: center;
	background: #f8f8f8;
	overflow-x: scroll;
`;

export const Page = styled.div`
	display: flex;
	flex: 8;
	height: 100%;
	overflow-x: scroll;
`;
