export const acceptanceType = {
	newRegister: "ACCOUNT_ACTIVATION",
	newCategory: "MODALTY_ACTIVATION",
	countryChange: "NEW_REGION",
};

export const acceptanceStatus = {
	approved: "APPROVED",
	rejected: "REJECTED",
	pending: "PENDING",
};

export const userGender = {
	male: "male",
	female: "female",
	other: "other",
};

export const documentType = {
	personalDocument: "PERSONAL_DOCUMENT",
	profilePicture: "PROFILE_PICTURE",
	professionalLicense: "PROFESSIONAL_LICENSE",
};
