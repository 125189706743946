import styled, { css } from "styled-components/macro";

import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as Suport } from "../assets/icons/contact_support.svg";
import { ReactComponent as Real } from "../assets/icons/real.svg";

import { ReactComponent as Catalog } from "../assets/icons/shopping_catalog.svg";

import { ReactComponent as Modality } from "../assets/icons/modality@1.svg";
import { ReactComponent as Countries } from "../assets/icons/flag.svg";
import { ReactComponent as System } from "../assets/icons/system.svg";

import { ReactComponent as Acceptance } from "../assets/icons/acceptance.svg";
import { ReactComponent as Student } from "../assets/icons/user.svg";
import { ReactComponent as Administrator } from "../assets/icons/verified_user.svg";

import { ReactComponent as Settings } from "../assets/icons/settings@2.svg";
import { ReactComponent as Language } from "../assets/icons/language.svg";
import { ReactComponent as Logout } from "../assets/icons/log_out.svg";

import { ReactComponent as Teachers } from "../assets/icons/teachers.svg";
import { ReactComponent as Category } from "../assets/icons/category.svg";

import { MenuIconProps } from "../types/menu";
import { w } from "../styles/responsive";

const iconStyle = css<MenuIconProps>`
	& path {
		fill: ${({ color }) => (color ? color : "#4b4b4b")};
	}
`;

const DashboardIcon = styled(Dashboard).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const SuportIcon = styled(Suport).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const RealIcon = styled(Real).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;
const CatalogIcon = styled(Catalog).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const ModalityIcon = styled(Modality).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;
const CountriesIcon = styled(Countries).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const SystemIcon = styled(System).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const AcceptanceIcon = styled(Acceptance).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const StudentIcon = styled(Student).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const AdministratorIcon = styled(Administrator).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const SettingsIcon = styled(Settings).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const LanguageIcon = styled(Language).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const LogoutIcon = styled(Logout).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const TeacherIcon = styled(Teachers).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

const CategoryIcon = styled(Category).attrs({
	width: w(10),
	height: w(10),
})<MenuIconProps>`
	${iconStyle}
`;

export const menuData = [
	{
		id: 1,
		title: "Geral",
		data: [
			{ id: 10, title: "Dashboard", icon: DashboardIcon, link: "dashboard" },
			{
				id: 11,
				title: "Aprovações",
				icon: AcceptanceIcon,
				link: "acceptance",
			},
			{ id: 12, title: "Suporte", icon: SuportIcon, link: "support" },
			{ id: 13, title: "Financeiro", icon: RealIcon, link: "finance" },
		],
	},
	{
		id: 2,
		title: "Loja",
		data: [{ id: 50, title: "Catálogo", icon: CatalogIcon, link: "catalog" }],
	},
	{
		id: 3,
		title: "Configurações",
		data: [
			{ id: 20, title: "Categorias", icon: CategoryIcon, link: "category" },
			{
				id: 21,
				title: "Modalidades",
				icon: ModalityIcon,
				link: "modality",
			},
			{
				id: 22,
				title: "Países Habilitados ",
				icon: CountriesIcon,
				link: "countries",
			},
			{
				id: 23,
				title: "Parâmetros Sistema",
				icon: SystemIcon,
				link: "system",
			},
		],
	},
	{
		id: 4,
		title: "Usuários",
		data: [
			{
				id: 31,
				title: "Professores",
				icon: TeacherIcon,
				link: "personal",
			},
			{ id: 32, title: "Alunos", icon: StudentIcon, link: "student" },
			{
				id: 33,
				title: "Administradores",
				icon: AdministratorIcon,
				link: "admin-users",
			},
		],
	},
	{
		id: 5,
		title: "Perfil",
		data: [
			{
				id: 40,
				title: "Minha Conta",
				icon: SettingsIcon,
				link: "my-account",
			},
			{
				id: 42,
				title: "Sair",
				icon: LogoutIcon,
				link: "another-page",
			},
		],
	},
];
