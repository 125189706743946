import styled from "styled-components";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";

import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	margin-bottom: ${ww(3)}px;
	color: #7c7c7c;
	font-weight: 500;
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	text-align: left;
	color: #3b4ba2;
	margin-bottom: ${ww(13)}px;
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(200)}px;
	padding: ${w(7, 17, 14, 17)};
	position: relative;
	padding-top: ${ww(20)}px;
`;

export const IconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const Icon = styled(Close).attrs({
	width: w(14),
	height: w(14),
})`
	display: flex;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: ${ww(6)}px;
`;

export const InputContainer = styled.div`
	margin-bottom: ${ww(17)}px;
	width: 100%;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SubContainer = styled.div`
	/* display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center; */
`;

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(14),
	height: w(14),
})``;

export const CancelContainer = styled.div`
	align-self: center;
	margin-top: ${ww(8)}px;

	p {
		font-size: ${ww(8)}px;
		text-align: center;
		font-weight: 400;
		color: #3b4ba2;
	}
	&:hover {
		cursor: pointer;
	}
`;
