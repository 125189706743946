import styled, { css } from "styled-components/macro";
import { ReactComponent as Greeko } from "../../assets/icons/logo.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings@1.svg";
import { ReactComponent as Logout } from "../../assets/icons/log_out.svg";
import { w, ww } from "../../styles/responsive";

const iconStyle = css`
	&:hover {
		cursor: pointer;
	}
`;

export const TopContainer = styled.div`
	display: flex;
	align-items: center;
	height: ${ww(71)}px;
	width: ${ww(145)}px;
	background: linear-gradient(270deg, #3b4ba2 0%, #4f66e2 100%);
	padding: ${ww(10)}px;
	min-width: 255px;
`;

export const Logo = styled(Greeko).attrs({
	width: w(21),
	height: w(25),
})`
	margin-bottom: ${ww(10)}px;
`;

export const TopLogoContainer = styled.div`
	display: flex;
	flex: 4;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

export const TopText = styled.p`
	color: #fff;
	font-size: ${ww(9)}px;
	font-weight: 700;
	text-transform: capitalize;
`;

export const ActionsContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	margin-top: auto;
`;

export const SettingsIcon = styled(Settings).attrs({
	width: w(11),
	height: w(11),
})`
	${iconStyle}
`;

export const LogoutIcon = styled(Logout).attrs({
	width: w(11),
	height: w(11),
})`
	${iconStyle}
	margin-left: ${ww(6)}px;
`;
