import React from "react";
import { useForm } from "react-hook-form";

import { TableActionsProps } from "../../../types/table";
import { FormValues } from "../../../types/form";

import Button from "../../button/button.components";
import InputComponent from "../../input/input.component";
import SelectComponent from "../../select/select.component";
import { isValidDate } from "../../input/validateInput";

import {
	ButtonContainer,
	CalendarIcon,
	ArrowDownIcon,
	Container,
	StatusContainer,
	SubContainer,
	Text,
	Form,
} from "./table-action.styles";
import { GeneralContext } from "../../../contexts/generalContext";
import { ww } from "../../../styles/responsive";

const TableAction: React.FC<TableActionsProps> = ({
	showDate = false,
	hasModalities,
	hasCategories,
	hasSupportCategories,
	supportCategoriesData = [],
	hasUserType,
	statusData = [],
	hasCheckBox,
	hasCountryOfOperation,
	countryOfOperationData = [],
	hasAprovationType,
	aprovationTypeData = [],
}) => {
	const { showFilter, setSearchFilters } = React.useContext(GeneralContext);
	const [status, setStatus] = React.useState("");
	const [modality, setModality] = React.useState([""]);
	const [countryOfOperation, setCountryOfOperation] = React.useState("");
	const [aprovationType, setAprovationType] = React.useState("");
	const [typeUser, setTypeUser] = React.useState("");
	const [supportCategory, setSupportCategory] = React.useState("");

	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<FormValues>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const dateFrom = getValues("from")!
			? isValidDate(getValues("from")!, setError, "from")
			: true;

		const dateTo = getValues("to")!
			? isValidDate(getValues("to")!, setError, "to")
			: true;

		if (!dateFrom || !dateTo) {
			return;
		}

		const data = {
			dateFrom: "",
			dateTo: "",
			status: "",
			modality: [""],
			countryOfOperation: "",
			aprovationType: "",
			typeUser: "",
			supportCategory: "",
		};

		if (showDate) {
			data.dateFrom = getValues("from")!;
			data.dateTo = getValues("to")!;
		}

		if (statusData.length) {
			data.status = status!;
		}

		if (hasModalities) {
			data.modality = modality;
		}

		if (hasCountryOfOperation) {
			data.countryOfOperation = countryOfOperation;
		}

		if (hasAprovationType) {
			data.aprovationType = aprovationType;
		}

		if (hasUserType) {
			data.typeUser = typeUser;
		}

		if (hasSupportCategories) {
			data.supportCategory = supportCategory;
		}

		setSearchFilters?.(data);
		//mandar os dados para pesquisa
	};

	if (!showFilter) return <></>;

	return (
		<Container>
			<Form onSubmit={onSubmit}>
				{showDate && (
					<>
						<SubContainer>
							<Text>De:</Text>
							<InputComponent
								register={register}
								type="date"
								name="from"
								placeholder="11/12/21"
								width={ww(62)}
								iconStart={true}
								onFocus={() => setError("from", { type: "", message: "" })}
								maxLength={8}
								inputStyle={
									errors.from?.message
										? { border: "1px solid #FF6868", backgroundColor: "#fff" }
										: { backgroundColor: "#fff" }
								}
							>
								<CalendarIcon />
							</InputComponent>
						</SubContainer>
						<SubContainer>
							<Text>Para:</Text>
							<InputComponent
								register={register}
								type="date"
								name="to"
								placeholder="11/12/21"
								width={ww(62)}
								iconStart={true}
								onFocus={() => setError("to", { type: "", message: "" })}
								maxLength={8}
								inputStyle={
									errors.from?.message
										? { border: "1px solid #FF6868", backgroundColor: "#fff" }
										: { backgroundColor: "#fff" }
								}
							>
								<CalendarIcon />
							</InputComponent>
						</SubContainer>
					</>
				)}
				{hasModalities && (
					<SubContainer>
						<Text>Modalidades:</Text>
						<SelectComponent
							data={[
								{ title: "Body Building", value: "Body Building" },
								{ title: "Corrida", value: "Corrida" },
								{ title: "Natação", value: "Natação" },
								{ title: "Musculação", value: "Musculação" },
							]}
							setStatus={setStatus}
							setModality={setModality}
							hasCheckBox={hasCheckBox}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}
				{hasSupportCategories && (
					<SubContainer>
						<Text>Categoria:</Text>
						<SelectComponent
							data={supportCategoriesData}
							setStatus={setSupportCategory}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}

				{hasUserType && (
					<SubContainer>
						<Text>Tipo de Usuário:</Text>
						<SelectComponent
							data={[
								{ title: "Selecionar", value: "" },
								{ title: "Personal", value: "TEACHER" },
								{ title: "Estudante", value: "STUDENT" },
							]}
							setStatus={setTypeUser}
							// setModality={setModality}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}

				{hasCategories && (
					<SubContainer>
						<Text>Categorias:</Text>
						<SelectComponent
							data={[{ title: "Academia", value: "Academia" }]}
							setStatus={setStatus}
							setModality={setModality}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}

				{!!statusData.length && (
					<StatusContainer>
						<Text>Status:</Text>
						<SelectComponent
							data={statusData}
							setStatus={setStatus}
							width={ww(81)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</StatusContainer>
				)}

				{hasCountryOfOperation && (
					<SubContainer>
						<Text>País de Atuação:</Text>
						<SelectComponent
							data={countryOfOperationData}
							setStatus={setCountryOfOperation}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}

				{hasAprovationType && (
					<SubContainer>
						<Text>Tipo de Aprovação:</Text>
						<SelectComponent
							data={aprovationTypeData}
							setStatus={setAprovationType}
							width={ww(131)}
						>
							<ArrowDownIcon />
						</SelectComponent>
					</SubContainer>
				)}
				<ButtonContainer>
					<Button title="Salvar" type="submit" inverterColor />
				</ButtonContainer>
			</Form>
		</Container>
	);
};

export default TableAction;
