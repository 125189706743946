import React from "react";
import { useQuery, useQueryClient } from "react-query";
import Table from "../../components/table";
import { GeneralContext } from "../../contexts/generalContext";
import { useTitle } from "../../hooks/useTitle";
import { mockupTitle } from "../../mockup/modality";
import { adminApi } from "../../services/api/axios";
import { Pagination } from "../../types/requestPagination";

import { Container, Title } from "./modality.styles";

interface Modality {
	id: string;
	category: {
		id: string;
		name: string;
	};
	name: string;
	minimalPrice: string;
	status: string;
}
interface GetModalitiesResponseItem {
	items: Modality[];
	meta: Pagination;
}

const PAGE_TITLE = "Modalidades";

const ModalityPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	const queryClient = useQueryClient();

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const { status = "", searchInput = "" } = searchFilters;

	const { data } = useQuery(
		["modalities", searchFilters, currentPage, itemsPage],
		async () => {
			const response = await adminApi.get<GetModalitiesResponseItem>(
				"/modalities",
				{
					params: {
						page: currentPage,
						limit: itemsPage,
						status: status,
						search: searchInput,
					},
				},
			);

			const formattedData = response.data.items.map((modality: any) => {
				return {
					id: modality.modality_id,
					category: modality.category_name,
					name: modality.name,
					minimalPrice: modality.minimalPrice,
					status: modality.active ? "Ativo" : "Inativo",
					hidden: {
						category_id: modality.category_id,
					},
				};
			});

			setTotalItems?.(response.data.meta.totalItems);

			return formattedData;
		},
	);

	const setData = (newData: any) => {
		queryClient.setQueryData("modalities", () => {
			return {
				data: {
					...newData,
				},
			};
		});
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={data!}
				setData={setData}
				showDate={false}
				hasModalities={true}
				hasCategories={true}
				hasActions={false}
				hasPageDetails
				statusData={[
					{ title: "Ativo", value: "Ativo" },
					{ title: "Inativo", value: "Inativo" },
				]}
			/>
		</Container>
	);
};

export default ModalityPage;
