import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow_right.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down2.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: #f2f0f9;
	height: ${ww(21)}px;
	width: 100%;
	border-radius: ${w(0, 0, 6, 6)};
	padding-right: ${ww(34)}px;
	margin-top: auto;
	position: absolute;
	bottom: 0;
	right: 0;
`;

export const SubContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${ww(114)}px;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	color: #6e6893;
	font-weight: 500;
`;

export const IconContainer = styled(SubContainer)`
	justify-content: space-between;
	width: ${ww(51)}px;
`;

export const IconSubContainer = styled(SubContainer)`
	width: ${ww(20)}px;
	height: ${ww(21)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
	width: w(4),
	height: w(6),
})``;
export const ArrowRightIcon = styled(ArrowRight).attrs({
	width: w(4),
	height: w(6),
})``;

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(6),
	height: w(3.5),
})``;
