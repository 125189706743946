import React from "react";

import { ProfessorAceptanceProps } from "../../../types/dashboard";

import {
	Container,
	ImageContainer,
	Image,
	TextContainer,
	Title,
	Text,
	IconArrowRight,
} from "./professor-acceptance-item.styles";

const ProfessorItem: React.FC<ProfessorAceptanceProps> = ({
	name,
	location,
	image,
	onPress,
}) => {
	return (
		<Container onClick={onPress}>
			<ImageContainer>
				<Image src={image} />
			</ImageContainer>
			<TextContainer>
				<Title>{name}</Title>
				<Text>{location}</Text>
			</TextContainer>
			<IconArrowRight />
		</Container>
	);
};

export default ProfessorItem;
