import React from "react";

import {
	Container,
	StatusContainer,
	Text,
	IconContainer,
	Icon,
} from "./documentSettings.styles";
import { DocumentSettingsProps } from "../../../types/country";
import EditorComponent from "../../Editor/Editor";
import { ErrorMessage } from "../../select/select.styles";

const DocumentSettings: React.FC<DocumentSettingsProps> = ({
	id,
	data,
	hasChanged,
	setHasChanged,
	error,
}) => {
	const [document, setDocument] = React.useState<any>();

	React.useEffect(() => {
		const newData: any = {
			status: "",
			description: "",
		};

		data?.forEach((document: any) => {
			if (document.id === id) {
				newData.status = document.status;
				newData.description = document.description;
			}
		});
		setDocument(newData);
	}, [hasChanged, id]);

	const setStatus = () => {
		data[data.findIndex((document: any) => document.id === id)].status =
			!document?.status;
		setHasChanged(!hasChanged);
	};

	const setDescription = React.useCallback(
		(text: string) => {
			data[data.findIndex((document: any) => document.id === id)].description =
				text;
			setHasChanged(!hasChanged);
		},
		[data, id],
	);

	return (
		<Container>
			<StatusContainer onClick={setStatus}>
				<Text>{document?.status ? "Ativo" : "Inativo"}</Text>
				<IconContainer>
					<Icon status={document?.status} />
				</IconContainer>
			</StatusContainer>

			{document?.description && (
				<EditorComponent
					description={document?.description}
					setDescription={setDescription}
				/>
			)}
			{!!error && <ErrorMessage>{error}</ErrorMessage>}
		</Container>
	);
};

export default DocumentSettings;
