import React from "react";

import { Route, Routes } from "react-router-dom";
import ModalityDetailsPage from "./modality-details";
import ModalityList from "./modality.list";

const ModalityIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<ModalityList />} />
			<Route path="details" element={<ModalityDetailsPage />} />
		</Routes>
	);
};

export default ModalityIndex;
