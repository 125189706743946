import React from "react";

import { ButtonProps } from "../../types/button";
import Loading from "../loading/loading.components";

import {
	PrimaryContainer,
	SecondaryContainer,
	CustomContainer,
	DisabledContainer,
	Text,
} from "./tableButton.styles";

const TableButton: React.FC<ButtonProps> = ({
	type = "button",
	title,
	clickFunction,
	isLoading = false,
	children,
	inverterColor = false,
	customColor,
	isDisabled = false,
	width,
}) => {
	/* eslint-disable */
	const Container = customColor
		? CustomContainer
		: inverterColor
		? SecondaryContainer
		: PrimaryContainer;
	/* eslint-enable */

	return (
		<>
			{!isDisabled ? (
				<Container
					type={type}
					onClick={clickFunction}
					disabled={isLoading}
					isLoading={isLoading}
					customColor={customColor}
					width={width}
				>
					{children}
					{isLoading ? (
						<Loading color={customColor === "#EFD700" ? "#383838" : "#fff"} />
					) : (
						<Text inverterColor={inverterColor}>{title}</Text>
					)}
				</Container>
			) : (
				<DisabledContainer>
					<Text>{title}</Text>
				</DisabledContainer>
			)}
		</>
	);
};

export default TableButton;
