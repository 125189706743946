import React from "react";

import { Route, Routes } from "react-router-dom";
import CountryDetailsPage from "./countryDetails/countryDetails.component";
import CountriesPage from "./main/countries.component";

const CountryIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<CountriesPage />} />
			<Route path="details" element={<CountryDetailsPage />} />
		</Routes>
	);
};

export default CountryIndex;
