import format from "date-fns/format";
import React, { useEffect } from "react";
import Table from "../../../components/table";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { mockupTitle } from "../../../mockup/student";
import { adminApi } from "../../../services/api/axios";

import { Container, Title } from "./student.styles";
import { useDebounce } from "usehooks-ts";
import { useQuery, useQueryClient } from "react-query";

interface GetStudentsItem {
	id: string;
	name: string;
	createdAt: string;
	status: boolean;
	rating: number;
	scheduleCount: number;
}

interface Pagination<T> {
	items: T[];
	meta: {
		totalItems: number;
		itemCount: number;
		itemsPerPage: number;
		totalPages: number;
		currentPage: number;
	};
}

const PAGE_TITLE = "Alunos";

const StudentPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	const queryClient = useQueryClient();

	const { searchInput = "", status = "" } = searchFilters;
	const debouncedValue = useDebounce<string>(searchInput ?? "", 400);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const fetchStudents = async () => {
		const { data } = await adminApi.get<Pagination<GetStudentsItem>>(
			"/student",
			{
				params: {
					page: currentPage,
					limit: itemsPage,
					search: debouncedValue.trim(),
					status: typeof status === "boolean" ? status : undefined,
				},
			},
		);
		setTotalItems?.(data.meta.totalItems);

		return data.items.map((item: GetStudentsItem) => ({
			id: item.id,
			name: item.name,
			scheduleCount: String(item.scheduleCount),
			createdAt: format(new Date(item.createdAt), "dd/MM/yyyy"),
			rating: String(item.rating),
			status: item.status ? "Ativo" : "Inativo",
		}));
	};

	const { data: students, refetch } = useQuery(
		["students", currentPage, itemsPage, status],
		fetchStudents,
		{ refetchOnWindowFocus: false },
	);

	useEffect(() => {
		refetch();
	}, [debouncedValue, refetch]);

	const updateData = (data: any) => {
		queryClient.setQueryData("students", () => data);
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle}
				data={students ?? []}
				setData={updateData}
				showDate={false}
				hasPageDetails={true}
				hasCheckBox={true}
				hasModalities={false}
				statusData={[
					{ title: "Ativo", value: true },
					{ title: "Inativo", value: false },
				]}
			/>
		</Container>
	);
};

export default StudentPage;
