import styled from "styled-components";

import { ReactComponent as ArcoFlecha } from "../assets/icons/arco_flecha.svg";
import { ReactComponent as ArtesMarciais } from "../assets/icons/artes_marciais.svg";
import { ReactComponent as Basquete } from "../assets/icons/basquete.svg";
import { ReactComponent as BeachTenis } from "../assets/icons/beach_tenis.svg";
import { ReactComponent as Bike } from "../assets/icons/bike.svg";
import { ReactComponent as Corrida } from "../assets/icons/corrida.svg";
import { ReactComponent as Crossfit } from "../assets/icons/crossfit.svg";
import { ReactComponent as Crossfit2 } from "../assets/icons/crossfit2.svg";
import { ReactComponent as Danca2 } from "../assets/icons/danca2.svg";
import { ReactComponent as Funcional } from "../assets/icons/funcional.svg";
import { ReactComponent as Futebol } from "../assets/icons/futebol.svg";
import { ReactComponent as Golfe } from "../assets/icons/golfe.svg";
import { ReactComponent as Golfe2 } from "../assets/icons/golfe2.svg";
import { ReactComponent as Musculacao } from "../assets/icons/musculacao.svg";
import { ReactComponent as Natacao } from "../assets/icons/natacao.svg";
import { ReactComponent as Parkour } from "../assets/icons/parkour.svg";
import { ReactComponent as Personal } from "../assets/icons/personal.svg";
import { ReactComponent as Tenis } from "../assets/icons/tenis.svg";
import { ReactComponent as Tenis2 } from "../assets/icons/tenis2.svg";
import { ReactComponent as Tenis3 } from "../assets/icons/tenis3.svg";
import { ReactComponent as Volei } from "../assets/icons/volei.svg";
import { ReactComponent as Volei2 } from "../assets/icons/volei2.svg";
import { TableTitleModel } from "../models/table";

import { w } from "../styles/responsive";

export const mockupTitle: TableTitleModel[] = [
	{ title: "Ícone" },
	{ title: "Categoria" },
	{ title: "Status", icon: true },
];

const Volei2Icon = styled(Volei2).attrs({
	width: w(27),
	height: w(27),
})``;

const Tenis2Icon = styled(Tenis2).attrs({
	width: w(27),
	height: w(27),
})``;

const Tenis3Icon = styled(Tenis3).attrs({
	width: w(27),
	height: w(27),
})``;

const VoleiIcon = styled(Volei).attrs({
	width: w(27),
	height: w(27),
})``;

const ParkourIcon = styled(Parkour).attrs({
	width: w(27),
	height: w(27),
})``;

const PersonalIcon = styled(Personal).attrs({
	width: w(27),
	height: w(27),
})``;

const TenisIcon = styled(Tenis).attrs({
	width: w(27),
	height: w(27),
})``;

const Golfe2Icon = styled(Golfe2).attrs({
	width: w(27),
	height: w(27),
})``;

const ArcoFlechaIcon = styled(ArcoFlecha).attrs({
	width: w(27),
	height: w(27),
})``;

const ArtesMarciaisIcon = styled(ArtesMarciais).attrs({
	width: w(27),
	height: w(27),
})``;

const MusculacaoIcon = styled(Musculacao).attrs({
	width: w(27),
	height: w(27),
})``;

const NatacaoIcon = styled(Natacao).attrs({
	width: w(27),
	height: w(27),
})``;

const FuncionalIcon = styled(Funcional).attrs({
	width: w(27),
	height: w(27),
})``;

const FutebolIcon = styled(Futebol).attrs({
	width: w(27),
	height: w(27),
})``;

const GolfeIcon = styled(Golfe).attrs({
	width: w(27),
	height: w(27),
})``;

const BasqueteIcon = styled(Basquete).attrs({
	width: w(27),
	height: w(27),
})``;

const BeachTenisIcon = styled(BeachTenis).attrs({
	width: w(27),
	height: w(27),
})``;

const BikeIcon = styled(Bike).attrs({
	width: w(27),
	height: w(27),
})``;

const Danca2Icon = styled(Danca2).attrs({
	width: w(27),
	height: w(27),
})``;

const CorridaIcon = styled(Corrida).attrs({
	width: w(27),
	height: w(27),
})``;

const CrossfitIcon = styled(Crossfit).attrs({
	width: w(27),
	height: w(27),
})``;

const Crossfit2Icon = styled(Crossfit2).attrs({
	width: w(27),
	height: w(27),
})``;

export const mockupData = [
	{
		id: 1,
		icon: VoleiIcon,
		category: "Volei",
		status: "Ativo",
	},
	{
		id: 2,
		icon: Tenis2Icon,
		category: "Tenis",
		status: "Inativo",
	},
	{
		id: 3,
		icon: ParkourIcon,
		category: "Parkour",
		status: "Ativo",
	},
];

export const iconsData = [
	{
		id: 99,
		icon: VoleiIcon,
	},
	{
		id: 1,
		icon: Tenis2Icon,
	},
	{
		id: 2,
		icon: Tenis3Icon,
	},
	{
		id: 3,
		icon: VoleiIcon,
	},
	{
		id: 4,
		icon: ParkourIcon,
	},
	{
		id: 5,
		icon: PersonalIcon,
	},
	{
		id: 6,
		icon: TenisIcon,
	},
	{
		id: 7,
		icon: Golfe2Icon,
	},
	{
		id: 8,
		icon: ArcoFlechaIcon,
	},

	{
		id: 9,
		icon: ArtesMarciaisIcon,
	},
	{
		id: 10,
		icon: CrossfitIcon,
	},
	{
		id: 11,
		icon: Volei2Icon,
	},
	{
		id: 12,
		icon: CorridaIcon,
	},
	{
		id: 13,
		icon: MusculacaoIcon,
	},
	{
		id: 14,
		icon: NatacaoIcon,
	},
	{
		id: 15,
		icon: FuncionalIcon,
	},
	{
		id: 16,
		icon: FutebolIcon,
	},
	{
		id: 17,
		icon: GolfeIcon,
	},
	{
		id: 18,
		icon: BasqueteIcon,
	},
	{
		id: 19,
		icon: BeachTenisIcon,
	},
	{
		id: 20,
		icon: BikeIcon,
	},
	{
		id: 21,
		icon: Danca2Icon,
	},
	{
		id: 22,
		icon: Crossfit2Icon,
	},
	{
		id: 23,
		icon: BikeIcon,
	},
];
