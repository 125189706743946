import styled from "styled-components";
import { w } from "../../styles/responsive";

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	padding-top: ${w(25)};
	overflow-x: hidden;
	border-radius: ${w(10)};

	background-color: #fff;
`;

export const FormContainer = styled.div`
	padding: ${w(0, 21, 0, 21)};
	flex: 1;
	display: grid;
	grid-template-areas:
		"file type name name name name"
		"file description description description description description"
		"file link link link link link"
		"highlighted categories categories categories categories categories";
	grid-template-columns: 244px 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 60px 85px 60px 60px;
	gap: ${w(10)};
`;

export const DropZoneContainer = styled.div`
	grid-area: file;

	background-color: #f7f7f7;
	padding: 8px;
	border-radius: 10px;
	/* 
	min-width: 230px;
	min-height: 230px;
	max-width: 244px;
	max-width: 244px; */
`;

export const ButtonContainer = styled.div`
	width: 100%;
	padding: 30px 20px 20px 25px;

	border-top: 1px solid #e5e5e5;

	display: flex;
	justify-content: space-between;

	div {
		display: flex;
		flex: 1;
		flex-direction: row;

		button {
			max-width: 127px;
		}

		+ div {
			justify-content: flex-end;

			button {
				max-width: 164px;

				+ button {
					max-width: 216px;
					margin-left: 30px;
				}
			}
		}
	}
`;

export const ToggleContainer = styled.div`
	grid-area: "highlighted";
	min-width: 230px;
	max-width: 244px;
	padding: 15px;
	padding-left: 7px;

	border: 0.5px solid rgba(0, 0, 0, 0.4);
	border-radius: 6px;

	font-size: 12px;
	font-weight: 500;
	line-height: 16.5px;
	color: #4b4b4b;

	display: flex;
	justify-content: space-between;
	align-items: center;

	label {
		display: flex;
		align-items: center;

		span {
			margin-right: 7px;
			color: #7c7c7c;
		}

		.react-toggle:not(.react-toggle--checked) .react-toggle-thumb {
			background-color: #fff;
			border: 1px solid rgba(59, 75, 162, 0.3);
		}

		.react-toggle:not(.react-toggle--checked) .react-toggle-track {
			background-color: #3b4ba24d;
		}

		.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
			background-color: #dadee3;
			filter: brightness(0.9);
		}

		.react-toggle--focus .react-toggle-thumb {
			box-shadow: 0px 0px 2px 3px #3b4ba24d;
		}

		.react-toggle--checked .react-toggle-thumb {
			left: 20px;
			border: none;
			border: 1px solid #596cd4;
		}

		.react-toggle-thumb {
			background-color: #596cd4;
		}

		.react-toggle-track {
			width: 40px;
			height: 12px;
			background-color: #dadee3;
		}

		.react-toggle-thumb {
			width: 20px;
			height: 20px;
			top: -4px;
			left: 0;
		}
	}
`;
