import React from "react";
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../../components/alert/alert.component";
import FormCatalogComponent, {
	DataCatalog,
} from "../../../components/formCatalog/formCatalog.component";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { apiAntiga } from "../../../services/api/axios";
import { Container, Title } from "./product.style";

type LastCreatedProduct = DataCatalog & {
	id: string;
};

// Create a client
const queryClient = new QueryClient();

const PAGE_TITLE = "Catálogo > Novo Produto";

function NewProductPage() {
	useTitle(PAGE_TITLE);

	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [lastCreatedProduct, setLastCreatedProduct] =
		React.useState<LastCreatedProduct>({} as LastCreatedProduct);
	const navigate = useNavigate();
	const location = useLocation();

	const { setPageDetails, setEditData } = React.useContext(GeneralContext);

	const currentRoute = location?.pathname.split("/")[1];

	async function handleCreateProduct(data: DataCatalog) {
		try {
			setIsLoading(true);
			const formData = new FormData();

			formData.append("name", data.name);
			formData.append("description", data.description);
			formData.append("image", data?.image as any);
			formData.append("url", data.url);
			formData.append("typeProduct", data.type);
			formData.append("isFeatured", data.featured as any);

			data.categories.forEach((category) => {
				formData.append("categories", category.value);
			});

			const response = await apiAntiga.post("/admin/products/create", formData);

			if (response.status === 201) {
				setShowAlert(true);
				setLastCreatedProduct(response.data);
			} else {
				alert("Erro ao criar o produto, tente novamente!");
			}
		} catch (error) {
			console.error(error);
			alert("Um erro inesperado aconteceu, tente novamente mais tarde!");
		} finally {
			setIsLoading(false);
		}
	}

	function onPressOkAlertSuccess() {
		setShowAlert(false);
		navigate("/catalog/details", {
			state: {
				routeFrom: currentRoute,
				id: lastCreatedProduct.id,
				name: lastCreatedProduct.name,
				item: lastCreatedProduct,
			},
		});

		setEditData?.({ ...lastCreatedProduct });
		setPageDetails?.({
			route: currentRoute,
			id: lastCreatedProduct?.id,
		});
	}

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>

			<FormCatalogComponent
				onSubmit={handleCreateProduct}
				isLoading={isLoading}
			/>

			{showAlert && (
				<Alert
					text="Produto adicionado com sucesso!"
					buttonText="Ok"
					clickFunction={onPressOkAlertSuccess}
				/>
			)}
		</Container>
	);
}

export default NewProductPage;
