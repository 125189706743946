import styled from "styled-components";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	height: fit-content;
	width: 100%;
	border-radius: ${ww(6)}px;
	box-shadow: ${w(0, 0, 3, 0)} #00000040;

	input,
	p {
		font-size: ${ww(8)}px;
	}
`;
