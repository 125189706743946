import styled, { css } from "styled-components";
import { w, ww } from "./responsive";
import { AboutCardProps } from "../types/details";
import { FlexBoxProps } from "../types/flexBox";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: ${w(11, 11, 0, 11)};
	width: 100%;
`;

export const Box = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const Title = styled.p`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 500;
	color: #4b4b4b;
	margin-right: auto;
`;

export const Section = styled.div<{ backgroundColor?: string }>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: ${w(25)};
`;

export const SectionTitle = styled.text<{ color?: "primary" | "secondary" }>`
	font-family: "Inter";
	font-size: ${ww(11)}px;
	font-weight: 700;
	color: ${(props) => (props.color === "primary" ? "#3b4ba2" : "#4b4b4b")};
	margin-bottom: ${ww(7)}px;
`;

export const SubTitle = styled.p`
	font-size: ${ww(9)}px;
	font-weight: 700;
	color: #3b4ba2;
	margin-bottom: ${ww(7)}px;
`;

export const Text = styled.p<AboutCardProps>`
	font-size: ${ww(8)}px;
	font-weight: 400;
	color: #7c7c7c;
	line-height: ${ww(11)}px;
	margin-bottom: ${ww(19)}px;
`;

export const SubContainer = styled.div<{
	direction: "row" | "column";
	justify?:
		| "flex-start"
		| "flex-end"
		| "center"
		| "space-between"
		| "space-around";
	wrap?: "wrap" | "nowrap";
	widthPerCent?: number;
	width?: number;
	margin?: number;
	padding?: number;
	gap?: number;
}>`
	width: ${({ widthPerCent }) => widthPerCent || 100}%;
	min-width: ${({ width }) => width || 0}px;
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify || "none"};
	flex-wrap: ${({ wrap }) => wrap || "nowrap"};
	background-color: #fff;
	border-radius: 10px;
	margin: ${({ margin }) => ww(margin || 0)}px;
	padding: ${({ padding }) => ww(padding || 0)}px;
	gap: ${({ gap }) => ww(gap || 0)}px;
`;

// export const SubContainer = styled.section`
// 	display: flex;
// 	flex-direction: column;
// 	width: 100%;
// 	padding: ${w(0, 14, 0, 3)};
// `;

export const CardContainer = styled.div<{ width?: number }>`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${ww(11)}px;
	width: ${({ width }) => width}%;
	/* border: 1px solid; */

	p {
		margin-bottom: 0px;
	}
`;

/* eslint-disable */
const cardStyle = css<AboutCardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${ww(17)}px;
	background-color: ${({ isCertification }) =>
		isCertification ? "#596CD4" : "#efefef"};
	padding: ${w(5, 9)};
	margin: ${w(0, 6, 6, 0)};
	border-radius: ${ww(3)}px;

	&:hover {
		cursor: ${({ isCertification }) =>
			isCertification ? "pointer" : "default"};
	}

	p {
		color: ${({ isCertification }) => (isCertification ? "#fff" : "#4b4b4b")};
	}
`;
/* eslint-enable */

export const Card = styled.div<{ widthPerCent?: number }>`
	${cardStyle}/* width: ${({ widthPerCent }) => widthPerCent}%; */
	/* width: 100%; */
	/* border: 1px solid; */
`;

export const Link = styled.a`
	${cardStyle}
	padding: ${w(2, 9)};
`;

export const ContainerTableButton = styled.div`
	display: flex;
	width: ${ww(111)}px;
	justify-content: center;

	gap: ${ww(11)}px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: ${ww(6)}px;
`;

export const Divisor = styled.div<{ marginBottom?: number }>`
	height: 1px;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: ${({ marginBottom }) => ww(marginBottom || 15)}px;
`;

export const Spacer = styled.div`
	height: ${ww(15)}px;
	width: 100%;
`;

// create a image component that will be used in the card component below and export it
export const Image = styled.img<{
	size?: number;
	borderRadius?: number;
}>`
	width: ${({ size }) => size || 1}px;
	height: ${({ size }) => size || 1}px;
	border-radius: ${({ borderRadius }) => borderRadius}px;
	margin-right: ${w(5)};
`;

export const FlexBox = styled.div<FlexBoxProps>`
	width: ${({ widthPerCent }) => widthPerCent || 100}%;
	height: ${({ height }) => height || "auto"};
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify || "none"};
	align-items: ${({ align }) => align || "none"};
	background-color: #fff;
	border-radius: 10px;
	margin: ${({ margin }) => ww(margin || 0)}px;
	padding: ${({ padding }) => ww(padding || 0)}px;
	gap: ${({ gap }) => ww(gap || 0)}px;
`;

// create a circled color component
export const Circle = styled.div<{
	color: string;
	size?: number;
}>`
	width: ${({ size }) => ww(size || 11)}px;
	height: ${({ size }) => ww(size || 11)}px;
	border-radius: ${({ size }) => ww(size || 11 * 2)}px;
	background-color: ${({ color }) => color};
	margin: ${ww(5)}px;
`;

export const TableText = styled.span<{
	fontSize?: number;
	fontWeight?: number;
	color?: string;
}>`
	font-size: ${({ fontSize }) => fontSize || "normal"}px;
	font-weight: ${({ fontWeight }) => fontWeight || "normal"};
	color: ${({ color }) => color || "#4b4b4b"};
`;
