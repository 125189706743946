import React from "react";

import { Route, Routes } from "react-router-dom";
import CategoryDetailsPage from "./details/index";
import CategoriesPage from "./category.component";

const CategoryIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<CategoriesPage />} />
			<Route path="details" element={<CategoryDetailsPage />} />
		</Routes>
	);
};

export default CategoryIndex;
