import styled from "styled-components";

import { ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background: #3b4ba2;
	height: ${ww(95)}px;
	width: 100%;
	padding: ${ww(6)}px;

	p {
		color: #fff;
	}
`;

export const DropZone = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed #fff;
	background: #3b4ba2;
	margin-top: ${ww(5)}px;
	height: 100%;
	width: 100%;

	&.active {
		border: 1px solid #fff;
	}

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

export const Thumbnail = styled.div`
	height: 60px;
	width: 60px;
	background: #fff;
	border-radius: ${ww(3)}px;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	transition: 0.2s ease-in-out;

	opacity: 0;

	&.active {
		opacity: 1;
	}
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	font-weight: 400;
	text-align: left;

	&.remove {
		display: none;
	}
`;

export const TextError = styled.p`
	font-size: ${ww(6)}px;
	font-weight: 400;
	text-align: left;
	margin-top: ${ww(3)}px;
	color: #ff6868 !important;
`;
