import styled from "styled-components";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
`;

export const Box = styled.div`
	/* height: 100vh; */
	height: ${ww(300)}px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(210)}px;
	padding: ${w(7, 17, 14, 17)};
	position: relative;
`;

export const SectionMessages = styled.div`
	height: auto;
	/* height: ${ww(100)}px; */
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: ${w(11)};

	border: 1px red;
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	text-align: center;
	font-weight: 400;
	color: #3b4ba2;

	word-break: break-all;
`;

export const UserNameButton = styled.button`
	cursor: "pointer";
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	text-align: left;
	color: #3b4ba2;
	margin-bottom: ${ww(6)}px;
`;

export const StatusContainer = styled.div`
	display: flex;
	width: ${ww(100)}px;
	height: ${ww(17)}px;
	padding: ${w(6, 0)};
	align-items: center;
	justify-content: center;
	background: #596cd4;
	margin-right: auto;
	margin-bottom: ${ww(9)}px;
	border-radius: ${ww(3)}px;

	p {
		font-size: ${ww(7)}px;
		font-weight: 400;
		color: #fff;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const Icon = styled(Close).attrs({
	width: w(14),
	height: w(14),
})`
	display: flex;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: ${ww(6)}px;
`;

export const Divisor = styled.div`
	height: 1px;
	width: calc(100% + ${ww(34)}px);
	transform: translateX(-${ww(17)}px);
	border-bottom: 1px solid #e5e5e5;
	margin: ${w(11, 0)};
`;
