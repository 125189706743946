import styled from "styled-components";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar@1.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: ${ww(46)}px;
	background: #f2f0f9;
	width: 100%;
	padding: ${w(6, 6, 0, 0)};
	border-bottom: 1px solid #fff;
`;

export const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: ${ww(6)}px;
`;

export const Text = styled.p`
	font-size: ${ww(6)}px;
	font-weight: 400;
	color: #6e6893;
	margin: ${w(3, 0)};
`;

export const CalendarIcon = styled(Calendar).attrs({
	width: w(14),
	height: w(14),
})``;

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(14),
	height: w(14),
})``;

export const StatusContainer = styled(SubContainer)`
	border-left: 2px solid #fff;
	padding-left: ${ww(6)}px;
`;

export const ButtonContainer = styled.div`
	width: ${ww(57)}px;
	margin-left: ${ww(6)}px;
	margin-bottom: -${ww(4)}px;
`;

export const Form = styled.form`
	display: flex;
	align-items: center;
`;
