import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import { AuthProps } from "../types/auth";

const RequireAuth: React.FC<AuthProps> = ({ children }) => {
	const { authenticated } = React.useContext(AuthContext);
	const location = useLocation();

	if (!authenticated) {
		return <Navigate to="/authentication" state={{ from: location }} replace />;
	}

	return children;
};

const AcessAuth: React.FC<AuthProps> = ({ children }) => {
	const { authenticated } = React.useContext(AuthContext);
	const location = useLocation();

	if (authenticated) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	return children;
};

export { AcessAuth, RequireAuth };
