import styled from "styled-components";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	min-height: ${ww(371)}px;
	max-height: 100%;
	width: 100%;
	border-radius: ${ww(6)}px;
	background: #fff;
	box-shadow: ${w(0, 0, 3, 0)} #00000040;
	padding-top: ${ww(6)}px;
	position: relative;

	input,
	p {
		font-size: ${ww(7)}px;
	}
`;
