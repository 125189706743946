import React from "react";
import { GeneralContext } from "../../../contexts/generalContext";
import { apiAntiga } from "../../../services/api/axios";
import { convertDate_yy_mm_dd } from "../../../utils/convertDate";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import Loading from "../../loading/loading.components";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	TextContainer,
	Title,
	Divisor,
	Ul,
	Li,
} from "./anamnese.styles";

const StudentAnamnese: React.FC = () => {
	const { pageDetails, setPageDetails } = React.useContext(GeneralContext);
	const [data, setData] = React.useState<any>();
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const { id } = pageDetails;

	React.useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await apiAntiga.get(`/adm-anamnesi/student/${id}`);
				const { newDate } = convertDate_yy_mm_dd(data.date);

				data.date = newDate;

				setIsLoading(false);
				setData(data);
			} catch (err) {
				setShowAlert(true);
				setIsLoading(false);
			}
		};

		getData();
	}, []);

	if (isLoading) {
		return (
			<Container>
				<Box>
					<Loading color={"#383838"} text="Buscando dados..." />
				</Box>
			</Container>
		);
	}

	if (!isLoading && showAlert) {
		return (
			<Alert
				text={"Esse usuário não informou nenhuma Anamnese."}
				buttonText="Ok"
				clickFunction={() => setPageDetails?.({})}
			/>
		);
	} else {
		return (
			<Container>
				<Box>
					<IconContainer onClick={() => setPageDetails?.(false)}>
						<Icon />
					</IconContainer>

					<Title>{data?.student?.name}</Title>
					<Text>Anamnese</Text>
					<Text>Realizada no dia {data?.date}</Text>
					<Divisor />
					<TextContainer>
						<Title>Peso:</Title> <Text>{`${data?.weight} Kg`}</Text>
					</TextContainer>
					<TextContainer>
						<Title>Altura:</Title> <Text>{`${data?.height} cm`}</Text>
					</TextContainer>
					<TextContainer flexColumn={true}>
						<Title>Frequência Cardíaca em Repouso:</Title>{" "}
						<Text>{`${data?.heart_rate} batidas a cada 10 segundos`}</Text>
					</TextContainer>
					<TextContainer flexColumn={true}>
						<Title>Objetivos:</Title>{" "}
						<Ul style={{ marginLeft: 25 }}>
							{data?.goals.split(",").map((element: string, index: number) => (
								<Li key={index}>
									<Text>{element}</Text>
								</Li>
							))}
						</Ul>
					</TextContainer>
					<TextContainer>
						<Title>Pais com sobrepeso:</Title>
						<Text>{data?.overweight_parents ? "Sim" : "Não"}</Text>
					</TextContainer>
					<TextContainer>
						<Title>Pratica atividades físicas:</Title>
						<Text>{data?.do_activities ? "Sim" : "Não"}</Text>
					</TextContainer>
					<TextContainer flexColumn={true}>
						<Title>Atividades físicas e por quanto tempo:</Title>
						<Text>{data?.activities_time}</Text>
					</TextContainer>
					<TextContainer flexColumn={true}>
						<Title>Sintomas ao realizar atividades:</Title>{" "}
						<Ul style={{ marginLeft: 25 }}>
							{data?.active_symptoms
								.split(",")
								.map((element: string, index: number) => (
									<Li key={index}>
										<Text>{element}</Text>
									</Li>
								))}
						</Ul>
					</TextContainer>
					<TextContainer>
						<Title>Faz dieta:</Title> <Text>{data?.diet ? "Sim" : "Não"}</Text>
					</TextContainer>
					<Divisor />

					<ButtonContainer>
						<Button
							title="Fechar"
							clickFunction={() => setPageDetails?.(false)}
						/>
					</ButtonContainer>
				</Box>
			</Container>
		);
	}
};

export default StudentAnamnese;
