import * as React from "react";
import { Routes, Route } from "react-router-dom";

import { AcessAuth, RequireAuth } from "./auth/RouteAuth";
import { AuthProvider } from "./contexts/authContext";
import GlobalStyles from "./GlobalStyles";
import Signin from "./components/auth/signIn/signIn.component";
import HomePage from "./pages/home/home.page";
import ForgotPassword from "./components/auth/forgotPassword/forgotPassword.component";
import DefineNewPassword from "./components/auth/defineNewPassword/defineNewPassword.component";
import { GeneralProvider } from "./contexts/generalContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
export const queryClient = new QueryClient();

export const App: React.FC = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<GeneralProvider>
					<GlobalStyles />
					<Routes>
						<Route
							path="/authentication"
							element={
								<AcessAuth>
									<Signin />
								</AcessAuth>
							}
						/>
						<Route
							path="/forgetPassword"
							element={
								<AcessAuth>
									<ForgotPassword />
								</AcessAuth>
							}
						/>
						<Route
							path="/defineNewPassword"
							element={
								<AcessAuth>
									<DefineNewPassword />
								</AcessAuth>
							}
						/>
						<Route
							path="/*"
							element={
								<RequireAuth>
									<HomePage />
								</RequireAuth>
							}
						/>
					</Routes>
					<ReactQueryDevtools />
				</GeneralProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default App;
