import styled from "styled-components";
import { DetailsProps } from "../../../types/details";
import { ReactComponent as HalfStar } from "../../../assets/icons/star-half.svg";
import { ReactComponent as FullStar } from "../../../assets/icons/star-full.svg";
import { ReactComponent as EmptyStar } from "../../../assets/icons/star-empty.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: ${ww(11)}px;
	width: 100%;
`;

export const Title = styled.p`
	font-size: ${ww(12)}px;
	font-weight: 700;
	color: #3b4ba2;
	margin-bottom: ${ww(7)}px;
`;

export const Text = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 400;
	color: #3b4ba2;
	line-height: ${ww(11)}px;
`;

export const SubContainer = styled.section<DetailsProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	width: 100%;
	height: 100%;
	align-items: ${({ onEnd }) => (onEnd ? "flex-end" : "flex-start")};
	padding-left: ${ww(3)}px;
`;

export const ImageContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	border-radius: ${ww(96)}px;
	position: relative;
	margin-bottom: ${ww(6)}px;
`;

export const Image = styled.img`
	width: ${ww(59)}px;
	height: ${ww(59)}px;
	border-radius: ${ww(96)}px;
	object-fit: cover;
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${ww(17)}px;
	background-color: #596cd4;
	padding: ${w(5, 9)};
	border-radius: ${ww(3)}px;
	position: absolute;
	transform: translateY(120%);
	left: ${ww(49)}px;

	p {
		color: #fff;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	width: ${ww(71)}px;
`;

export const SubButtonContainer = styled.div`
	display: flex;
	align-items: flex-end;
	height: 100%;

	& :nth-child(2) {
		margin-left: ${ww(11)}px;
	}
`;

export const StarContainer = styled.div`
	display: flex;
	width: ${ww(68)}px;
	align-items: center;
	margin-top: -${ww(5)}px;

	p {
		color: #7c7c7c;
		margin-left: ${ww(3)}px;
		margin-bottom: -2px;
	}
`;

export const FullStarIcon = styled(FullStar).attrs({
	width: w(8.2),
	height: w(8.2),
})``;

export const HalfStarIcon = styled(HalfStar).attrs({
	width: w(8.2),
	height: w(8.2),
})``;

export const EmptyStarIcon = styled(EmptyStar).attrs({
	width: w(8.2),
	height: w(8.2),
})``;
