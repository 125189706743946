import React from "react";
import { useForm } from "react-hook-form";

import { GeneralContext } from "../../../contexts/generalContext";
import { ww } from "../../../styles/responsive";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import InputComponent from "../../input/input.component";
import { validate } from "./validate-input";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	Title,
	Form,
	InputContainer,
	CancelContainer,
} from "../my-account.styles";

import { MyAccountInput } from "../../../types/input";
import { apiAntiga } from "../../../services/api/axios";
import { AuthContext } from "../../../contexts/authContext";
import { getAllAdmins } from "../../../hooks/usAdmins";

const MyAccountEditProfile: React.FC = () => {
	const { setPageDetails, setEditData, editData } =
		React.useContext(GeneralContext);

	const { setAdmin, admin } = React.useContext(AuthContext);

	const {
		register,
		getValues,
		formState: { errors },
		setError,
	} = useForm<MyAccountInput>();

	const [isLoading, setIsLoading] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);

	const { id, name: currentName, email: currentEmail } = editData;

	const handleOnFocusInput = (errorName: "name" | "email" | "confirmEmail") => {
		return () => setError(errorName, { type: "", message: "" });
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const name = getValues("name");
		const email = getValues("email");
		const confirmEmail = getValues("confirmEmail");

		const hasError = await validate(
			{ name, email, confirmEmail },
			currentName,
			currentEmail,
			setError,
		);

		if (hasError) {
			return;
		}

		setIsLoading(true);

		const data = {
			name,
			email,
		};

		const response = await apiAntiga.patch(`/admins/${id}/update`, data);

		const myNewAccountData: any = {
			...admin,
			name: data.name,
			email: data.email,
		};

		localStorage.setItem("@admin", myNewAccountData);

		setAdmin?.(myNewAccountData);

		if (response.status !== 200) return;

		setTimeout(() => {
			setShowAlert(true);
			setEditData?.({});
			setIsLoading(false);
		}, 2000);
	};

	function closeModal() {
		setShowAlert(false);
		setPageDetails?.(false);
	}

	return (
		<Container>
			<Box>
				<IconContainer onClick={closeModal}>
					<Icon />
				</IconContainer>

				<Title>Editando Perfil</Title>

				<Form onSubmit={onSubmit}>
					<InputContainer>
						<InputComponent
							register={register}
							required={true}
							value={currentName}
							onFocus={handleOnFocusInput("name")}
							name="name"
							type="text"
							placeholder="Nome"
							error={errors.name?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={currentEmail}
							onFocus={handleOnFocusInput("email")}
							name="email"
							type="text"
							placeholder="Email"
							error={errors.email?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={getValues("confirmEmail")}
							onFocus={handleOnFocusInput("confirmEmail")}
							name="confirmEmail"
							type="text"
							placeholder="Confirmar Email"
							error={errors.confirmEmail?.message}
							width={ww(164)}
							isSecondary={true}
						/>
					</InputContainer>

					<ButtonContainer>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</ButtonContainer>
				</Form>
				<CancelContainer>
					<Text onClick={closeModal}>Cancelar</Text>
				</CancelContainer>
			</Box>
			{showAlert && (
				<Alert
					text="Alterações realizadas com sucesso."
					buttonText="Ok"
					clickFunction={closeModal}
				/>
			)}
		</Container>
	);
};

export default MyAccountEditProfile;
