import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { queryClient } from "../../App";
import Alert from "../../components/alert/alert.component";
import Button from "../../components/button/button.components";
import { FieldInputSystem } from "../../components/system/Input";
import { useTitle } from "../../hooks/useTitle";
import {
	getSystemFees,
	registerSystemFees,
} from "../../services/system.service";
import * as S from "./system.styles";

const PAGE_TITLE = "Parâmetros do Sistema";

type FieldsSystemParameters = {
	studentFee: number;
	teacherFee: number;
};

export const SystemPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const [alert, setAlert] = useState({ success: false });

	const { data } = useQuery("system-fee", getSystemFees, {
		staleTime: 1 * 60 * 1000, // 1 minute
	});

	React.useEffect(() => {
		if (!data) return;

		setValue("studentFee", data.studentFee);
		setValue("teacherFee", data.teacherFee);
	}, [data]);

	const { control, formState, handleSubmit, setValue } =
		useForm<FieldsSystemParameters>({
			defaultValues: {
				studentFee: data?.studentFee ?? 0,
				teacherFee: data?.teacherFee ?? 0,
			},
		});

	async function handleUpdateRate(values: FieldsSystemParameters) {
		const data = {
			studentFee: Number(values.studentFee),
			teacherFee: Number(values.teacherFee),
		};

		const isSuccess = await registerSystemFees(data);

		if (isSuccess) {
			queryClient.invalidateQueries("system-fee");
		}

		setAlert((prevState) => ({ ...prevState, success: isSuccess }));
	}

	return (
		<S.Container>
			<S.Title>{PAGE_TITLE}</S.Title>

			{alert.success && (
				<Alert
					type="alert"
					text="Parâmetros salvos com sucesso."
					buttonText="Ok"
					clickFunction={() => {
						setAlert((prevState) => ({ ...prevState, success: false }));
					}}
				/>
			)}

			<S.Card onSubmit={handleSubmit(handleUpdateRate)}>
				<div>
					<S.Label>Taxa % de cobrança do Aluno</S.Label>
					<Controller
						control={control}
						name="studentFee"
						rules={{
							required: {
								value: true,
								message: "Atenção! Campo não pode ficar em branco.",
							},
							min: {
								value: 0,
								message: " Atenção! Valor fora do padrão (0 a 100%).",
							},
							max: {
								value: 100,
								message: " Atenção! Valor fora do padrão (0 a 100%).",
							},
						}}
						render={({ field }) => (
							<FieldInputSystem
								type="number"
								label="Porcentagem (%)"
								error={formState.errors.studentFee}
								{...field}
							/>
						)}
					/>
				</div>

				<div>
					<S.Label>Taxa % de cobrança do Professor</S.Label>
					<Controller
						control={control}
						name="teacherFee"
						rules={{
							required: {
								value: true,
								message: "Atenção! Campo não pode ficar em branco.",
							},
							min: {
								value: 0,
								message: " Atenção! Valor fora do padrão (0 a 100%).",
							},
							max: {
								value: 100,
								message: " Atenção! Valor fora do padrão (0 a 100%).",
							},
						}}
						render={({ field }) => (
							<FieldInputSystem
								type="number"
								label="Porcentagem (%)"
								error={formState.errors.teacherFee}
								{...field}
							/>
						)}
					/>
				</div>

				<S.ButtonContainer>
					<Button type="submit" title="Salvar" />
				</S.ButtonContainer>
			</S.Card>
		</S.Container>
	);
};
