import styled from "styled-components";
import { ReactComponent as ArrowRight } from "../../../assets/icons/chevron_big_right.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	height: 100%;
	max-height: 100vh;
	width: ${ww(218)}px;
	align-items: center;
	justify-content: flex-start;
	padding: ${w(3, 11)};

	&:hover {
		cursor: pointer;
		background: rgba(73, 93, 204, 0.1);
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	margin-right: ${ww(6)}px;
`;

export const Image = styled.img`
	width: ${ww(23)}px;
	height: ${ww(23)}px;
	border-radius: ${ww(23)}px;
	object-fit: cover;
`;

export const TextContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
export const Title = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 700;
	color: #000;
	margin: 1px 0px;
	text-transform: capitalize;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.54);
`;

export const IconArrowRight = styled(ArrowRight).attrs({
	width: w(11),
	height: w(11),
})`
	display: flex;
`;
