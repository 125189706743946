import { useEffect } from "react";

export function useTitle(title: string) {
	useEffect(() => {
		const baseTitle = `${title} | Greeko`;

		const prevTitle = document.title;
		document.title = baseTitle;

		return () => {
			document.title = prevTitle;
		};
	});
}
