import { TableTitleModel } from "../models/table";

export const mockupTitle: TableTitleModel[] = [
	{ title: "Nome do Professor", icon: true },
	{ title: "Email" },
	{ title: "Data Cadastro" },
	{ title: "País de Atuação" },
	{ title: "Tipo" },
	{ title: "status", icon: true },
];

export const mockupDocumentationTitle = [
	{ title: "Data e Hora" },
	{ title: "Tipo de documento" },
	{ title: "Documento Enviado" },
	{ title: "Status" },
	{ title: "Ação" },
];

export const mockedDocumentsName = [
	"Foto . jpeg",
	"Documento . pdf",
	"Licença 01 . pdf",
	"Licença 02 . pdf",
];

export const mockupData = [
	{
		id: 1,
		name: "Luhio Geowu",
		email: "luhio.geowu@gmail.com",
		registration_date: "12/01/2022",
		country_of_operation: "Brasil",
		acceptance_type: "Novo Cadastro",
		status: "Em Aprovação",
	},
	{
		id: 2,
		name: "Duhio Geowu",
		email: "duhio.geowu@gmail.com",
		registration_date: "02/01/2022",
		country_of_operation: "Brasil",
		acceptance_type: "Nova Categoria",
		status: "Em Aprovação",
	},
	{
		id: 3,
		name: "Tuhio Geowu",
		email: "tuhio.geowu@gmail.com",
		registration_date: "13/01/2022",
		country_of_operation: "Brasil",
		acceptance_type: "Mudança de País",
		status: "Reprovado",
	},
	{
		id: 4,
		name: "Xuhio Geowu",
		email: "xuhio.geowu@gmail.com",
		registration_date: "14/01/2022",
		country_of_operation: "Brasil",
		acceptance_type: "Novo Cadastro",
		status: "Em Aprovação",
	},
];

// create a array of mocked documentation and export it (ex:[{timestamp: "18/07/2022 - 12h34", documentation_type: "Foto de Perfil", file_uploaded: "foto.jpg", status: "Aprovado"}])
export const mockupDocumentation = [
	{
		id: "1",
		timestamp: "18/07/2022 - 12h34",
		documentation_type: "Foto de Perfil",
		file_uploaded: "foto.jpg",
		status: "Aprovado",
	},
	{
		id: "2",
		timestamp: "18/07/2022 - 12h34",
		documentation_type: "Foto de Perfil",
		file_uploaded: "foto.jpg",
		status: "Recusado",
	},
	{
		id: "3",
		timestamp: "18/07/2022 - 12h34",
		documentation_type: "Foto de Perfil",
		file_uploaded: "foto.jpg",
		status: "Em análise",
	},
	{
		id: "4",
		timestamp: "18/07/2022 - 12h34",
		documentation_type: "Foto de Perfil",
		file_uploaded: "foto.jpg",
		status: "Em análise",
	},
];

export const mockupAcceptanceCategories = [
	{ label: "Musculação", value: 0 },
	{ label: "Fitness", value: 1 },
	{ label: "Crossfit", value: 2 },
	{ label: "Yoga", value: 3 },
	{ label: "Pilates", value: 4 },
];
