import React from "react";

import { apiAntiga } from "../services/api/axios";

type Icon = {
	id: string;
	imageUrl: string;
	order: number;
	createdAt?: Date;
	updatedAt?: Date;
	Icon: any;
};

interface IconComponentProps {
	url: string;
}

export function IconComponent({ url }: IconComponentProps) {
	const [rawSVG, setRawSVG] = React.useState("");

	fetch(url)
		.then((res) => res.text())
		.then((text) => {
			setRawSVG(text);
		});

	if (!rawSVG) {
		return <></>;
	}

	return <div dangerouslySetInnerHTML={{ __html: rawSVG }} />;
}

export function useIcons() {
	const [icons, setIcons] = React.useState<Icon[]>([]);

	function fetchIcons() {
		apiAntiga
			.get<Icon[]>("/icons")
			.then((response) => {
				onSuccess(response.data);
			})
			.catch((err) => {
				console.error("Error fetching icons", err);
			});
	}

	function onSuccess(data: Icon[]) {
		const newData = data.map((item) => {
			return {
				...item,
				Icon: React.memo(IconComponent),
			};
		});
		setIcons(newData);
	}

	React.useEffect(() => {
		fetchIcons();
	}, []);

	return {
		data: icons,
		refetch: fetchIcons,
	};
}
