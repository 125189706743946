import React from "react";
import { aboutData } from "../../../mockup/details";
import { apiAntiga } from "../../../services/api/axios";
import { AboutProfileProps } from "../../../types/details";
import {
	Container,
	CardContainer,
	SubContainer,
	Title,
	Text,
	Divisor,
	Card,
	Box,
	Link,
} from "./about.styles";

const AboutComponent: React.FC<AboutProfileProps> = ({ id }) => {
	const [data, setData] = React.useState<any>();
	const [formatedCities, setFormatedCities] = React.useState<any>();

	React.useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await apiAntiga.get(
					`/adm-get-teacher?teacherId=${id}`,
				);
				const list: string[] = [];
				data?.cities[0]?.cities
					?.split(",")
					.forEach((item: string, index: number) => {
						// get the item that is above the third comma
						if (index % 3 === 0) {
							list.push(item);
						}
					});
				setFormatedCities(list);

				setData(data);
			} catch (error) {
				//
			}
		};

		getData();
	}, []);

	return (
		<Container>
			<Divisor />
			<Box>
				<SubContainer>
					<Title>Sobre</Title>
					<Text>{data?.bio}</Text>
				</SubContainer>
				<SubContainer>
					<Title>Cidades</Title>
					<CardContainer>
						{formatedCities?.map((item: string, index: string) => (
							<Card key={index}>
								<Text>{item}</Text>
							</Card>
						))}
					</CardContainer>
					<Title>Modalidades</Title>
					<CardContainer>
						{data?.modalities?.split(",").map((item: string, index: string) => (
							<Card key={index}>
								<Text>{item}</Text>
							</Card>
						))}
					</CardContainer>
				</SubContainer>
				<SubContainer>
					<Title>Formação e Cursos</Title>
					<Text>{data?.courses}</Text>
					<Title>Certificados (imagens)</Title>
					<CardContainer>
						{data?.certificate?.map((item: any, index: string) => (
							<Link
								key={index}
								isCertification={true}
								target="_blank"
								rel="noreferrer"
								href={item.certificateImage}
							>
								<Text isCertification={true}>{item.description}</Text>
							</Link>
						))}
					</CardContainer>
				</SubContainer>
			</Box>
		</Container>
	);
};

export default AboutComponent;
