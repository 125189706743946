import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CustomizedState } from "../../../types/details";
import { Container, SubContainer, Section } from "./acceptance.styles";
import { InputTextComponent } from "../../../components/InputText";
import TableDocuments from "../../../components/tableDocuments";
import { mockupDocumentationTitle } from "../../../mockup/acceptance";
import Button from "../../../components/button/button.components";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
	CategoryOption,
	FieldsCatalog,
} from "../../../components/formCatalog/formCatalog.component";
import { apiAntiga } from "../../../services/api/axios";
import AlertConfirm from "../../../components/alertConfirm/alertConfirm.component";
import Alert from "../../../components/alert/alert.component";
import Modal from "../../../components/modal/modal.component";
import mask from "../../../components/input/mask";
import { formatCity } from "../../../utils/formatText";
import { acceptanceStatus } from "../../../constants/acceptance";
import { acceptanceCustomStylesSelect } from "./customStylesSelect";
import {
	Title,
	SectionTitle,
	SubTitle,
	Card,
	CardContainer,
	Text,
	Image,
} from "../../../styles/components";
import { formatDocumentTypeText, formatGenderText } from "./formatText";
import {
	IApprovalDetails,
	ICitiesOfOPeration,
	IApprovalDocumentation,
} from "../../../types/acceptanceDetails";
import { loadCategories } from "../../../hooks/useCategories";
import { format } from "date-fns";
import { getFlagCountry } from "../../../data/countries";

const approvalDetailsState = {
	id: "",
	user: {
		id: "",
		name: "",
		email: "",
		gender: "",
		phoneNumber: "",
		citiesOfOperation: [],
		countryOfOperationRelation: {
			cca2: "",
			name: "",
		},
	},
	approvalDocumentation: [],
} as IApprovalDetails;

export const AcceptanceDetailsPage = () => {
	const navigate = useNavigate();
	const [data, setData] = React.useState(approvalDetailsState);
	const location = useLocation();
	const { id, item } = location.state as CustomizedState<any>;

	const acceptanceWasApprovedOrRejected =
		item.status === "Aprovado" || item.status === "Reprovado";

	const { control, getValues } = useForm<FieldsCatalog>();

	const [showModalDetails, setShowModalDetails] = React.useState(false);
	const [showModalRefuseDocument, setShowModalRefuseDocument] =
		React.useState(false);
	const [showModalApproveDocument, setShowModalApproveDocument] =
		React.useState(false);

	const [showModalApprove, setShowModalApprove] = React.useState(false);
	const [showModalDisapprove, setShowModalDisapprove] = React.useState(false);

	const [showAlertSuccessRevision, setShowAlertSuccessRevision] =
		React.useState(false);
	const [showAlertSuccessAprovation, setShowAlertSuccessAprovation] =
		React.useState(false);
	const [showAlertSuccessJustification, setShowAlertSuccessJustification] =
		React.useState(false);

	const [isLoading, setIsLoading] = React.useState(false);

	const [clickedItemId, setClickItemId] = React.useState("");
	const [categoryWasSelected, setCategoryWasSelected] = React.useState(false);

	const [justificationText, setJustificationText] = React.useState("");
	const [detailsText, setDetailsText] = React.useState("");

	React.useEffect(() => {
		const getData = async () => {
			try {
				setIsLoading(true);
				const { data } = await apiAntiga.get(`admin/approvals/${id}`);
				const approvalDetails = data.value;
				const formattedData = {
					...approvalDetails,
					approvalDocumentation: approvalDetails.approval_document.map(
						(i: IApprovalDocumentation) => {
							return {
								id: i.id,
								createdAt: format(new Date(i.createdAt), "yyyy/MM/dd HH'h'mm"),
								documentType: formatDocumentTypeText(i.document.type),
								documentFile: i.document.file,
								justificationDetails: "",
								status: i.status,
							};
						},
					),
				};
				setData(formattedData);
			} catch (error) {
				//
			} finally {
				setIsLoading(false);
			}
		};

		getData();
	}, []);

	const approvalDocuments = data.approvalDocumentation;

	const allDocumentsWasApproved = approvalDocuments.every(
		(i: any) => i.status === acceptanceStatus.approved,
	);

	function updateApprovalDocument(newStatus: string, justification?: string) {
		return data.approvalDocumentation.map((i: any) => {
			if (i.id === clickedItemId) {
				return {
					...i,
					status: newStatus,
					justificationDetails: justification || "",
				};
			}
			return i;
		});
	}

	function handleClickApproveDocument() {
		setShowModalApproveDocument(false);

		setData({
			...data,
			approvalDocumentation: updateApprovalDocument(acceptanceStatus.approved),
		});
	}

	function handleClickRefuseDocument() {
		setShowModalRefuseDocument(false);

		setData({
			...data,
			approvalDocumentation: updateApprovalDocument(
				acceptanceStatus.rejected,
				detailsText,
			),
		});

		setShowAlertSuccessRevision(true);
	}

	async function handleClickApproveNewRegister() {
		setShowModalApprove(false);
		const acceptanceData = {
			documents: [{ status: "APPROVED" }],
			categories: getValues("categories"),
		};
		try {
			await apiAntiga.post(`admin/approvals/${id}/approval`, acceptanceData);
			setShowAlertSuccessAprovation(true);
		} catch (error) {
			console.error("Erro ao aprovar o novo cadatro: ", error);
		}
	}

	async function handleClickDisapproveNewRegister() {
		setShowModalDisapprove(false);

		const repprovalData = {
			documents: data.approvalDocumentation.map((i: any) => {
				return {
					id: i.id,
					status: i.status,
					description: i.justificationDetails,
				};
			}),
			observation: justificationText,
		};

		try {
			await apiAntiga.post(`admin/approvals/${id}/rejection`, repprovalData);
			setShowAlertSuccessJustification(true);
		} catch (error) {
			//
		}
	}

	return (
		<Container>
			<Title>Aprovações &gt; {item.type}</Title>

			{showModalRefuseDocument && (
				<Modal
					hideIcon
					type="danger"
					title="Deseja recusar esse documento?"
					text="Escreva sua justificativa"
					subText="(será enviada para o Personal):"
					onChangeText={(e) => setDetailsText(e)}
					buttonText="Recomendar Revisão"
					clickFunction={() => setShowModalRefuseDocument(false)}
					cautionFunction={handleClickRefuseDocument}
				/>
			)}

			{showModalDetails && (
				<Modal
					hideIcon
					title="Esse documento foi recusado"
					text="Acompanhe a justificativa"
					subText="(enviada para o Personal):"
					textDetails={detailsText}
					buttonText="Fechar"
					clickFunction={() => setShowModalDetails(false)}
				/>
			)}

			{showModalApproveDocument && (
				<AlertConfirm
					type="info"
					text="Deseja realmente aprovar este documento?"
					buttonText1="Sim, Aprovar"
					buttonText2="Fechar"
					cancelFunction={() => setShowModalApproveDocument(false)}
					confirmFunction={handleClickApproveDocument}
					isLoading={isLoading}
				/>
			)}

			{showModalApprove && (
				<AlertConfirm
					type="info"
					text="Deseja realmente aprovar o cadastro desse usuário?"
					buttonText1="Sim, Aprovar"
					buttonText2="Fechar"
					cancelFunction={() => setShowModalApprove(false)}
					confirmFunction={handleClickApproveNewRegister}
					isLoading={isLoading}
				/>
			)}

			{showModalDisapprove && (
				<Modal
					hideIcon
					type="danger"
					title="Qual o motivo da reprova?"
					text="Escreva sua justificativa"
					onChangeText={(e) => setJustificationText(e)}
					subText="(será enviada para o Personal):"
					buttonText="Enviar Justificativa"
					clickFunction={() => setShowModalDisapprove(false)}
					cautionFunction={handleClickDisapproveNewRegister}
				/>
			)}

			{showAlertSuccessRevision && (
				<Alert
					text={"Revisão enviada com sucesso."}
					buttonText="Ok"
					clickFunction={() => setShowAlertSuccessRevision(false)}
				/>
			)}

			{showAlertSuccessAprovation && (
				<Alert
					text={"Aprovação de cadastro realizada com sucesso."}
					buttonText="Ok"
					clickFunction={() => {
						setShowAlertSuccessAprovation(false);
						navigate("/acceptance");
					}}
				/>
			)}
			{showAlertSuccessJustification && (
				<Alert
					text={"Cadastro reprovado."}
					buttonText="Ok"
					clickFunction={() => {
						setShowAlertSuccessJustification(false);
						navigate("/acceptance");
					}}
				/>
			)}

			<Section>
				<SectionTitle>Informações Pessoais</SectionTitle>

				<SubContainer direction="column" padding={5}>
					<SubContainer direction="row">
						<SubContainer direction="column">
							<SubContainer direction="row">
								<InputTextComponent label="Nome" value={data.user.name} />
							</SubContainer>
							<SubContainer direction="row">
								<InputTextComponent
									label="Gênero"
									value={formatGenderText(data.user.gender)}
								/>
								<InputTextComponent
									label="Celular"
									value={`${
										mask(data.user.phoneNumber, "phone") || "Não Informado"
									} `}
								>
									{/* <Image
										src={require("../../../assets/images/brasil.png")}
										size={25}
									/> */}
								</InputTextComponent>
							</SubContainer>
						</SubContainer>
						<SubContainer direction="column">
							<SubContainer direction="row">
								<InputTextComponent label="Email" value={data.user.email} />
							</SubContainer>
						</SubContainer>
					</SubContainer>
					<SubContainer direction="row" widthPerCent={50} margin={10}>
						<SubContainer direction="column">
							<SubTitle>País de Atuação</SubTitle>
							<CardContainer>
								<Card>
									<Image
										src={getFlagCountry(
											data.user.countryOfOperationRelation.cca2 as any,
										)}
										size={25}
									/>
									<Text>{data.user.countryOfOperationRelation.cca2}</Text>
								</Card>
							</CardContainer>
						</SubContainer>
						<SubContainer direction="column">
							<SubTitle>Cidades de Atuação</SubTitle>
							<CardContainer width={250}>
								<SubContainer direction="row" wrap="wrap" width={100}>
									{data.user.citiesOfOperation.map(
										(city: ICitiesOfOPeration) => (
											<Card key={city.placeId}>
												<Text>{formatCity(city.name, false, true)}</Text>
											</Card>
										),
									)}
								</SubContainer>
							</CardContainer>
						</SubContainer>
					</SubContainer>
				</SubContainer>
			</Section>

			<Section>
				<SectionTitle>Documentos Enviados</SectionTitle>
				<SubContainer direction="row" padding={11}>
					<TableDocuments
						titleData={mockupDocumentationTitle}
						data={approvalDocuments}
						onClickApproveButton={(itemId: string) => {
							setClickItemId(itemId);
							setShowModalApproveDocument(true);
						}}
						onClickDisapproveButton={(itemId: string) => {
							setClickItemId(itemId);
							setShowModalRefuseDocument(true);
						}}
						onClickDetailsButton={(itemId: string) => {
							setClickItemId(itemId);
							setShowModalDetails(true);
						}}
					/>
				</SubContainer>
			</Section>

			<Section>
				<SectionTitle>Habilitar Categorias</SectionTitle>
				<SubContainer direction="row" padding={11}>
					<div style={{ gridArea: "categories" }}>
						<Controller
							control={control}
							name="categories"
							defaultValue={[]}
							rules={{ required: true }}
							render={({ field }) => {
								return (
									<AsyncSelect
										isMulti
										cacheOptions
										defaultOptions
										isDisabled={acceptanceWasApprovedOrRejected}
										defaultValue={[]}
										placeholder="Categorias"
										styles={acceptanceCustomStylesSelect}
										loadOptions={loadCategories}
										name={field.name}
										onChange={(value: any) => {
											return field.onChange(
												value.map((v: CategoryOption) => ({ id: v.value })),
												setCategoryWasSelected(value.length > 0 ? true : false),
											);
										}}
										ref={field.ref}
									/>
								);
							}}
						/>
					</div>
				</SubContainer>
			</Section>

			<SubContainer direction="row" justify="flex-end" padding={11}>
				<SubContainer direction="row" widthPerCent={50} gap={25}>
					<Button
						title="Reprovar"
						customColor="#FF6868"
						isDisabled={acceptanceWasApprovedOrRejected}
						clickFunction={() => setShowModalDisapprove(true)}
					/>
					<Button
						isDisabled={
							allDocumentsWasApproved && categoryWasSelected ? false : true
						}
						title="Aprovar"
						customColor="#00A8E8"
						clickFunction={() =>
							!acceptanceWasApprovedOrRejected && setShowModalApprove(true)
						}
					/>
				</SubContainer>
			</SubContainer>
		</Container>
	);
};
