import React from "react";

import { Route, Routes } from "react-router-dom";
import { NewProductPage } from "./product";
import { CatalogPage } from "./main/catalog.component";
import EditProductPage from "./product/editProduct.page";

const CatalogIndex: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<CatalogPage />} />
			<Route path="/new" element={<NewProductPage />} />
			<Route path="/details" element={<EditProductPage />} />
		</Routes>
	);
};

export default CatalogIndex;
