import format from "date-fns/format";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import Table from "../../components/table";
import { GeneralContext } from "../../contexts/generalContext";
import { useTitle } from "../../hooks/useTitle";
import { mockupTitle } from "../../mockup/admin-users";
import { apiAntiga } from "../../services/api/axios";
import { Pagination } from "../../types/requestPagination";

import { Container, Title } from "./admin-users.styles";

interface IAdminUser {
	id: string;
	name: string;
	email: string;
	lastAccess: string;
	createdAt: string;
	status: boolean;
}
interface IGetAdimUsersResponse {
	items: IAdminUser[];
	meta: Pagination;
}

const PAGE_TITLE = "Administradores";

const AdminUsersPage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		currentPage,
		setCurrentPage,
		itemsPage,
		setItemsPage,
		searchFilters,
		setSearchFilters,
		setTotalItems,
	} = React.useContext(GeneralContext);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const queryClient = useQueryClient();

	const { searchInput = "" } = searchFilters;

	const { data } = useQuery(
		["admin-users", searchFilters, currentPage, itemsPage],
		async () => {
			const response = await apiAntiga.get<IGetAdimUsersResponse>("/admins", {
				params: {
					sortBy: "email",
					page: currentPage,
					limit: itemsPage,
					search: searchInput,
				},
			});

			const formattedData = response.data.items.map((item: IAdminUser) => {
				return {
					id: item.id,
					name: item.name,
					email: item.email,
					lastAccess: item.lastAccess
						? format(new Date(item.lastAccess), "dd/MM/yyyy HH:mm")
						: "-",
					// lastAccess: format(new Date(item.lastAccess), "dd/MM/yyyy HH:mm"),
					createdAt: format(new Date(item.createdAt), "dd/MM/yyyy"),
					status: item.status ? "Ativo" : "Inativo",
				};
			});

			setTotalItems?.(response.data.meta.totalItems);
			return formattedData;
		},
	);

	const setData = (newData: any) => {
		queryClient.setQueryData("admin-users", () => {
			return {
				data: {
					...newData,
				},
			};
		});
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle!}
				data={data!}
				setData={setData}
				hasActions={false}
				hasPageDetails={false}
				statusData={[
					{ title: "Ativo", value: "Ativo" },
					{ title: "Inativo", value: "Inativo" },
				]}
			/>
		</Container>
	);
};

export default AdminUsersPage;
