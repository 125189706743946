import styled from "styled-components";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;
`;

export const Title = styled.p`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 500;
	color: #4b4b4b;
	margin-right: auto;
`;
