import { apiAntiga } from "./api/axios";

type SystemFee = {
	studentFee: number;
	teacherFee: number;
};

export async function getSystemFees(): Promise<SystemFee> {
	try {
		const response = await apiAntiga.get(
			"admin/app-fee/1232s23er423e32s23e23s",
		);
		return response.data;
	} catch {
		console.error("Error getting system fee");
		return {
			studentFee: 0,
			teacherFee: 0,
		};
	}
}

export async function registerSystemFees(data: SystemFee): Promise<boolean> {
	try {
		const response = await apiAntiga.patch(
			"admin/app-fee/1232s23er423e32s23e23s/update",
			data,
		);

		if ([200, 201].includes(response.status)) {
			return true;
		}

		return false;
	} catch {
		console.error("Error updating system fee");
		return false;
	}
}
