import { addDays, format, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import React from "react";
import { useQuery } from "react-query";
import BarChartDashboard, {
	DataChartLastWeekStudents,
} from "../../components/dashboard/chart-bar/chart-bar.component";
import ChartDashboard from "../../components/dashboard/chart-line/chart-line.component";
import ProfessorAceptance from "../../components/dashboard/professor-acceptance";
import TopCards from "../../components/dashboard/top-cards/top-cards.components";
import { useTitle } from "../../hooks/useTitle";
import {
	getDashboardData,
	HistoryStudent,
} from "../../services/dashboard.service";
import {
	Container,
	Title,
	SubContainer,
	FirstMenu,
	SecondMenu,
} from "./dashboard.styles";

const PAGE_TITLE = "Dashboard";

const DashboardPage: React.FC = () => {
	useTitle(PAGE_TITLE);

	const { data } = useQuery("dashboard-data", getDashboardData, {
		staleTime: 60 * 3 * 1000, // 3 minutes
	});

	if (data === undefined) {
		return null;
	}

	function formatStudentDays(
		lastStudents: HistoryStudent[],
	): DataChartLastWeekStudents[] {
		const data = lastStudents;

		if (data.length < 7) {
			for (let i = data.length; i <= 6; i++) {
				if (i === 0) {
					const lastSevenDayDate = subDays(new Date(), 7);
					const formattedDate = format(lastSevenDayDate, "dd/MM/yyyy");

					data.push({ day_month_year: formattedDate, quantity: 0 });
				} else {
					const [lastDay, lastMonth, lastYear] = data[i - 1].day_month_year
						.split("/")
						.map(Number);

					const lastDate = new Date(lastYear, lastMonth - 1, lastDay);
					const dateAddedOneDay = addDays(lastDate, 1);
					const newDate = format(dateAddedOneDay, "dd/MM/yyyy");

					data.push({ day_month_year: newDate, quantity: 0 });
				}
			}
		}

		const formattedData: DataChartLastWeekStudents[] = data.map((item) => {
			const [day, month, year] = item.day_month_year.split("/").map(Number);

			const formattedItem = {
				name: format(new Date(year, month - 1, day), "EEEEEE", {
					locale: ptBR,
				}).toLocaleUpperCase(),
				alunos: item.quantity,
			};

			return formattedItem;
		});

		return formattedData;
	}

	const formattedClassesChart =
		data.historyClasses.map((item) => {
			const [month, year] = item.month_year.split("/").map(Number);

			const formattedItem = {
				name: format(new Date(year, month - 1, 1), "LLL", {
					locale: ptBR,
				}).toLocaleUpperCase(),
				Alunos: item.classes_quantity,
			};

			return formattedItem;
		}) || [];

	const formattedStudentsChart = formatStudentDays(data.historyStudents);

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<SubContainer>
				<FirstMenu>
					<TopCards
						classesHeld={data?.classesHeld ?? 0 ?? 0}
						totalStudents={data?.totalStudents ?? 0}
						totalTeachers={data?.totalTeachers ?? 0}
						scheduledClasses={data?.scheduledClasses ?? 0}
					/>
					<ChartDashboard data={formattedClassesChart} />
				</FirstMenu>
				<SecondMenu>
					<ProfessorAceptance
						teachers={data?.approvals?.slice(0, 4).map((approval) => ({
							approvalId: approval.id,
							type: approval.type ?? "ACCOUNT_ACTIVATION",
							id: approval.user.id,
							name: approval.user.name,
							location: approval.user.citiesOfOperation[0]?.name,
							image: approval.user.document[0]?.file,
						}))}
					/>
					<BarChartDashboard data={formattedStudentsChart} />
				</SecondMenu>
			</SubContainer>
		</Container>
	);
};

export default DashboardPage;
