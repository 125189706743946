import { FormValues } from "../../../types/form";

export const validate = (value: FormValues, setError: any) => {
	const { modality, minPrice } = value;

	const passError = {
		modality: false,
		minPrice: false,
	};

	if (typeof modality === "string") {
		if (!value.modality?.length) {
			passError.modality = true;
			setError("modality", {
				type: "required",
				message: "Informe uma modalidade.",
			});
		} else if (value.modality?.length < 3 || value.modality?.length > 20) {
			passError.modality = true;
			setError("modality", {
				type: "required",
				message: "Inform uma modalidade válida entre 3 e 20 caracteres",
			});
		}
	}

	if (typeof minPrice === "string") {
		if (!value.minPrice?.length) {
			passError.minPrice = true;
			setError("minPrice", {
				type: "required",
				message: "Informe uma valor.",
			});
		}
	}

	return passError.modality || passError.minPrice;
};
