import { useState } from "react";

const useModal = () => {
	const [text, setText] = useState("");
	const [isShowing, setIsShowing] = useState(false);

	const toggle = () => setIsShowing(!isShowing);

	const open = (msg = "") => {
		setIsShowing(true);
		if (msg) setText(msg);
	};

	const close = () => {
		setIsShowing(false);
		setText("");
	};

	return {
		isShowing,
		text,
		toggle,
		open,
		close,
	};
};

export default useModal;
