import styled from "styled-components";

import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Caution } from "../../assets/icons/caution.svg";
import { ReactComponent as Danger } from "../../assets/icons/info.svg";

import { AlertStyleProps } from "../../types/alert";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #000000bb;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999;
`;

export const Box = styled.div<AlertStyleProps>`
	height: ${ww(100)};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* justify-content: center; */
	border: 1px solid ${({ type }) => type ?? "#64bc47"};
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(220)}px;
	padding: ${w(7, 20, 14, 20)};
	/* text-align: center; */
`;

export const IconContainer = styled.div`
	margin-bottom: ${ww(10)}px;
`;

export const Icon = styled(Check).attrs(({ width, height }) => ({
	width: width ?? ww(16),
	height: height ?? ww(26),
}))``;

export const CautionIcon = styled(Caution).attrs({
	width: w(18),
	height: w(18),
})``;

export const DangerIcon = styled(Danger).attrs({
	width: w(18),
	height: w(18),
})``;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${ww(23)}px;
	width: 100%;
	margin-top: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}

	p {
		font-size: ${ww(7)}px;
		color: #3b4ba2;
		margin-bottom: 0px;
	}
`;

export const TextArea = styled.textarea<{
	textColor?: string;
	fontWeight?: number;
}>`
	font-size: ${ww(9)}px;
	min-height: ${ww(5)}px;
	max-height: ${ww(57)}px;
	min-width: 100%;
	overflow: hidden;
	word-break: break-all;

	color: ${({ textColor }) => textColor ?? "#000000"};
	font-weight: ${({ fontWeight }) => fontWeight || "normal"};
	/* border: 1px solid; */
`;

export const Text = styled.p<{ color?: string }>`
	font-size: ${ww(8)}px;
	color: ${(props) => props.color || "#7f7f7f"};
	margin-bottom: ${ww(18)}px;
	font-weight: 500;
`;
