import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Divisor, SectionTitle } from "../../styles/components";

import { ModalProps } from "../../types/modal";
import Button from "../button/button.components";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	CautionIcon,
	DangerIcon,
	TextContainer,
	TextArea,
} from "./modal.styles";

const Modal: React.FC<ModalProps> = ({
	title,
	text,
	subText,
	textDetails,
	onChangeText,
	buttonText,
	buttonLink,
	type = "alert",
	isLoading = false,
	clickFunction,
	cautionFunction,
	hideIcon = false,
}) => {
	const navigate = useNavigate();
	const [height, setHeight] = React.useState("22px");

	const { register } = useForm<any>();

	const goTo = () => {
		if (type === "caution" || type === "danger") return cautionFunction?.();
		if (buttonLink) return navigate(buttonLink);
		return clickFunction?.();
	};

	const renderButtonProps = () => {
		if (type === "caution") return { customColor: "#EFD700" };
		if (type === "danger") return { customColor: "#FF6868" };
		return {};
	};

	const renderIcon = () => {
		if (type === "caution") return <CautionIcon />;
		if (type === "danger") return <DangerIcon />;
		if (!type) return <Icon />;
	};

	const autoGrowTextArea = (e: any) => {
		if (!e.target.value) {
			setHeight("22px");
			return;
		}
		setHeight(e.target.scrollHeight + "px");
		onChangeText?.(e.target.value);
	};

	const buttonProps = renderButtonProps();

	return (
		<Container>
			<Box type={buttonProps.customColor}>
				<SectionTitle>{title}</SectionTitle>
				{!hideIcon && <IconContainer>{renderIcon()}</IconContainer>}

				<Text>
					{text}
					<br />
					{subText}
				</Text>

				{textDetails ? (
					<>
						<Text color="#000">{textDetails}</Text>
						<Divisor />
					</>
				) : (
					<>
						<TextArea
							style={{ height: height }}
							onChange={autoGrowTextArea}
							fontWeight={500}
						/>
						<Divisor marginBottom={25} />
					</>
				)}

				<Button
					title={buttonText}
					clickFunction={goTo}
					{...buttonProps}
					isLoading={isLoading}
				/>
				{((!isLoading && type === "caution") || type === "danger") && (
					<TextContainer onClick={() => clickFunction?.()}>
						<Text>Cancelar</Text>
					</TextContainer>
				)}
			</Box>
		</Container>
	);
};

export default Modal;
