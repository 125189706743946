import React from "react";
import { FileUploader } from "react-drag-drop-files";

import { UploadFilesProps } from "../../../types/category";
import {
	Container,
	DropZone,
	Text,
	TextError,
	Thumbnail,
} from "./upload-file.styles";

const fileTypes = ["SVG"];

const DragDrop: React.FC<UploadFilesProps> = ({ setSelected }) => {
	const [url, setUrl] = React.useState("");
	const [error, setError] = React.useState(false);

	const dropZoneElement = document.querySelector(".dropZone");

	dropZoneElement?.addEventListener("dragover", (event) => {
		event?.preventDefault();
		dropZoneElement?.classList.add("active");
	});

	["dragleave", "dragend"].forEach((type) => {
		dropZoneElement?.addEventListener(type, (event) => {
			dropZoneElement?.classList.remove("active");
		});
	});

	const imageSize = (file: File): any => {
		const img = new Image();
		img.src = window.URL.createObjectURL(file);

		img.onload = () => {
			if (img.width % 24 !== 0 || img.height % 24 !== 0) {
				setError(true);
				return false;
			}
			setError(false);

			const thumbnailElement = document.querySelector(
				".thumbnail",
			) as HTMLElement;

			thumbnailElement?.classList.add("active");

			const reader = new FileReader();
			const textInput = document.querySelector(".textInput");

			textInput?.classList.add("remove");

			reader.readAsDataURL(file);

			reader.onload = () => {
				thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
				dropZoneElement?.classList.add("active");
				setUrl(`${reader.result}`);
				setSelected?.(file);
			};

			return true;
		};
	};

	const handleChange = (file: File) => {
		imageSize(file);
	};

	return (
		<FileUploader handleChange={handleChange} name="file" types={fileTypes}>
			<Container>
				<Text>Prévia de visualização do ícone:</Text>
				<DropZone className={"dropZone"}>
					<Text className={"textInput"}>Selecionar ícone em SVG...</Text>
					<Thumbnail className={"thumbnail"} />
				</DropZone>
			</Container>
			{error && <TextError>* Tamanho inválido.</TextError>}
		</FileUploader>
	);
};

export default DragDrop;
