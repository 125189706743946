import styled from "styled-components";
import {
	ColumnProps,
	ItemContainerProps,
	RowProps,
} from "../../../types/table";
import { ReactComponent as Sorting } from "../../../assets/icons/sorting.svg";
import { ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: ${ww(46)}px;
	width: 100%;
	margin-top: 1px;
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Row = styled.div<RowProps>`
	display: flex;
	width: 100%;
	background: ${({ isTitle }) => (isTitle ? "#f2f0f9" : "#fff")};
	border-bottom: ${({ isTitle }) => (isTitle ? "none" : "1px solid #d2d0d9")};
`;

/* eslint-disable */
export const ColumnContainer = styled.div<ColumnProps & RowProps>`
	display: flex;
	flex: 1;
	height: ${ww(29)}px;
	min-width: ${ww(19)}px;
	margin-left: ${ww(5)}px;
	margin-right: ${ww(5)}px;
	align-items: center;
	justify-content: ${({ justifyContent }) => justifyContent || "center"};
	max-width: ${({ numberOfColumns }) => `calc(100% / ${numberOfColumns})`};
`;
/* eslint-enable */

export const Text = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
`;

// create a styled component for the text with link styling applied to it
export const Link = styled.a`
	font-size: ${ww(8)}px;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	color: #596cd4;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const Title = styled.p`
	font-size: ${ww(8)}px;
	color: #6e6893;
	font-weight: 600;
	text-transform: uppercase;
`;

export const ItemContainer = styled.div<ItemContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	max-height: ${({ hasActions }) => (hasActions ? ww(241) : ww(290))}px;
	overflow-y: scroll;
`;

export const Image = styled.img`
	width: ${ww(23)}px;
	height: ${ww(23)}px;
	/* filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg); */
`;

export const IconSorting = styled(Sorting).attrs({
	width: ww(15),
	height: ww(28),
})``;
