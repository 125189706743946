import countriesJSON from "./countries.json";

export type CCA2Country = keyof typeof countriesJSON;

const CountrieCodes = Object.keys(countriesJSON);
export const countries = Object.values(countriesJSON).map((country, index) => ({
	...country,
	code: CountrieCodes[index],
}));

export const coins = countries
	.flatMap((item) => item.currency)
	.filter((coin, index, coinsArray) => {
		return coinsArray.indexOf(coin) === index;
	})
	.sort();

export function getFlagCountry(cca2: CCA2Country) {
	let flag = "";
	try {
		flag = countriesJSON[cca2].flag;
	} catch (err) {
		console.error(`Erro ao buscar imagem do país ${cca2}!`);
	}

	return flag;
}
