import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { GeneralContext } from "../../contexts/generalContext";
import { TableActionsStatusProps } from "../../models/table";
import { ww } from "../../styles/responsive";
import Alert from "../alert/alert.component";
import Button from "../button/button.components";
import InputComponent from "../input/input.component";
import { TextError } from "../input/input.styles";
import SelectComponent from "../select/select.component";
import { validate } from "./validateInput";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	Title,
	Form,
	InputContainer,
	SubContainer,
	ArrowDownIcon,
	CancelContainer,
} from "./modality.styles";
import { ModalityInput } from "../../types/input";
import { adminApi } from "../../services/api/axios";
import { Category } from "../formCatalog/formCatalog.component";
import { Pagination } from "../../types/requestPagination";

interface GetCategoriesResponse {
	items: Category[];
	meta: Pagination;
}

const ModalityDetails: React.FC = () => {
	const { setPageDetails, setEditData, editData } =
		React.useContext(GeneralContext);
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		setError,
	} = useForm<ModalityInput>();
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [apiError, setApiError] = React.useState(false);
	const [categories, setCategories] = React.useState<TableActionsStatusProps[]>(
		[],
	);
	const [showCautionAlert, setShowCautionAlert] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState(false);
	const [selectInitialValue, setSelectInitialvalue] = React.useState(0); // It's a index position array

	const { id, category, name: modalityName, minimalPrice, hidden } = editData;

	const queryClient = useQueryClient();

	React.useEffect(() => {
		setIsLoading(true);

		(async () => {
			const getData = async () => {
				try {
					const route = "/categories?page=1&limit=0";

					const response = await adminApi.get<GetCategoriesResponse>(route);

					const formattedData = response.data.items.map((item: any) => {
						return {
							value: item.category_id,
							title: item.name,
						};
					});

					if (hidden?.category_id) {
						const indexOfSelectedCategory = formattedData.findIndex(
							(item) => item.value === hidden.category_id,
						);
						setSelectInitialvalue(indexOfSelectedCategory);
					}

					setCategories(formattedData);
					return formattedData;
				} catch (error) {
					return [];
				} finally {
					setIsLoading(false);
				}
			};

			await getData();

			if (id) {
				setValue("minPrice", minimalPrice);
				setValue("modality", modalityName);
			}
		})();
	}, []);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const minPrice = Number(
			(event.target as any).minPrice.value.replace(",", "."),
		);

		const hasError = validate(
			{ modality: getValues("modality"), minPrice: getValues("minPrice") },
			setError,
		);
		if (hasError || !selectedData) return;
		setIsLoading(true);

		const data = {
			name: getValues("modality"),
			minimalPrice: minPrice,
			category: selectedData,
		};

		if (id) {
			const response = await adminApi.put(`/modalities/update/${id}`, data);
			queryClient.invalidateQueries("modalities"); // refetch modilities list

			if (response.status !== 200) {
				return;
			}
		} else {
			const response = await adminApi.post("/modalities/create", data);
			queryClient.invalidateQueries("modalities"); // refetch modilities list

			if (response.status !== 201) {
				return;
			}
		}

		setTimeout(() => {
			setShowAlert(true);
			setEditData?.({});
			setIsLoading(false);
		}, 2000);
	};

	const blockFunction = async () => {
		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);
			setShowCautionAlert(false);
			setPageDetails?.(false);
		}, 2000);

		setEditData?.({});
	};

	return (
		<Container>
			<Box>
				<IconContainer
					onClick={() => (setPageDetails?.(false), setEditData?.({}))}
				>
					<Icon />
				</IconContainer>

				<Title>{`${id ? "Editando" : "Adicionar"} Modalidade`}</Title>
				<Form onSubmit={onSubmit}>
					<InputContainer>
						<SubContainer>
							<Text>Categoria:</Text>
							{hidden?.category_id ? (
								Boolean(selectInitialValue) && (
									<SelectComponent
										data={categories!}
										width={ww(165)}
										setData={setSelectedData}
										initialValue={selectInitialValue}
									>
										<ArrowDownIcon />
									</SelectComponent>
								)
							) : (
								<SelectComponent
									data={categories!}
									width={ww(165)}
									setData={setSelectedData}
									initialValue={selectInitialValue}
								>
									<ArrowDownIcon />
								</SelectComponent>
							)}
						</SubContainer>
						<InputComponent
							register={register}
							required={true}
							value={getValues("modality")}
							onFocus={() => setError("modality", { type: "", message: "" })}
							name="modality"
							type="text"
							placeholder="Modalidade"
							error={errors.modality?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={getValues("minPrice")}
							onFocus={() => setError("minPrice", { type: "", message: "" })}
							name="minPrice"
							type="contabil"
							placeholder="Preço Mínimo"
							maxLength={8}
							error={errors.minPrice?.message}
							width={ww(164)}
							isSecondary={true}
						/>
					</InputContainer>

					{apiError && (
						<TextError>* Acesso negado, email ou senha incorretos.</TextError>
					)}
					<ButtonContainer>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</ButtonContainer>
				</Form>
				{!!id && (
					<ButtonContainer
						onClick={() => (setShowCautionAlert(true), setEditData?.({}))}
					>
						<Button title="Desativar Modalidade" inverterColor />
					</ButtonContainer>
				)}
				<CancelContainer>
					<Text onClick={() => (setPageDetails?.(false), setEditData?.({}))}>
						Cancelar
					</Text>
				</CancelContainer>
			</Box>
			{showCautionAlert && (
				<Alert
					text={`Você está prestes a desativar essa modalidade:${getValues(
						"modality",
					)}`}
					buttonText="Ok"
					clickFunction={() => (setShowCautionAlert(false), setEditData?.({}))}
					cautionFunction={() => blockFunction()}
					isLoading={isLoading}
					type="danger"
				/>
			)}
			{showAlert && (
				<Alert
					text="Modalidade adicionada com sucesso."
					buttonText="Ok"
					clickFunction={() => (
						setShowAlert(false), setPageDetails?.(false), setEditData?.({})
					)}
				/>
			)}
		</Container>
	);
};

export default ModalityDetails;
