import { Pagination } from "./../types/requestPagination";
import { apiAntiga } from "./api/axios";

type FinanceStatement = {
	date: string;
	gatewayRepasse: number;
	greekoRepasse: number;
	id: string;
	studentFee: number;
	studentName: number;
	teacherAmount: number;
	teacherFee: number;
	teacherName: string;
	totalSchedule: number;
	totalValue: number;
	status: string;
};

type GetFinancialStatement = {
	items: FinanceStatement[];
	meta: Pagination;
};

export async function getFinancialStatement(
	params: any,
): Promise<GetFinancialStatement | undefined> {
	try {
		const response = await apiAntiga.get<GetFinancialStatement>(
			"/admin/financial",
			{ params },
		);

		return response.data;
	} catch {
		console.error("Error");
	}
}
