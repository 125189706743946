import { ReactSVG } from "react-svg";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GeneralContext } from "../../../contexts/generalContext";

import { TableItemProps } from "../../../types/table";
import { sortData } from "../../../utils/sortData";

import {
	ColumnContainer,
	Container,
	RowContainer,
	Row,
	Title,
	Text,
	IconSorting,
	ItemContainer,
	Image,
} from "./table-item.styles";

const TableItem: React.FC<TableItemProps> = ({
	titleData,
	data,
	setData,
	hasPageDetails,
	hasActions,
	onPressColumnSortData,
}) => {
	const { showFilter, setPageDetails, setEditData } =
		React.useContext(GeneralContext);
	const location = useLocation();
	const [invert, setInvert] = React.useState(false);

	const navigate = useNavigate();

	const currentRoute = location.pathname.split("/")[1];

	const modalData = (data: any, id: string) => {
		setPageDetails?.({
			route: currentRoute,
			id,
		});

		/* eslint-disable */
		switch (currentRoute) {
			case "finance":
				return setEditData?.(data);
			case "acceptance":
				return setEditData?.(data);
			case "modality":
				return setEditData?.(data);
			
			case "admin-users":
				return setEditData?.(data);
			case "support":
				return setEditData?.(data);
			default:
				return;
		}
		/* eslint-enable */
	};

	React.useEffect(() => {
		if (currentRoute.search("category") > -1) {
			const elements = document.querySelectorAll(".column");

			elements.forEach((item) => {
				item.classList.add("category");
			});
		}

		if (currentRoute.search("admin-users") > -1) {
			const elements = document.querySelectorAll(".column");

			elements.forEach((item) => {
				item.classList.add("admin-users");
			});
		}

		if (currentRoute.search("countries") > -1) {
			const elements = document.querySelectorAll(".column");

			elements.forEach((item) => {
				item.classList.add("country");
			});
		}

		if (currentRoute.search("acceptance") > -1) {
			const elements = document.querySelectorAll(".column");

			elements.forEach((item) => {
				item.classList.add("acceptance");
			});
		}
	});

	if (!data?.length) return <></>;

	function filterKeyHidden(obj: any) {
		const newObj = structuredClone(obj);

		delete newObj["hidden"];

		return newObj;
	}

	const keys = !!data && Object.keys(filterKeyHidden(data?.[0]));
	type ObjectKey = keyof typeof data[0];

	const renderStatusTextColor = (status: string) => {
		if (status === "Inativo" || status === "Reprovado") return "#FF6868";
		if (status === "Aprovado") return "#64BC47 ";
		return "#25213b";
	};

	function renderItem(index: number, item: any, keyItem: ObjectKey) {
		if (keyItem === "icon" && item?.icon?.includes(".svg"))
			return <ReactSVG src={item?.icon} />;

		if (keyItem === "icon") return <Image src={item.icon} />;

		if (keyItem === "status") {
			return (
				<Text style={{ color: renderStatusTextColor(item.status) }}>
					{item?.[keyItem]}
				</Text>
			);
		}

		return (
			<Text
				style={{
					color: String(item?.[keyItem]).startsWith("-R$")
						? "#FF6868"
						: "#25213b",
				}}
			>
				{item?.[keyItem]}
			</Text>
		);
	}

	return (
		<Container>
			<RowContainer>
				<Row isTitle={true}>
					{titleData?.map(({ title, icon }, index: number) => (
						<ColumnContainer
							key={index}
							hasIcon={icon}
							isTitle={true}
							numberOfColumns={keys.length - 1}
							className={"column"}
							onClick={() => {
								if (onPressColumnSortData) {
									return onPressColumnSortData(keys[++index]);
								}

								return icon
									? (sortData(data!, setData!, keys[index + 1], invert),
									  setInvert(!invert))
									: {};
							}}
						>
							{icon && <IconSorting />}
							<Title>{title}</Title>
						</ColumnContainer>
					))}
				</Row>
				<ItemContainer
					hasActions={!!hasActions && showFilter}
					className="scroll"
				>
					{data?.map((item, index: number) => {
						return (
							<Row
								/* eslint-disable */
								key={index}
								onClick={() =>
									hasPageDetails
										? navigate("details", {
												state: {
													routeFrom: currentRoute,
													id: item.id,
													name: item.name,
													item: item,
												},
										  })
										: modalData(item, item.id)
								}
							>
								{Object.keys(keys).map((key, index2) => {
									if (index2) {
										const keyItem = keys[index2] as ObjectKey;

										return (
											<ColumnContainer
												key={index2}
												numberOfColumns={keys.length - 1}
												className={"column"}
												isCategory={
													currentRoute === "category" && keyItem === "icon"
												}
											>
												{renderItem(index, item, keyItem)}
											</ColumnContainer>
										);
									}
								})}
							</Row>
						);
					})}
				</ItemContainer>
			</RowContainer>
		</Container>
	);
};

export default TableItem;
