import React from "react";
import { useLocation } from "react-router-dom";
import { GeneralContext } from "../../../contexts/generalContext";
import { apiAntiga, adminApi } from "../../../services/api/axios";
import { AboutProfileProps } from "../../../types/details";
import { convertDate_yy_mm_dd } from "../../../utils/convertDate";
import Alert from "../../alert/alert.component";
import Button from "../../button/button.components";
import Avatar from "../../../assets/images/avatar.png";

import {
	Container,
	Image,
	ImageContainer,
	StatusContainer,
	SubContainer,
	Title,
	Text,
	ContainerButton,
	FullStarIcon,
	StarContainer,
	HalfStarIcon,
	EmptyStarIcon,
	SubButtonContainer,
} from "./profile.styles";
import mask from "../../input/mask";

const DetailsComponent: React.FC<AboutProfileProps> = ({ id, routeFrom }) => {
	const location = useLocation();
	const { setPageDetails } = React.useContext(GeneralContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [showCautionAlert, setShowCautionAlert] = React.useState(false);
	const [data, setData] = React.useState<any>();
	const stars = data?.rating?.toFixed(2).toString().split(".") ?? "0.0";
	const [hasChanged, setHasChanged] = React.useState(false);
	const [reloaded, setReloaded] = React.useState(false);

	/* eslint-disable */
	const cautionMessage =
		data?.status === "Ativo"
			? `Você está prestes a bloquear um ${
					routeFrom.search("personal") >= 0 ? "professor" : "aluno"
			  }, tem certeza?`
			: `Você está prestes a desbloquear um ${
					routeFrom.search("personal") >= 0 ? "professor" : "aluno"
			  }, tem certeza?`;
	const alertMessage =
		data?.status === "Ativo"
			? `${
					routeFrom.search("personal") >= 0 ? "Professor" : "Aluno"
			  } desbloqueado com sucesso.`
			: `${
					routeFrom.search("personal") >= 0 ? "Professor" : "Aluno"
			  } bloqueado com sucesso.`;
	/* eslint-enable */

	const getData = async () => {
		try {
			const { data } = await getDetails(routeFrom, id);

			data.status = data.status ? "Ativo" : "Inativo";
			const { newDate } = convertDate_yy_mm_dd(data.createdAt);
			data.createdAt = newDate;

			setData(data);
			setReloaded(true);
		} catch (error) {
			//
		}
	};

	if (!reloaded) {
		getData();
	}

	const blockFunction = async () => {
		const route =
			routeFrom.search("personal") >= 0
				? `/adm-activate-block-teacher/${id}`
				: `/adm-activate-block-student/${id}`;
		setIsLoading(true);

		try {
			await apiAntiga.post(route);

			setHasChanged(!hasChanged);
			getData();

			setTimeout(() => {
				setShowAlert(true);
			}, 1000);
		} catch (error) {
			setIsLoading(false);
		}

		setIsLoading(false);
		setShowCautionAlert(false);
	};

	function getActionStatus(status: string) {
		return status === "Ativo" ? "Bloquear" : "Ativar";
	}

	return (
		<Container>
			<SubContainer>
				<ImageContainer>
					<Image src={data?.photo ?? Avatar} />
					<StatusContainer>
						<Text>{data?.status}</Text>
					</StatusContainer>
				</ImageContainer>
				<Title>{data?.name}</Title>
				<StarContainer>
					{[1, 2, 3, 4, 5].map((item, index) => {
						if (index <= +stars[0] - 1) {
							return <FullStarIcon />;
						} else if (+stars[1] > 0 && index === +stars[0]) {
							return <HalfStarIcon />;
						} else {
							return <EmptyStarIcon />;
						}
					})}
					<Text>{data?.rating ? data?.rating.toFixed(2) : "0.0"}</Text>
				</StarContainer>
			</SubContainer>
			<SubContainer>
				<Title>Dados Principais:</Title>
				<Text>{data?.phoneNumber}</Text>
				<Text>{data?.email}</Text>
				{!!data?.cref && <Text>CREF: {data?.cref}</Text>}
				{!!data?.cpf && <Text>CPF: {data?.cpf}</Text>}
				<Text>{data?.schedulesCount} aulas pelo Greeko</Text>
				<Text>Membro desde: {data?.createdAt}</Text>
			</SubContainer>
			<SubContainer onEnd={true}>
				<ContainerButton>
					<Button
						title={getActionStatus(data?.status)}
						customColor={data?.status === "Ativo" ? "#FF6868" : "#30BA88"}
						clickFunction={() => setShowCautionAlert(true)}
					/>
				</ContainerButton>
				{location.pathname.search("student") >= 0 && (
					<SubButtonContainer>
						<ContainerButton>
							<Button
								title="Anamnese"
								inverterColor={true}
								clickFunction={() =>
									setPageDetails?.({
										route: "student/anamnese",
										id: data?.id,
									})
								}
							/>
						</ContainerButton>
						<ContainerButton>
							<Button
								title="Anotações"
								inverterColor={true}
								clickFunction={() =>
									setPageDetails?.({
										route: "student/annotations",
										student: data?.name,
										id: data?.id,
									})
								}
							/>
						</ContainerButton>
					</SubButtonContainer>
				)}
			</SubContainer>
			{showCautionAlert && (
				<Alert
					text={cautionMessage}
					buttonText={getActionStatus(data?.status)}
					clickFunction={() => setShowCautionAlert(false)}
					cautionFunction={() => blockFunction()}
					isLoading={isLoading}
					type="danger"
				/>
			)}
			{showAlert && (
				/* eslint-disable */
				<Alert
					text={alertMessage}
					buttonText="Ok"
					clickFunction={() => setShowAlert(false)}
				/>
				/* eslint-enable */
			)}
		</Container>
	);
};

export default DetailsComponent;

const getDetails = (routeFrom: string, id: string) => {
	if (routeFrom.search("personal") >= 0) {
		return apiAntiga.get(`/adm-get-teacher/?teacherId=${id}`);
	}

	return adminApi.get(`/student/detail/${id}`);
};
