import React from "react";
import { acceptanceStatus } from "../../../constants/acceptance";
import { mockedDocumentsName } from "../../../mockup/acceptance";
import { Circle, ContainerTableButton } from "../../../styles/components";
import { TableItemProps } from "../../../types/table";
import { Icon } from "../../alert/alert.styles";
import TableButton from "../../tableButton/tableButton.components";

import {
	ColumnContainer,
	RowContainer,
	Row,
	Title,
	Text,
	IconSorting,
	ItemContainer,
	Link,
} from "./table-item.styles";

const formatDocumentStatusText = (status: string) => {
	if (status === acceptanceStatus.pending) return "Pendente";
	if (status === acceptanceStatus.approved) return "Aprovado";
	if (status === acceptanceStatus.rejected) return "Recusado";
};

const TableItem: React.FC<TableItemProps> = ({
	titleData,
	data,
	onClickApproveButton,
	onClickDisapproveButton,
	onClickDetailsButton,
}) => {
	if (!data?.length) return <></>;

	const keys = !!data && Object.keys(data?.[0]);
	type ObjectKey = keyof typeof data[0];

	const openInNewTab = (url: string) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	const renderStatusTextColor = (status: string) => {
		if (status === acceptanceStatus.approved) return "#64BC47 ";
		if (status === acceptanceStatus.rejected) return "#FF6868";
		return "#25213b";
	};

	const renderItemStatus = (status: string) => {
		return (
			<>
				{status !== acceptanceStatus.pending && (
					<Circle size={8} color={renderStatusTextColor(status)} />
				)}
				<Text
					style={{
						color: renderStatusTextColor(status),
					}}
				>
					{formatDocumentStatusText(status)}
				</Text>
			</>
		);
	};

	const renderColumnItem = (item: any, keyItem: ObjectKey) => {
		let name = new URL(item.documentFile).pathname.split("/").pop() || "";

		if (name.length > 17) {
			name = name?.substring(name.length - 17).padStart(20, ".");
		}

		if (keyItem === "status") return renderItemStatus(item?.[keyItem]);
		if (keyItem === "documentFile") {
			return <Link onClick={() => openInNewTab(item?.[keyItem])}>{name}</Link>;
		}
		return <Text>{item?.[keyItem]}</Text>;
	};

	const renderColumnAction = (item: any) => {
		if (item.status === acceptanceStatus.approved) {
			return <Icon width="25" height="25" />;
		}
		if (item.status === acceptanceStatus.rejected) {
			return (
				<ContainerTableButton>
					<TableButton
						title="Detalhes"
						clickFunction={() => onClickDetailsButton?.(item.id)}
					/>
				</ContainerTableButton>
			);
		}
		if (item.status === acceptanceStatus.pending) {
			return (
				<ContainerTableButton>
					<TableButton
						title="Aprovar"
						customColor="#6BAF97"
						clickFunction={() => onClickApproveButton?.(item.id)}
					/>
					<TableButton
						title="Recusar"
						customColor="#FF6868"
						clickFunction={() => onClickDisapproveButton?.(item.id)}
					/>
				</ContainerTableButton>
			);
		}
	};

	return (
		<RowContainer>
			<Row isTitle={true}>
				{titleData?.map(({ title, icon }, index: number) => (
					<ColumnContainer
						key={index}
						hasIcon={icon}
						isTitle={true}
						numberOfColumns={keys.length - 1}
						className={"column"}
						justifyContent={title === "Ação" ? "center" : "flex-start"}
					>
						{icon && <IconSorting />}
						<Title>{title}</Title>
					</ColumnContainer>
				))}
			</Row>
			<ItemContainer hasActions={false} className="scroll">
				{data?.map((item, index: number) => {
					return (
						<Row key={index}>
							{Object.keys(keys).map((key, index2) => {
								if (index2) {
									const keyItem = keys[index2] as ObjectKey;

									return (
										<>
											{keyItem !== "justificationDetails" && (
												<ColumnContainer
													key={index2}
													numberOfColumns={keys.length - 1}
													className={"column"}
													justifyContent="flex-start"
												>
													{renderColumnItem(item, keyItem)}
												</ColumnContainer>
											)}
										</>
									);
								}
							})}
							<ColumnContainer className={"column"}>
								{renderColumnAction(item)}
							</ColumnContainer>
						</Row>
					);
				})}
			</ItemContainer>
		</RowContainer>
	);
};

export default TableItem;
