import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { GeneralContext } from "../../contexts/generalContext";
import { ww } from "../../styles/responsive";
import Alert from "../alert/alert.component";
import Button from "../button/button.components";
import InputComponent from "../input/input.component";
import { TextError } from "../input/input.styles";
import { validate } from "./validateInput";

import {
	Container,
	Box,
	Text,
	IconContainer,
	Icon,
	ButtonContainer,
	Title,
	Form,
	InputContainer,
	CancelContainer,
} from "./admin-user.styles";
import { AdminUserInput } from "../../types/input";
import { adminApi, apiAntiga } from "../../services/api/axios";
import { onlySpaces } from "../../utils/string";
import { getAllAdmins } from "../../hooks/usAdmins";

const AdminUserDetails: React.FC = () => {
	const { setPageDetails, setEditData, editData } =
		React.useContext(GeneralContext);

	const queryClient = useQueryClient();

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		setError,
	} = useForm<AdminUserInput>();

	const [isLoading, setIsLoading] = React.useState(false);
	const [apiError, setApiError] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);
	const [showCautionAlert, setShowCautionAlert] = React.useState(false);

	const { id, name: adminUserName, email: currentEmail, status } = editData;

	const adminUserStatusIsActive = status === "Ativo";

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const name = getValues("name");
		const email = getValues("email");
		const password = getValues("password");
		const confirmPassword = getValues("confirmPassword");

		const hasError = await validate(
			{ name, email, password, confirmPassword },
			currentEmail,
			setError,
		);

		if (hasError) {
			return;
		}

		setIsLoading(true);

		const data = {
			name,
			email,
			password,
			status: true,
		};

		if (id) {
			const response = await apiAntiga.patch(`/admins/${id}/update`, data);
			queryClient.invalidateQueries("admin-users"); // refetch modilities list

			if (response.status !== 200) {
				return;
			}
		} else {
			const response = await apiAntiga.post("/admins/create", data);
			queryClient.invalidateQueries("admin-users"); // refetch modilities list

			if (response.status !== 201) {
				return;
			}
		}

		setTimeout(() => {
			setShowAlert(true);
			setIsLoading(false);
		}, 2000);
	};

	async function handleUpdateStatusAdminUser() {
		setIsLoading(true);

		try {
			await apiAntiga.patch(`/admins/${id}/update`, {
				status: adminUserStatusIsActive ? false : true,
			});

			queryClient.invalidateQueries("admin-users"); // refetch categories list
		} catch (err) {
			console.error("Error updating category, try again!");
		} finally {
			setIsLoading(false);
			setShowCautionAlert(false);
			setEditData?.({});
			setPageDetails?.(false);
		}
	}

	function closeModal() {
		setShowCautionAlert(false);
		setShowAlert(false);
		setPageDetails?.(false);
		setEditData?.({});
	}

	return (
		<Container>
			<Box>
				<IconContainer onClick={closeModal}>
					<Icon />
				</IconContainer>

				<Title>{`${
					adminUserName ? "Editando" : "Adicionar"
				} Administrador`}</Title>

				<Form onSubmit={onSubmit}>
					<InputContainer>
						<InputComponent
							register={register}
							required={true}
							value={id ? adminUserName : getValues("name")}
							onFocus={() => setError("name", { type: "", message: "" })}
							name="name"
							type="text"
							placeholder="Nome"
							error={errors.name?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						<InputComponent
							register={register}
							required={true}
							value={id ? currentEmail : getValues("email")}
							onFocus={() => setError("email", { type: "", message: "" })}
							name="email"
							type="email"
							placeholder="Email"
							error={errors.email?.message}
							width={ww(164)}
							isSecondary={true}
						/>
						{/* Show password and confirm password if is editing */}
						{!id && (
							<>
								<InputComponent
									register={register}
									required={true}
									value={getValues("password")}
									onFocus={() =>
										setError("password", { type: "", message: "" })
									}
									name="password"
									type="password"
									placeholder="Senha"
									error={errors.password?.message}
									width={ww(164)}
									isSecondary={true}
								/>
								<InputComponent
									register={register}
									required={true}
									value={getValues("confirmPassword")}
									onFocus={() =>
										setError("confirmPassword", { type: "", message: "" })
									}
									name="confirmPassword"
									type="password"
									placeholder="Confirmar Senha"
									error={errors.confirmPassword?.message}
									width={ww(164)}
									isSecondary={true}
								/>
							</>
						)}
					</InputContainer>

					{apiError && (
						<TextError>* Acesso negado, email ou senha incorretos.</TextError>
					)}
					<ButtonContainer>
						<Button type="submit" title="Salvar" isLoading={isLoading} />
					</ButtonContainer>
				</Form>
				{!!id && (
					<ButtonContainer onClick={() => setShowCautionAlert(true)}>
						<Button
							title={
								adminUserStatusIsActive
									? "Desativar Administrador"
									: "Ativar Administrador"
							}
							inverterColor
						/>
					</ButtonContainer>
				)}
				<CancelContainer>
					<Text onClick={() => (setPageDetails?.(false), setEditData?.({}))}>
						Cancelar
					</Text>
				</CancelContainer>
			</Box>
			{showCautionAlert && (
				<Alert
					text={
						adminUserStatusIsActive
							? "Você está prestes a desativar um admin: " + adminUserName
							: "Você está prestes a ativar um admin: " + adminUserName
					}
					buttonText={adminUserStatusIsActive ? "Desativar" : "Ativar"}
					cautionFunction={() => handleUpdateStatusAdminUser()}
					clickFunction={() => (setShowCautionAlert(false), setEditData?.({}))}
					isLoading={isLoading}
					type="danger"
				/>
			)}
			{showAlert && (
				<Alert
					text={
						adminUserName
							? "Administrador editado com sucesso."
							: "Administrador adicionado com sucesso."
					}
					buttonText="Ok"
					clickFunction={closeModal}
				/>
			)}
		</Container>
	);
};

export default AdminUserDetails;
