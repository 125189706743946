import { ww } from "./../../../styles/responsive";
import styled from "styled-components";

export const DropZone = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	border: 1px dashed rgba(0, 0, 0, 0.4);

	display: flex;
	position: relative;
`;

export const Thumbnail = styled.div`
	height: 100%;
	width: 100%;
	background-color: #f7f7f7;
	/* border: 1px dashed rgba(0, 0, 0, 0.4); */

	border-radius: ${ww(3)}px;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	transition: 0.2s ease-in-out;

	opacity: 0;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	flex: 1;

	&.active {
		opacity: 1;
	}
`;

export const Text = styled.p`
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;

	color: #4b4b4b;
`;

export const TextInfo = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 8px;
	line-height: 10px;
	text-align: center;

	color: #3b4ba2;

	margin-top: 20px;
	max-width: 130px;
`;
