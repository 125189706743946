import {
	Category,
	CategoryOption,
} from "../components/formCatalog/formCatalog.component";
import { adminApi } from "../services/api/axios";
import { Pagination } from "../types/requestPagination";
import { onlySpaces } from "../utils/string";

interface GetCategoriesResponse {
	items: Category[];
	meta: Pagination;
}

export function loadCategories(
	inputValue: string,
	callback: (options: CategoryOption[]) => void,
) {
	if (inputValue === "" || !!onlySpaces(inputValue)) {
		adminApi
			.get<GetCategoriesResponse>("/categories?page=1&limit=9999")
			.then(({ data }) => {
				const formattedOptions = data.items
					.filter((option) => option.status)
					.map((option) => ({
						value: option.id,
						label: option.name,
						color: "#596CD41A",
					}));

				callback(formattedOptions);
			});
	} else {
		adminApi
			.get<Category[]>(`/categories/search/${inputValue}?page=1&limit=9999`)
			.then(({ data }) => {
				const formattedOptions = data
					.filter((option) => option.status)
					.map((option) => ({
						value: option.id,
						label: option.name,
						color: "#596CD41A",
					}));

				callback(formattedOptions);
			});
	}
}
