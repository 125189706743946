import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";

import { w, ww } from "../../styles/responsive";
import { ButtonContainerProps } from "../../types/button";
import { IconContainerProps } from "../../types/category";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90%;
	width: 100%;
`;

export const Text = styled.p`
	font-size: ${ww(7)}px;
	color: #3b4ba2;
	font-weight: 500;
`;

export const Title = styled.p`
	font-size: ${ww(9)}px;
	font-weight: 700;
	text-align: left;
	color: #3b4ba2;
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	border-radius: ${ww(3)}px;
	width: ${ww(600)}px;
	padding: ${w(11, 17, 9, 17)};
	position: relative;
`;

export const CloseIconContainer = styled.div`
	display: flex;
	width: ${ww(17)}px;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: ${ww(6)}px;
	right: ${ww(6)}px;

	&:hover {
		cursor: pointer;
	}
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
	display: flex;
	width: ${({ width }) => width}px;
	margin: ${w(0, 0, 6, 10)};
`;

export const SelectContainer = styled.div`
	display: flex;
	margin: ${w(12, 0)};
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SubContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex: 1;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const UploadSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: ${ww(5)}px;

	p {
		color: #7c7c7c;
	}
`;

export const VerticalDivisor = styled.div`
	height: ${ww(209)}px;
	border-right: 1px solid #00000040;
	margin: ${w(0, 15)};
`;

export const HorizontallDivisor = styled.div`
	width: 100%;
	border-bottom: 1px solid #00000040;
	margin: ${w(15, 0)};
`;

export const IconContainer = styled.div<IconContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${ww(3)}px;
	width: ${ww(40.5)}px;
	height: ${ww(40.5)}px;
	margin: ${w(5, 2)};
	background: ${({ selected }) => (selected ? "#3d4da7" : "#fff")};

	&:hover {
		cursor: pointer;
	}

	& path {
		fill: ${({ selected }) => (selected ? "#fff" : "#7C7C7C")};
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	margin: ${w(7, 0, 2, 0)};
`;

export const Image = styled.img`
	width: 100%;
	height: ${ww(100)}px;
	object-fit: contain;
`;

export const ArrowDownIcon = styled(ArrowDown).attrs({
	width: w(14),
	height: w(14),
})``;
