import styled from "styled-components";
import { ReactComponent as ArrowRight } from "../../../assets/icons/chevron_big_right.svg";
import { w, ww } from "../../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	width: 100%;
	max-width: ${ww(210)}px;
	inline-size: ${ww(210)}px
	overflow-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
	align-items: flex-start;
	justify-content: center;
	padding: ${w(12, 0)};

`;

export const SubContainer = styled.div`
	display: flex;
	margin-bottom: ${ww(8)}px;
`;

export const ImageContainer = styled.div`
	display: flex;
	margin-right: ${ww(6)}px;
`;

export const Image = styled.img`
	width: ${ww(23)}px;
	height: ${ww(23)}px;
	border-radius: ${ww(23)}px;
	object-fit: cover;
`;

export const TextContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
export const Title = styled.p`
	font-size: ${ww(8)}px;
	font-weight: 400;
	letter-spacing: -0.02em;
	text-align: left;
	color: #3b4ba2;
	margin-bottom: 2px;
	text-transform: capitalize;
`;

export const Date = styled.p`
	font-size: ${ww(7)}px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.54);
`;

export const Text = styled(Date)`
	color: #4b4b4b;
`;

export const IconArrowRight = styled(ArrowRight)`
	display: flex;
`;
