import { AnyNsRecord } from "dns";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../../components/alert/alert.component";
import FormCatalogComponent, {
	DataCatalog,
} from "../../../components/formCatalog/formCatalog.component";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { apiAntiga } from "../../../services/api/axios";
import { Container, Title } from "./product.style";

type Category = {
	category: {
		id: string;
		name: string;
	};
};

type GETProductDetailsResponse = {
	description: string;
	id: string;
	image: string;
	isFeatured: boolean;
	name: string;
	typeProduct: string;
	url: string;
	category: Category[];
};

const PAGE_TITLE = "Catálogo > Editar Produto";

function EditProductPage() {
	useTitle(PAGE_TITLE);

	const [showAlert, setShowAlert] = React.useState({
		success: false,
		confirmDelete: false,
		confirmCancel: false,
		confirmSave: false,
	});
	const [isLoading, setIsLoading] = React.useState({
		submmiting: false,
		gettingData: false,
	});
	const [productDetails, setProductDetails] =
		React.useState<GETProductDetailsResponse | null>(null);
	const [dataForUpdate, setDataForUpdate] = React.useState<DataCatalog | null>(
		null,
	);
	const navigate = useNavigate();
	const location = useLocation();

	const productId = (location?.state as any)?.id as string;

	useEffect(() => {
		if (!productId) {
			navigate("/catalog");
			return;
		}

		try {
			setIsLoading({ ...isLoading, gettingData: true });

			apiAntiga
				.get<GETProductDetailsResponse>(`/admin/products/${productId}`)
				.then(({ data }) => {
					setProductDetails(data);
				});
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading({ ...isLoading, gettingData: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function updateProductImage(data: Pick<DataCatalog, "image">) {
		if (typeof data.image === "string") {
			return true;
		}

		try {
			const formData = new FormData();
			formData.append("image", data.image as any);

			const response = await apiAntiga.put(
				`/admin/products/update/image/${productId}`,
				formData,
			);

			if (response.status === 200) {
				return true;
			}

			alert("Erro ao atualizar a imagem, tente novamente!");
			return false;
		} catch {
			alert(
				"Houve Erro inesperado ao atualizar a imagem, tente novamente mais tarde!",
			);
		}
	}

	async function handleUpdateProduct(data: DataCatalog | null) {
		if (!data) {
			return;
		}

		try {
			setIsLoading((prevState) => ({ ...prevState, submmiting: true }));

			const body = {
				name: data?.name,
				description: data.description,
				url: data.url,
				isFeatured: data.featured,
				typeProduct: data.type,
				categories: data.categories.map((item) => ({ id: item.value })),
			};

			const responses = await Promise.all([
				apiAntiga.put(`/admin/products/update/${productId}`, body),
				updateProductImage(data),
			]);

			if (responses[1]?.valueOf() && responses[0].status === 200) {
				setShowAlert((prevState) => ({ ...prevState, success: true }));
			} else {
				alert("Erro ao criar o produto, tente novamente!");
			}
		} catch (error) {
			console.error(error);
			alert("Um erro inesperado aconteceu, tente novamente mais tarde!");
		} finally {
			setIsLoading((prevState) => ({ ...prevState, submmiting: false }));
		}
	}

	function onPressOkAlertSuccess() {
		setShowAlert((prevState) => ({ ...prevState, success: false }));
		navigate("/catalog");
	}

	async function handleDeleteProduct() {
		try {
			const response = await apiAntiga.post(
				`/admin/products/delete/${productId}`,
			);

			if (response.status === 200 || response.status === 201) {
				setShowAlert({ ...showAlert, confirmDelete: false });

				navigate("/catalog");
			}
		} catch (error) {
			console.error(error);
			alert("Um erro inesperado aconteceu, tente novamente mais tarde!");
		}
	}

	async function onSubmit(data: DataCatalog) {
		setDataForUpdate(data);
		setShowAlert({ ...showAlert, confirmSave: true });
	}

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>

			{productDetails && (
				<FormCatalogComponent
					type="UPDATE"
					initialValues={{
						name: productDetails?.name as string,
						description: productDetails?.description as string,
						url: productDetails?.url as string,
						type: productDetails?.typeProduct as string,
						featured: productDetails?.isFeatured as boolean,
						image: productDetails?.image as string,
						categories: productDetails?.category.map((category) => ({
							value: category.category.id,
							label: category.category.name,
						})) as any,
					}}
					onSubmit={onSubmit}
					isLoading={isLoading.submmiting}
					onPressDelete={() =>
						setShowAlert({ ...showAlert, confirmDelete: true })
					}
					onPressCancel={() =>
						setShowAlert({ ...showAlert, confirmCancel: true })
					}
				/>
			)}

			{showAlert.success && (
				<Alert
					text="Modificações salvas com sucesso!"
					buttonText="Ok"
					clickFunction={onPressOkAlertSuccess}
				/>
			)}

			{showAlert.confirmSave && (
				<Alert
					type="danger"
					cautionFunction={() => {
						handleUpdateProduct(dataForUpdate);
						setShowAlert({ ...showAlert, confirmSave: false });
					}}
					text="Deseja salvar as alterações para este produto?"
					buttonText="Sim, Salvar!"
					buttonSecondaryText="Não, manter assim mesmo!"
					clickFunction={() => {
						setShowAlert({ ...showAlert, confirmSave: false });
					}}
				/>
			)}

			{showAlert.confirmDelete && (
				<Alert
					type="danger"
					cautionFunction={() => {
						setShowAlert({ ...showAlert, confirmDelete: false });
					}}
					text="Tem certeza que deseja excluir esse produto?"
					buttonText="Não Excluir"
					buttonSecondaryText="Sim, Desejo Excluir"
					clickFunction={handleDeleteProduct}
				/>
			)}

			{showAlert.confirmCancel && (
				<Alert
					type="danger"
					cautionFunction={() => {
						setShowAlert({ ...showAlert, confirmCancel: false });
					}}
					text="Ao Cancelar, todas as alterações serão perdidas."
					buttonText="Não Cancelar"
					buttonSecondaryText="Sim, Desejo Cancelar"
					clickFunction={() => {
						setShowAlert({ ...showAlert, confirmCancel: false });
						navigate("/catalog");
					}}
				/>
			)}
		</Container>
	);
}

export default EditProductPage;
