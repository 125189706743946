import styled from "styled-components";
import { Link } from "react-router-dom";

import { MenuItemProps } from "../../types/menu";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: ${ww(143)}px;
	align-items: center;
	justify-content: center;
	padding-left: ${ww(3)}px;
	min-width: 255px;
	overflow-y: scroll;
	overflow-x: hidden;
`;

export const Title = styled.p`
	font-size: ${ww(7.5)}px;
	color: #7c7c7c;
	font-weight: 500;
`;

export const Text = styled.p<MenuItemProps>`
	font-size: ${ww(7.5)}px;
	color: ${({ selected }) => (selected ? "#3D4DA7" : "#4b4b4b")};
	font-weight: 500;
	margin-left: ${ww(9)}px;
	text-align: flex-start;

	&:hover {
		cursor: pointer;
	}
`;

export const TitleContainer = styled.div<MenuItemProps>`
	display: flex;
	align-items: center;
	height: ${ww(23)}px;
	width: 100%;
	padding: ${w(0, 11)};
`;

export const ItemContainer = styled(Link)<MenuItemProps>`
	display: flex;
	align-items: center;
	height: ${ww(22)}px;
	width: 100%;
	background: ${({ selected }) =>
		selected ? "rgba(73, 93, 204, 0.1)" : "#fff"};
	padding-left: ${ww(17)}px;

	&:hover {
		cursor: pointer;
		background: rgba(73, 93, 204, 0.1);
	}
`;

export const Divisor = styled.div`
	height: 1px;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	padding-top: ${ww(11)}px;
`;
