import { format, parse } from "date-fns";
import React, { useEffect } from "react";
import Table from "../../../components/table";
import { acceptanceStatus } from "../../../constants/acceptance";
import { GeneralContext } from "../../../contexts/generalContext";
import { useTitle } from "../../../hooks/useTitle";
import { mockupTitle } from "../../../mockup/acceptance";
import { apiAntiga } from "../../../services/api/axios";
import { Pagination } from "../../../types/requestPagination";
import { formatStatusText, formatTypeText } from "../../../utils/formatText";
import { Container, Title } from "./acceptance.styles";
import { useQuery, useQueryClient } from "react-query";
import { useDebounce } from "usehooks-ts";

interface Approval {
	id: string;
	name: string;
	email: string;
	status: string;
	type: boolean;
	countryOfOperation: string;
	dateOfRegistration: string;
}

interface GetApprovalsResponse {
	items: Approval[];
	meta: Pagination;
}
interface Country {
	cca2: string;
	name: string;
}

interface GetCountriesRequest {
	items: Country[];
	meta: Pagination;
}

const PAGE_TITLE = "Aprovação Cadastro";

export const AcceptancePage: React.FC = () => {
	useTitle(PAGE_TITLE);
	const {
		searchFilters,
		setSearchFilters,
		itemsPage,
		currentPage,
		setCurrentPage,
		setItemsPage,
		setTotalItems,
	} = React.useContext(GeneralContext);
	const queryClient = useQueryClient();

	const { searchInput = "" } = searchFilters;
	const debouncedValue = useDebounce<string>(searchInput ?? "", 400);

	React.useEffect(() => {
		setCurrentPage?.(1);
		setItemsPage?.(10);
		setSearchFilters?.({});
	}, []);

	const fetchCountries = async () => {
		const response = await apiAntiga.get<GetCountriesRequest>(
			"/admin/countries",
			{ params: { limit: 50 } },
		);

		return response.data?.items.map((country: Country) => ({
			title: country.name,
			value: country.cca2,
		}));
	};

	const { data: countries } = useQuery("admin/countries", fetchCountries, {
		refetchOnWindowFocus: false,
	});

	const getCountries = () => countries ?? [];

	const fetchList = async () => {
		const finalDate = formatFinalDate(searchFilters.dateTo);

		const params = {
			page: currentPage,
			limit: itemsPage,
			search: debouncedValue.trim(),
			initialDate: searchFilters.dateFrom
				? parse(searchFilters.dateFrom, "dd/MM/yy", new Date()).toISOString()
				: undefined,
			finalDate,
			status: searchFilters.status || undefined,
			type: searchFilters.aprovationType || undefined,
			countryOfOperation: searchFilters.countryOfOperation || undefined,
		};

		const response = await apiAntiga.get<GetApprovalsResponse>(
			"admin/approvals",
			{
				params,
			},
		);

		setTotalItems?.(response.data.meta.totalItems);

		const formattedData = response.data.items.map((item: any) => {
			return {
				id: item.id,
				name: item.user.name,
				email: item.user.email,
				dateOfRegistration: format(new Date(item.user.createdAt), "dd/MM/yyyy"),
				countryOfOperation: item.user.countryOfOperation,
				type: formatTypeText(item.type),
				status: formatStatusText(item.status),
			};
		});

		return formattedData;
	};

	const getFilters = () => {
		const filters = { ...searchFilters };
		delete filters.searchInput;
		return filters;
	};

	const { data: acceptance, refetch } = useQuery(
		["admin/acceptance", currentPage, itemsPage, getFilters()],
		fetchList,
		{
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		refetch();
	}, [debouncedValue, refetch]);

	const updateData = (data: any) => {
		queryClient.setQueryData("admin/acceptance", () => data);
	};

	return (
		<Container>
			<Title>{PAGE_TITLE}</Title>
			<Table
				titleData={mockupTitle}
				data={acceptance ?? []}
				setData={updateData}
				showDate={true}
				// hasModalities={false}
				hasActions={true}
				hasPageDetails={true}
				hasCountryOfOperation={true}
				hasAprovationType={true}
				aprovationTypeData={[
					{ title: "Selecionar", value: "" },
					{ title: "Novo Cadastro", value: "ACCOUNT_ACTIVATION" },
					{ title: "Mudança de País", value: "NEW_REGION" },
				]}
				countryOfOperationData={[
					{ title: "Selecionar", value: "" },
					...getCountries(),
				]}
				statusData={[
					{ title: "Selecionar", value: "" },
					{
						title: "Aprovado",
						value: acceptanceStatus.approved,
					},
					{
						title: "Reprovado",
						value: acceptanceStatus.rejected,
					},
					{ title: "Pendente", value: acceptanceStatus.pending },
				]}
			/>
		</Container>
	);
};

function formatFinalDate(date: string) {
	if (!date) return undefined;
	const parsedDate = parse(date, "dd/MM/yy", new Date());
	parsedDate.setHours(23, 59, 59);
	return parsedDate.toISOString();
}
