import { customStylesSelect } from "../../../components/formCatalog/customStylesSelect";
import { ww } from "../../../styles/responsive";

export const acceptanceCustomStylesSelect = {
	...customStylesSelect,
	container: (provided: any) => {
		return {
			...provided,
			fontSize: `${ww(8)}px`,
			minHeight: `${ww(25)}px`,
			height: "100%",
		};
	},
	multiValueLabel: (provided: any) => ({
		...provided,
		fontSize: `${ww(8)}px`,
		color: "#3B4BA2",
		fontWeight: 500,
	}),
	control: (provided: any) => ({ ...provided, height: "100%", width: 700 }),
};
