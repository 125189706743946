import styled from "styled-components";
import { w, ww } from "../../styles/responsive";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: ${w(25, 21, 29, 21)};
	overflow-x: hidden;

	& .subTitle {
		margin-bottom: ${ww(12)}px;
	}
`;

export const Title = styled.h5`
	margin-bottom: ${ww(31)}px;
	font-size: ${ww(21)}px;
	font-weight: 400;
	color: #4b4b4b;
	margin-right: auto;
`;

export const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin-bottom: ${ww(31)}px;
	background: #fff;
	border-radius: ${ww(6)}px;
`;
